<template>
	<div class="calendar-hours-info-box">
		<div class="calendar-hours-info">
			<div class="calendar-hours-button" :class="{'cur': hourIndex==timeInfo.curTimeIndex}">{{hourIndex==timeInfo.curTimeIndex?'当前时段':'分时状态'}}</div>
			<div class="calendar-hours-time">
				<i class="iconfont icon-time"></i>
				<span>{{hourData.beg+':00-'+hourData.end+':00'}}</span>
			</div>
			<div class="calendar-hours-keyword" :class="{'blur': hourData.locked}">
				<span v-if="hourData.locked">
					<span>{{defaultKeyword[0]}}</span><span>{{defaultKeyword[1]}}</span>
				</span>
				<span v-else>
					<span>{{hourData.keyword[0]}}</span><span>{{hourData.keyword[1]}}</span>
				</span>
			</div>
			<div class="calendar-hours-detail" :class="{'blur': hourData.locked}">
				<div class="calendar-hours-detail-item">
					<span class="calendar-hours-label label-good">利</span>
					<span class="calendar-hours-note">{{hourData.locked ? defaultGood : hourData.good}}</span>
				</div>
				<div class="calendar-hours-detail-item">
					<span class="calendar-hours-label label-bad">弊</span>
					<span class="calendar-hours-note">{{hourData.locked ? defaultCareful : hourData.careful}}</span>
				</div>
				<div class="calendar-hours-detail-item">
					<span class="calendar-hours-label label-recommend">荐</span>
					<span class="calendar-hours-note">{{hourData.locked ? defaultSuggest : hourData.suggest}}</span>
				</div>
			</div>
			<div v-if="hourData.locked" class="calendar-hours-locked">
				<div class="calendar-hours-lock-text">解锁当前分时状态,可查看你在这个时间段利于做什么事情，并提前得到预警，得知对应的建议。</div>
				<div class="calendar-hours-lock-button-box">
					<button class="calendar-hours-lock-button" @click="clickGoPay">马上解锁</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CalendarHoursInfo",
		computed: {
			timeInfo() {
				return this.$store.state.timeInfo;	
			}
		},
		props: {
			hourData: {
				type: Object,
				default: {}
			},
			hourIndex: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				defaultKeyword: ['心思敏捷', '灵感满满'],
				defaultGood: '思考问题，钻研，午间适当休息午间适当休息休息',
				defaultCareful: '出行有阻，困难重重',
				defaultSuggest: '深呼吸，放轻松',
			}
		},
		methods: {
			clickGoPay() {
				this.$bus.$emit('showMyVipPop');
			}
		}
	}
	
</script>

<style>
</style>
