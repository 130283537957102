// Vant 组件库按需引入
import Vue from 'vue'
import { Overlay, Popup, Dialog, Area, Toast, Picker, Switch, Collapse, CollapseItem, Swipe, SwipeItem, IndexBar, IndexAnchor, List, DatetimePicker, ImagePreview, Checkbox } from 'vant'
import 'vant/lib/overlay/style'
import 'vant/lib/popup/style'
import 'vant/lib/dialog/style'
import 'vant/lib/area/style'
import 'vant/lib/toast/style'
import 'vant/lib/picker/style'
import 'vant/lib/switch/style'
import 'vant/lib/collapse/style'
import 'vant/lib/collapse-item/style'
import 'vant/lib/swipe/style'
import 'vant/lib/swipe-item/style'
import 'vant/lib/index-bar/style'
import 'vant/lib/index-anchor/style'
import 'vant/lib/list/style'
import 'vant/lib/datetime-picker/style'
import 'vant/lib/image-preview/style'
import 'vant/lib/checkbox/style'

Vue.config.productionTip = false

Vue.use(Overlay)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(Area)

// Toast.setDefaultOptions({position: 'bottom'});
Vue.use(Toast)
Vue.use(Picker)
Vue.use(Switch)
Vue.use(Collapse).use(CollapseItem)
Vue.use(Swipe).use(SwipeItem)
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(List);
Vue.use(DatetimePicker);
Vue.use(ImagePreview);
Vue.use(Checkbox);


// 引入ECharts主模块 :: 引入柱状图
window.echarts = require('echarts/lib/echarts');
require('echarts/lib/chart/bar');
require('echarts/lib/chart/line');
require('echarts/lib/chart/radar');
import { LegendComponent, GridComponent, DataZoomComponent } from 'echarts/components';
window.echarts.use([LegendComponent, GridComponent, DataZoomComponent]);
// 引入fundebug错误上报日志
// 2021.02.05添加,勿删除
// 开发环境屏蔽一下,不然太多不必要的报错了
// import * as fundebug from "fundebug-javascript";
// import fundebugVue from "fundebug-vue";
// fundebug.init({
//     apikey: "758a4d73ab643457889c574f1e4968bb04384cecb127b2fb5ab26ac7dbd0aae7"
// });
// fundebugVue(fundebug, Vue);

import _ from 'lodash'
Object.defineProperty(Vue.prototype, '$_', { value: _ });

// 样式库引入
// basic part
import './assets/style/base.css'
import './assets/style/common.css'
import './assets/style/iconfont/iconfont.css'
import './assets/style/iconbg.css'
import './assets/style/calendar.css'
import './assets/style/calendar-circle.css'
import './assets/style/my.css'
import './assets/style/pages.css'
// import './assets/style/media.css'
// layout part

// bus: 注意:在mounted的時候on，在destory的時候要off，避免重复触发
const Bus = new Vue({
    methods: {
        emit (event, ...args) {
            this.$emit(event, ...args)
        },
        on (event, callback) {
            this.$on(event, callback)
        },
        off (event, callback) {
            this.$off(event, callback)
        }
    }
})
Vue.prototype.$bus = Bus

// 重写打印台
console.log = (function (oriLogFunc) {
    return function () {
      // 判断是否开启日志调试
      if (process.env.NODE_ENV != 'production') {
        try{
          oriLogFunc.call(console, ...arguments);
        }catch(e){
          console.error('console.log error', e);
        }
      }
    }
  })(console.log);

// 移动端打印log
// var vConsole = process.env.NODE_ENV == 'production' ? new VConsole() : null;

// 检查登录状态后:App开始初始化
// 嵌入式的页面不走微信授权登录接口，直接走url上的token
import login from './modules/login'
login.thirdLogin();