
var ua = navigator.userAgent.toLowerCase();
var UA = {
    // 安卓
    isAndroid: function() {
        return (/android/.test(ua)) ? true : false;
    },
    // Iphone
    isIphone: function() {
        return (/iphone/.test(ua)) ? true : false;
    },
    // 微信内置浏览器
    isWeChat: function() {
        return (/micromessenger/.test(ua)) ? true : false;
    },
    isQQ: function() {
        return (/qqtheme/.test(ua)) ? true : false;
    },
    isPcWeChat: function() {
        return(/windowswechat/.test(ua) || /macwechat/.test(ua)) ? true : false;
    },
    isPC: function() {
        return (/windows nt/.test(ua) || /mac os/.test(ua) && (!/micromessenger/.test(ua) && !/qqtheme/.test(ua) && !/iphone/.test(ua) && !/iphone/.test(ua) && !/windowswechat/.test(ua) && !/macwechat/.test(ua))) ? true : false;
    }
}

export {
    UA
}