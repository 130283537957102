
var CONFIG_IMG = {
	// 预加载图片:home
	home_image_list: [
		'../../aseets/images/constellation-arrow.png',
		'../../aseets/images/constellation-default.png',
		'../../aseets/images/constellation-blue.png',
		'../../aseets/images/constellation-red.png',
	],
	start_image_list: [
		'../../aseets/images/ani/earth/1.png',
		'../../aseets/images/ani/earth/2.png',
		'../../aseets/images/ani/earth/3.png',
		'../../aseets/images/ani/earth/4.png',
		'../../aseets/images/ani/earth/5.png',
		'../../aseets/images/ani/earth/6.png',
		'../../aseets/images/ani/earth/7.png',
		'../../aseets/images/ani/earth/8.png',
		'../../aseets/images/ani/earth/9.png',
		'../../aseets/images/ani/earth/10.png',
		'../../aseets/images/ani/earth/11.png',
		'../../aseets/images/ani/earth/12.png',
		'../../aseets/images/ani/earth/13.png',
		'../../aseets/images/ani/earth/14.png',
		'../../aseets/images/ani/earth/15.png',
		'../../aseets/images/ani/earth/16.png',
		'../../aseets/images/ani/sun/1.png',
		'../../aseets/images/ani/sun/2.png',
		'../../aseets/images/ani/sun/3.png',
		'../../aseets/images/ani/sun/4.png',
		'../../aseets/images/ani/sun/5.png',
		'../../aseets/images/ani/sun/6.png',
		'../../aseets/images/ani/sun/7.png',
		'../../aseets/images/ani/sun/8.png',
		'../../aseets/images/ani/sun/9.png',
		'../../aseets/images/ani/sun/10.png',
		'../../aseets/images/ani/sun/11.png',
		'../../aseets/images/ani/sun/12.png',
		'../../aseets/images/ani/sun/13.png',
		'../../aseets/images/ani/sun/14.png',
		'../../aseets/images/ani/sun/15.png',
		'../../aseets/images/ani/sun/16.png',
	],
	ani_man_list: [
		'../../aseets/images/ani/man/1.png',
		'../../aseets/images/ani/man/2.png',
		'../../aseets/images/ani/man/3.png',
		'../../aseets/images/ani/man/4.png',
		'../../aseets/images/ani/man/5.png',
		'../../aseets/images/ani/man/6.png',
		'../../aseets/images/ani/man/7.png',
		'../../aseets/images/ani/man/8.png',
		'../../aseets/images/ani/man/9.png',
		'../../aseets/images/ani/man/10.png',
		'../../aseets/images/ani/man/11.png',
		'../../aseets/images/ani/man/12.png',
		'../../aseets/images/ani/man/13.png',
		'../../aseets/images/ani/man/14.png',
		'../../aseets/images/ani/man/15.png',
		'../../aseets/images/ani/man/16.png',
	],
	ani_woman_list: [
		'../../aseets/images/ani/woman/1.png',
		'../../aseets/images/ani/woman/2.png',
		'../../aseets/images/ani/woman/3.png',
		'../../aseets/images/ani/woman/4.png',
		'../../aseets/images/ani/woman/5.png',
		'../../aseets/images/ani/woman/6.png',
		'../../aseets/images/ani/woman/7.png',
		'../../aseets/images/ani/woman/8.png',
		'../../aseets/images/ani/woman/9.png',
		'../../aseets/images/ani/woman/10.png',
		'../../aseets/images/ani/woman/11.png',
		'../../aseets/images/ani/woman/12.png',
		'../../aseets/images/ani/woman/13.png',
		'../../aseets/images/ani/woman/14.png',
		'../../aseets/images/ani/woman/15.png',
		'../../aseets/images/ani/woman/16.png',
	],
}

export {
	CONFIG_IMG
}