
import * as consts from '../base/consts'
import { WUXING } from './wuxing'
import { SHISHEN } from './shishen'
import { UTILS } from '../../utils'

/**
 * 定义一些处理基本数据的方法
 */
var FORMAT = {
    // 根据index获取隐藏的数据:十神/藏干/五行
    getHideArrDataByIndex(type, index, rigan) {
        var resultArr = [];
        if (type == "tiangan") {
            resultArr.push(SHISHEN.getShiShenOfRiZhu(index, rigan));    //十神
            resultArr.push('');         //藏干为空
            resultArr.push(WUXING.getWuXingByIndex(WUXING.getTianGanXingByIndex(index))); //五行
        } else if (type == "dizhi") {
            var shishenArr = [], cangganArr = [];
            var canggan = this.getCangGanByIndex(index);
            for (var i = 0; i < canggan.length; i++) {
                shishenArr.push({
                    "text": SHISHEN.getShiShenOfRiZhu(canggan[i], rigan),
                    "color": WUXING.getWuXingEnByIndex(WUXING.getTianGanXingByIndex(canggan[i]))
                });
                cangganArr.push({
                    "text": this.getTianGanByIndex(canggan[i]),
                    "color": WUXING.getWuXingEnByIndex(WUXING.getTianGanXingByIndex(canggan[i]))
                });
            }
            // console.error("getHideArrDataByIndex----", shishenArr, cangganArr)
            resultArr.push(shishenArr);  //十神
            resultArr.push(cangganArr);  //藏干
            resultArr.push(WUXING.getWuXingByIndex(WUXING.getDiZhiXingByIndex(index))); //五行
        }
        return resultArr;
    },
    // 根据index值获取天干的数据类
    getTianGanWordDataByIndex(index) {
        var data = {};
        data.text = this.getTianGanByIndex(index);
        var wuxingIndex = WUXING.getTianGanXingByIndex(index);
        data.propXing = WUXING.getWuXingByIndex(wuxingIndex);
        data.propXingEn = WUXING.getWuXingEnByIndex(wuxingIndex);
        return data;
    },
    // 根据index值获取地支的数据类
    getDiZhiWordDataByIndex(index) {
        var data = {};
        data.text = this.getDiZhiByIndex(index);
        var wuxingIndex = WUXING.getDiZhiXingByIndex(index);
        data.propXing = WUXING.getWuXingByIndex(wuxingIndex);
        data.propXingEn = WUXING.getWuXingEnByIndex(wuxingIndex);
        return data;
    },
    // 根据后台数据获取八个字的数据类数组
    getEightWordArrByData(unknown, data) {
        var eightWordArr = [];
        for (var i = 1; i < 5; i++) {
            var arr = [];
            if(i == 4){
                if(unknown == 1) {
                    arr.push({text: '不', propXingEn: 'no'})
                    arr.push({text: '详', propXingEn: 'no'})
                }else{
                    arr.push(this.getTianGanWordDataByIndex(data['tg_' + i]))
                    arr.push(this.getDiZhiWordDataByIndex(data['dz_' + i]))
                }
            }else{
                arr.push(this.getTianGanWordDataByIndex(data['tg_' + i]))
                arr.push(this.getDiZhiWordDataByIndex(data['dz_' + i]))
            }
            eightWordArr.push(arr);
        }
        return eightWordArr;
    },
    // 根据前端获取排盘的数据获取八个字的数据类数组
    getSiZhuEightWordArr(data) {
        var eightWordArr = [];
        for (var i = 0; i < 4; i++) {
            var arr = [];
            arr.push(this.getTianGanWordDataByIndex(data['tg'][i]))
            arr.push(this.getDiZhiWordDataByIndex(data['dz'][i]))
            eightWordArr.push(arr);
        }
        return eightWordArr;
    },
    // 获取时辰文字
    getShiChenByNumber(number) {
        var index = 0;
        switch (number) {
            case 23:
            case 0:
                index = 0; break;
            case 1:
            case 2:
                index = 1; break;
            case 3:
            case 4:
                index = 2; break;
            case 5:
            case 6:
                index = 3; break;
            case 7:
            case 8:
                index = 4; break;
            case 9:
            case 10:
                index = 5; break;
            case 11:
            case 12:
                index = 6; break;
            case 13:
            case 14:
                index = 7; break;
            case 15:
            case 16:
                index = 8; break;
            case 17:
            case 18:
                index = 9; break;
            case 19:
            case 20:
                index = 10; break;
            case 21:
            case 22:
                index = 11; break;
        }
        return consts.CONST_HOUR_WORD[index] + '时';
    },
    // 获取十二节
    getMonthFestivalByIndex(index) {
        return consts.CONST_MONTH_FEST[index];
    },
    // 获取天干文字
    getTianGanByIndex(index) {
        return consts.CONST_TIAN_GAN.charAt(index);
    },
    // 获取地支文字
    getDiZhiByIndex(index) {
        return consts.CONST_DI_ZHI.charAt(index);
    },
    // 获取天干文字的index值
    getTianGanIndexByText(text) {
        return consts.CONST_TIAN_GAN.indexOf(text);
    },
    // 获取地支文字的index值
    getDiZhiIndexByText(text) {
        return consts.CONST_DI_ZHI.indexOf(text);
    },
    // 根据文字获得文字的类型:天干/地支/五行
    getWordTypeByText(text) {
        var index = consts.CONST_TIAN_GAN.indexOf(text);
        var type = '';
        if(index >= 0){
            type = 'tiangan';
        }else{
            index = consts.CONST_DI_ZHI.indexOf(text);
            if(index >= 0){
                type = 'dizhi';
            }else{
                index = consts.CONST_WU_XING.indexOf(text);
                if(index >= 0){
                    type = 'wuxing';
                }
            }
        }
        return type;
    },
    // 根据生日获取虚岁
    getXuYearsOldByYear(birthday) {
        var now = MOMENT_TIME.getTimeArrayByTimeStamp();
        var birth = MOMENT_TIME.getTimeArrayByTimeStamp(birthday * 1000);
        // console.log('getXuYearsOldByYear-----', now[0] - birth[0] + 1);
        return now[0] - birth[0] + 1;
    },
    // 获得时间差值返回:前N天||后N天
    getDifferentialDayByNum(time, compTime) {
        var mD = time[1] - compTime[1], yD;
        if(mD == 0){
            yD = time[2] - compTime[2];
        }else{
            if([1, 3, 5, 7, 8, 10, 12].indexOf(time[1]) != -1){
                yD = time[2] - (compTime[2] + 31);
            }else if([4, 6, 9, 11].indexOf(time[1]) != -1){
                yD = time[2] - (compTime[2] + 30);
            }else if(time[1] == 2){
                yD = time[2] - (compTime[2] + 28);
            }
        }
        // console.log('getDifferentialDayByNum------', time, compTime, yD);
        return yD > 0 ? '后'+yD+'天' : '前'+Math.abs(yD)+'天';
    }
}

export {
    FORMAT
}