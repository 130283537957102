<template>
	<van-popup ref='vippopup' type="bottom" @change="onChange">
		<my-vip-panel 
			:titleText="['此功能为会员专享特权', '开通会员马上使用']">
		</my-vip-panel>
	</van-popup>
</template>

<script>
	import MyVipPanel from "../../components/my/MyVipPanel.vue"
	export default {
		name: "MyVipPop",
		components: { MyVipPanel },
		mounted() {
			this.$bus.on('showMyVipPop', this.showPop)
			this.$bus.on('hideMyVipPop', this.hidePop)
		},
		destroyed() {
			this.$bus.off('showMyVipPop', this.showPop)	
			this.$bus.off('hideMyVipPop', this.hidePop)
		},
		methods: {
			showPop() {
				this.$refs.vippopup.open();
			},
			hidePop() {
				this.$refs.vippopup.close();
			},
			onChange(value) {
				this.$store.state.hideAllCharts = value.show;
			}
		}
	}
</script>

<style>
</style>
