import { API, API_JSON } from '../../modules/api';
import { UTILS } from '../../modules/utils';
import { USER_DATA_MANAGER } from '../../modules/data'
import * as consts from '../../modules/data/base/consts.js'
export default {
	/**
	 * 获取当前时间及相关信息
	 */
	initSysTime({state}) {
		var date = new Date(),
			hour = date.getHours();
		var styleArr = consts.CONST_START_END_TIME_STYLE, styleType = '',
			timeArr = consts.CONST_START_END_TIME_HOUR, curTimeIndex = -1;
		styleType = hour >= styleArr[0] && hour <= styleArr[1] ? 'day' : 'night';
		for(var i = 0; i < timeArr.length; i++) {
			curTimeIndex = hour >= timeArr[i] && hour < timeArr[i]+2 ? i : curTimeIndex;
		}
		state.timeInfo = {
			type: styleType, curTimeIndex: curTimeIndex
		}
		console.log('initSysTime-------', hour, state.timeInfo);
	},
	/**
	 * 获取天气信息
	 */
	initWeatherInformation({state}, params) {
		API.getWeatherInfo({}, function(data) {
			console.log('获取天气信息成功:::::::', data);
			var todayInfo = data && data.showapi_res_body ? data.showapi_res_body.f1 : {},
				tomorrowInfo = data && data.showapi_res_body ? data.showapi_res_body.f2 : {};
			state.weatherInfo.today = todayInfo;
			state.weatherInfo.tomorrow = tomorrowInfo;
			if(params && params.cb && typeof(params.cb) == 'function') {
				params.cb();
			}
		}, function() {
			if(params && params.cb && typeof(params.cb) == 'function') {
				params.cb();
			}
		})
	},
	/**
	 * 获取宜忌
	 * 通过年份来拉取线上的json数据
	 */
	getYiJiCurrentDataByYear({state}, params) {
		var year = params.year, ymd = params.ymd;
		var inJson = consts.CONST_YI_JI_YEAR.indexOf(year) >= 0 ? true : false,
			jsonUrl = consts.CONST_YI_JI_JSON_URL + year + '.json';
		if(!inJson) { return; }
		// 已经下载了就直接取数据就好
		if(state.yijiTotalData[year]) {
			state.yijiCurrentData = state.yijiTotalData[year][ymd];
			return;
		}
		API_JSON.getJson(jsonUrl, function(data) {
			console.log('获取年的宜忌数据成功:::::::', year, data)
			var newData = {}; newData[year] = data;
			state.yijiTotalData = Object.assign(newData, state.yijiTotalData);
			state.yijiCurrentData = state.yijiTotalData[year][ymd];
		}, function() {
			console.error('获取年的宜忌数据失败:::::::', year)
		})
		
	},
	/**
	 * 通过我的用户列表
	 * */
	getMyUserListData({state}, params) {
		if(state.gotMyUserList) { return; }
		API.getMyUserList({}, function(data) {
			console.log('获取我的用户数据列表成功:::::::', data)
			state.gotMyUserList = true;
			state.myUserList = USER_DATA_MANAGER.getMyUserListDataByData(data);
			if(params && params.cb && typeof(params.cb) == 'function') {
				params.cb(data);
			}
		});
	},
	/**
	 * 添加先知主人到我的用户列表
	 */
	addMyUserListData({state}, data) {
		var newData = USER_DATA_MANAGER.getMyUserDataByData(data);
		var newIndex = UTILS.getIdIndexInObjectArray(state.myUserList, data.id);
		if(newIndex == -1) {
			state.myUserList.push(newData);
		}
		console.log('添加我的用户数据成功:::::::', state.myUserList);
	},
	/**
	 * 更新我的用户列表
	 */
	updateMyUserListData({state}, params) {
		var curIndex = UTILS.getIdIndexInObjectArray(state.myUserList, params.id);
		console.error('updateMyUserListData------', params)
		if(curIndex != -1) {
			state.myUserList[curIndex].f_name = params.name;
			state.myUserList[curIndex].firstL = UTILS.getFirstLetter(params.name);
		}
	}
}