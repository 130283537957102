import * as consts from '../base/consts'
import wuxing, { WUXING } from './wuxing'

var SHISHEN = {

    /**
     * 
     * @param {日干index} rigan 
     * @param {干支index} ganzhi 
     * @param {天干tiangan地支dizhi的type值} type 
     */
    getShiShenOfRiZhu(ganzhi, rigan) {

        var ganzhiWuXing = '';
            ganzhiWuXing = consts.CONST_WU_XING.charAt(WUXING.getTianGanXingByIndex(ganzhi));
        var wuXingStartFromIndex = this.getWuxingStartFromIndex(rigan);
        var indexOfType = wuXingStartFromIndex.indexOf(ganzhiWuXing);
        var count = (rigan + ganzhi) % 2;
        var result = "";
        switch (indexOfType) {
            case 0:
                result = this._shishenBi(count); break;
            case 1:
                result = this._shishenShang(count); break;
            case 2:
                result = this._shishenCai(count); break;
            case 3:
                result = this._shishenSha(count); break;
            case 4:
                result = this._shishenYin(count); break;
            default:
                break;
        }
        return result;
    },

    /**
     * @param {*日干的索引} index 
     * 根据日干的五行属性，生成一个比伤财煞印顺序的对应五行的字符串
     */
    getWuxingStartFromIndex(index) {
        var wuXingStartFromIndex = "";
        switch (index) {
            case 0:
            case 1:
                wuXingStartFromIndex = "木火土金水";
                break;
            case 2:
            case 3:
                wuXingStartFromIndex = "火土金水木";
                break;
            case 4:
            case 5:
                wuXingStartFromIndex = "土金水木火";
                break;
            case 6:
            case 7:
                wuXingStartFromIndex = "金水木火土";
                break;
            case 8:
            case 9:
                wuXingStartFromIndex = "水木火土金";
                break;
            default:
                break;
        }
        return wuXingStartFromIndex;
    },

    /**
     * 十神关系:劫/比
     */
    _shishenBi(count) {
        return count == 1 ? "劫" : "比";
    },

    /**
     * 十神关系:伤/食
     */
    _shishenShang(count) {
        return count == 1 ? "伤" : "食";
    },

    /**
     * 十神关系:财/才
     */
    _shishenCai(count) {
        return count == 1 ? "财" : "才";
    },

    /**
     * 十神关系:官/杀
     */
    _shishenSha(count) {
        return count == 1 ? "官" : "杀";
    },

    /**
     * 十神关系:印/卩
     */
    _shishenYin(count) {
        return count == 1 ? "印" : "卩";
    },

}

export {
    SHISHEN
}