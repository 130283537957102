<template>
	<div class="my-avatar-out-box" :class="className">
		<div v-if="userBaseInfo.f_type!=0" class="my-avatar-vip"></div>
		<img v-if="userBaseInfo && userBaseInfo.isdefault == 1" class="my-avatar" :src="(userBaseInfo.gender==0 ? 'https://m1.yxdxt.com/d-m.png' : 'https://m1.yxdxt.com/d-f.png')" mode="" />
		<div v-else class="my-avatar-text" :class="{'my-avatar-text-vip': userBaseInfo.f_type!=0}">
			<span>{{userBaseInfo.firstL}}</span>
		</div>
		<i v-if="userBaseInfo.f_type!=0" class="iconbg icon-vip"></i>
	</div>
</template>

<script>
	export default {
		name: "MyAvatar",
		computed: {
			userInfo() {
				return this.$store.state.userInfo;	
			},
			userBaseInfo() {
				return this.$store.state.user.userBaseInfo;
			},
		},
		props: {
			className: {
				type: String,
				default: ''
			}
		},
		mounted() {
			this.avatarUrl = this.userInfo && this.userBaseInfo && this.userBaseInfo.isdefault == 1 ? this.userInfo.avatar : this.avatarUrl;
		}
	}
</script>

<style>
</style>
