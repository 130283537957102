import Vue from 'vue'
import VueResource from 'vue-resource'
import store from '../store'
import message from './msg_srv'
import { CONFIG } from '../config'
Vue.use(VueResource)

// var getMd5Params = function(params) {
// 	var str = cj.MD5(str).toString(), paramsArr = [], paramsStr = '';
// 	for(var keys in params) {
// 		paramsArr.push(keys+'='+params[keys]);
// 	}
// 	paramsArr.sort(function(a, b){
// 		return(a.charAt(0).localeCompare(b.charAt(0)))
// 	});
// 	paramsStr = paramsArr.join('&');
// 	var resultStr = cj.MD5(cj.MD5(paramsStr).toString() + cj.MD5(CONFIG.sign_code).toString().slice(0, 16)).toString();
// 	return resultStr;
// }

// 封装基础http请求方法
var getApi = function (url, params, sucCallback, failCallback) {
	// api基础参数配置
	var apiUrl = CONFIG.api_url, getUrl = apiUrl + url;
	var timestamp = (new Date()).getTime() / 1000;
	var platformParams = {
	    version: '1.5.1',
	    time: timestamp,
		os: store.state.systemInfo.system,
		brand: store.state.systemInfo.brand,
		lang: store.state.systemInfo.language
	};
	
	// 处理url传过来的platform参数
	var platform = JSON.parse(store.state.platform);
	platformParams.platform = platform == 0 ? platformParams.platform : platform;	
	// #ifdef MP-WEIXIN
	platformParams.platform = 51;
	// #endif
	// console.log('getApi-------', url, platformParams.platform, platformParams);

	// 处理token参数
	var	getParams = Object.assign(params, platformParams);
	if(url.indexOf('login') == -1) {
		getParams = Object.assign(getParams, {
			token: store.state.userData.token
            // isphper: 1017216
		});
	}
	
	// 处理sign参数
	// var signStr = getMd5Params(Object.assign({}, getParams));
	// getParams = Object.assign(getParams, {
	// 	sign: signStr
	// })
	// console.log('getApi------', getUrl, getParams)
	// 发送请求
	return new Promise((resolve, reject) => {
        Vue.http.get(getUrl, { params: getParams }, { emulateJSON: true }).then((response) => {
            if(response.data.status_code == 200) {
                if (sucCallback) {
                    sucCallback(response.data.data)
                }
                resolve();
            }else {
                if (failCallback) { 
                    failCallback(response.data)
                }
                if (response.data.status_code) {
                    console.error("请求失败", message[response.data.status_code]);
                }
                reject();
            }
        })
    })
}

// 注册事件，方便调用
var API = {
    // 个人信息获取
    getUserInfo: function (params, suc_cb, fail_cb) { getApi('/user', params, suc_cb, fail_cb) },
    // 登录方法
    thirdLogin: function (params, suc_cb, fail_cb) { getApi('/login/thirdLogin', params, suc_cb, fail_cb) },
	// 小程序:小程序授权登录前传Code
	codeToSession: function (params, suc_cb, fail_cb) { getApi('/login/codeToSession', params, suc_cb, fail_cb) },
    // 添加测算
    addUser: function (params, suc_cb, fail_cb) { getApi('/ai/add', params, suc_cb, fail_cb) }, 
	// 获取首页数据
	getUserHomePage: function (params, suc_cb, fail_cb) { getApi('/ai/getHomePage', params, suc_cb, fail_cb) },
	// 修改昵称
	updateUserInfo: function (params, suc_cb, fail_cb) { getApi('/ai/upInfo', params, suc_cb, fail_cb) },
	// 获取月数据
	getUserMonth: function (params, suc_cb, fail_cb) { getApi('/ai/getMonth', params, suc_cb, fail_cb) },
	// 获取我的列表
	getMyUserList: function (params, suc_cb, fail_cb) { getApi('/ai/getList', params, suc_cb, fail_cb) },
	// 获取性格
	getUserCharacter: function (params, suc_cb, fail_cb) { getApi('/ai/getCharacter', params, suc_cb, fail_cb) },
	// 获取天赋
	getUserTalent: function (params, suc_cb, fail_cb) { getApi('/ai/getTalent', params, suc_cb, fail_cb) },
	// 获取日状态曲线
	getUserDayStatus: function (params, suc_cb, fail_cb) { getApi('/ai/dayStatus', params, suc_cb, fail_cb) },
	// 获取月状态曲线
	getUserMonthStatus: function (params, suc_cb, fail_cb) { getApi('/ai/monthStatus', params, suc_cb, fail_cb) },
	// 获取天气预报
	getWeatherInfo: function (params, suc_cb, fail_cb) { getApi('/ai/getWeather', params, suc_cb, fail_cb) },
	// 基础配置:获取微信jssdk配置信息
	getWeixinSdkConfig: function (params, suc_cb, fail_cb) { getApi('/muhuo/config/weixinSdk', params, suc_cb, fail_cb) },
	// 获取商品列表
	getProductList: function (params, suc_cb, fail_cb) { getApi('/pay/productList', params, suc_cb, fail_cb) },
	// 下单
	createPayOrder: function (params, suc_cb, fail_cb) { getApi('/pay/createOrder', params, suc_cb, fail_cb) },
}

var API_JSON = {
    getJson: function (url, sucCb, failCb) {
        return new Promise((resolve, reject) => {
            Vue.http.get(url).then((response) => {
                if(response.status == 200) {
                    if(sucCb && typeof(sucCb) == 'function') {
                        sucCb(response.body)
                    }
                    resolve();
                }else {
                    if(failCb && typeof(failCb) == 'function') {
                        failCb()
                    }
                    console.error("获取json失败");
                }
            })
        })
    }
}

export {
    API, API_JSON, message
}