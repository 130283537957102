<template>
	<div class="topbar second-topbar" :class="className">
		<div class="topbar-calendar pos-r">
			<div class="second-topbar-back" @click="clickBack"><i class="iconfont icon-arrow-l"></i></div>
			<div class="topbar-title">{{title}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "SecondTopBar",
		props: {
			className: {
				type: String,
				default: ''
			},
			title: {
				type: String,
				default: ''
			},
			backFunc: {
				type: Function,
				default: undefined
			}
		},
		methods: {
			clickBack() {
				if(this.backFunc && typeof(this.backFunc) == 'function') {
					this.backFunc();
				}
			}
		}
	}
</script>

<style>
</style>
