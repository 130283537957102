<template>
	<img :src="imgUrl" @load="loadImg" @error="loadError" class="load-image" />
</template>

<script>
	import { CONFIG_IMG } from '../../modules/config'
	export default {
		name: "LoadImageList",
		data() {
			return {
				imgUrl: '',
				imgIndex: 0,
				imageList: [],
				sucCb: undefined,
			}
		},
		mounted() {
			this.$bus.on('loadImageList', this.loadImageList)
		},
		destroyed() {
			this.$bus.off('loadImageList', this.loadImageList)
		},
		methods: {
			loadImageList(params) {
				this.imageList = []; this.imgIndex = 0;
				switch(params.fromPage) {
					case 'home':
						this.imageList = CONFIG_IMG.home_image_list;
						break;
					case 'start':
						this.imageList = CONFIG_IMG.start_image_list.concat(CONFIG_IMG.ani_man_list).concat(CONFIG_IMG.ani_woman_list);
						break;
					case 'ani_man':
						this.imageList = CONFIG_IMG.ani_man_list;
						break;
					case 'ani_woman':
						this.imageList = CONFIG_IMG.ani_woman_list;
						break;
					default:
						break;
				}
				if(this.imageList.length == 0) { return; }
				console.log('loadImageList-----', this.imageList)
				this.imgUrl = this.imageList[this.imgIndex];
				this.sucCb = params.cb;
			},
			loadImg() {
				var bigIndex = this.imageList.length - 1;
				if(this.imgIndex < bigIndex) {
					this.imgIndex++;
					this.imgUrl = this.imageList[this.imgIndex];
					console.log('loadImg---------', this.imgIndex);
				}else if(this.imgIndex == bigIndex) {
					console.log('全部图片加载完成-----')
					if(this.sucCb && typeof(this.sucCb) == 'function') {
						this.sucCb();
					}
				}
			},
			loadError() {
				console.error('加载图片出错-------', this.imageList[this.imgIndex])
			}
		}
	}
</script>

<style>
	.load-image {
		width: 0;
		height: 0;
	}
</style>
