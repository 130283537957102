<template>
	<div class="page-two page-second my-talent-page">
		<!-- 二级顶部导航栏 -->
		<second-top-bar :title="'天赋变化'" :backFunc="clickMyBack"></second-top-bar>
		<div class="my-character-scroll-view">
			<!-- 内容部分 -->
			<div class="my-data-box w-94-p white-box">
				<div class="my-radar-data-chart">
					<radar-charts :canvasId="'talentRadarCharts'" :chartData="userTalentChartData"></radar-charts>
				</div>
				<div class="my-data-chart-button-box" v-if="!userAuthority.STalentAuth">
					<button type="default" class="my-data-chart-button" @click="clickCheck">
						<span>{{'查看'+userTalentData.nextYear+'年后天能力'}}</span>
					</button>
				</div>
			</div>
			<div v-if="userTalentData.des && userTalentData.des.f">
				<div class="my-data-box-title w-94-p" v-if="userTalentData.des.f.title">
					<span class="title-line line1"></span><span class="title">{{userTalentData.des.f.title.slice(0, 6)}}</span>
				</div>
				<div class="my-data-box w-94-p white-box">
					<div class="my-data-content">
						<div class="content-base" v-if="userTalentData.des.f.base">
							<p v-for="dText in userTalentData.des.f.base" :key="dText.slice(0, 5)" v-html="dText"></p>
						</div>
						<div class="content-content" v-for="(cData, cIndex) in userTalentData.des.f.content" :key="cData.title.slice(0, 5)">
							<div class="my-data-sub-title"><span :class="'title'+cIndex">{{cData.title}}</span></div>
							<p v-html="cData.content"></p>
						</div>
					</div>
				</div>
			</div>
			<div v-if="userAuthority.STalentAuth && userTalentData.des && userTalentData.des.s">
				<div class="my-data-box-title w-94-p" v-if="userTalentData.des.s.title">
					<span class="title-line line1"></span><span class="title">{{userTalentData.des.s.title.slice(0, 6)}}</span>
				</div>
				<div class="my-data-box w-94-p white-box my-data-box-last">
					<div class="my-data-content">
						<div v-if="userTalentData.des.s.base">
							<p v-for="ddText in userTalentData.des.s.base" :key="ddText.slice(0, 5)" v-html="ddText"></p>
						</div>
						<div v-for="ccData in userTalentData.des.s.content" :key="ccData.slice(0, 5)">
							<p v-html="ccData"></p>
						</div>
					</div>
				</div>
				<div class="my-data-box-blank"></div>
			</div>
			<!-- 后天能力占位 -->
			<div v-if="!userAuthority.STalentAuth">
				<div class="my-data-box-title w-94-p">
					<span class="title-line line2"></span><span class="title">后天表现方面</span>
				</div>
				<div class="my-data-box w-94-p white-box pos-r my-data-box-last">
					<div class="my-data-content blur">
						<div>2023年命主的逻辑思维、创意表达、规划能力得到</div>
						<div>而自制力、执行能力有所</div>
						<div>今年在逻辑思维和创意表达能力比较</div>
						<div>而自制力和执行能力相对</div>
					</div>
					<button type="default" class="my-data-chart-button check-button" @click="clickCheck"><span>查看</span></button>
				</div>
				<div class="my-data-box-blank"></div>
			</div>
		</div>
		<!-- vip弹框 -->
		<my-vip-pop v-if="showPop"></my-vip-pop>
	</div>
</template>

<script>
	import SecondTopBar from '../../../components/common/SecondTopBar.vue'
	import RadarCharts from '../../../components/charts/RadarCharts.vue'
	import graceRichText from '../../../modules/utils/richText.js'
	export default {
		name: "MyTalentPage",
		components: { SecondTopBar, RadarCharts },
		computed: {
			systemInfo() {
				return this.$store.state.systemInfo;
			},
			userAuthority() {
				return this.$store.state.user.authority;
			},
			userTalentData() {
				return this.$store.state.user.userTalentData;
			},
			userTalentChartData() {
				return this.$store.state.user.userTalentChartData;
			},
		},
		data() {
			return {
				showPop: false,
				graceRichText: graceRichText,
				height: '500px'
			}
		},
		onShow() {
			this.showPop = true;
		},
		onHide() {
			this.showPop = false;
		},
		mounted() {
			setTimeout(function() {
				this.initDom();
			}.bind(this), 20)
		},
		methods: {
			initDom() {
				this.height = (this.systemInfo.windowHeight-50)+'px';
			},
			clickMyBack() {
				this.$router.replace('/my');
			},
			clickCheck() {
				this.$bus.emit('showMyVipPop');
			}
		}
	}
</script>

<style>
</style>
