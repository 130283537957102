// #ifdef MP-WEIXIN
import { CONFIG_PLATFORM } from './wx'
// #endif

// 图片list配置
import { CONFIG_IMG } from './imglist'
import { UTILS } from '../utils';

// 基础配置
var CONFIG = Object.assign({
    platform: 'wechat',
	// api接口地址
	api_url: 'https://api.5seer.cn/api',
	// 开始时间
	startHour: 7,

	getWechatAuthUrl() {
        var shareId = UTILS.getQueryString("shareid");
        var wechatAuthUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb92e4353fb2d7f1e&redirect_uri=";
        wechatAuthUrl = wechatAuthUrl + window.encodeURIComponent('https://' + window.location.hostname + window.location.pathname);
        if(shareId){
            wechatAuthUrl = wechatAuthUrl + '?shareid=' + shareId;
        }
        wechatAuthUrl = wechatAuthUrl + "&response_type=code&scope=snsapi_userinfo&state=web&connect_redirect=1#wechat_redirect";
        console.error("wechatAuthUrl---", wechatAuthUrl)
        return wechatAuthUrl;
    }, 
	
}, CONFIG_PLATFORM)

export {
	CONFIG, CONFIG_IMG
}