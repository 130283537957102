<template>
	<div class="page-one pg-calendar">
		<div class="calendar-bg">
			<!-- 顶部bar -->
			<top-bar :className="'calendar-top-bar'"></top-bar>
			<!-- 日历信息 -->
			<calendar-info
				v-bind:className="'calendar-calendar-info'"
				v-bind:infoData="userTodayData"
				v-bind:dateData="todayDateInfo">
			</calendar-info>
			<!-- 星球信息 -->
			<div class="calendar-stars-box">
				<!-- 木星 -->
				<div class="calendar-star-box star-jupi" v-if="userStarInfo && userStarInfo['jupi']">
					<div class="star"></div><span class="arrow"></span><span>木星位置{{userStarInfo['jupi'].msg.slice(2)}}</span>
				</div>
				<!-- 火星 -->
				<div class="calendar-star-box star-mars" v-if="userStarInfo && userStarInfo['mars']">
					<div class="star"></div><span class="arrow"></span><span>火星{{userStarInfo['mars'].msg.slice(2)}}</span>
				</div>
				<!-- 水星 -->
				<div class="calendar-star-box star-merc" v-if="userStarInfo && userStarInfo['merc']">
					<div class="star"></div><span class="arrow"></span><span>水星{{userStarInfo['merc'].msg.slice(2)}}</span>
				</div>
				<!-- 土星 -->
				<div class="calendar-star-box star-satu" v-if="userStarInfo && userStarInfo['satu']">
					<div class="star"></div><span class="arrow"></span><span>土星{{userStarInfo['satu'].msg.slice(2)}}</span>
				</div>
				<!-- 金星 -->
				<div class="calendar-star-box star-venu" v-if="userStarInfo && userStarInfo['venu']">
					<div class="star"></div><span class="arrow"></span><span>金星{{userStarInfo['venu'].msg.slice(2)}}</span>
				</div>
			</div>
			<div class="calendar-bottom-box">
				<!-- 分时工作状态 -->
				<van-swipe v-if="userTodayData && userTodayData.hourMsg" class="calendar-hours-swiper" :initial-swipe="timeInfo.curTimeIndex" :show-indicators="false" :duration="300" :width="320" :height="130">
					<van-swipe-item class="calendar-hours-swiper-item" v-for="(hData, index) in userTodayData.hourMsg.info" :key="hData.beg">
						<calendar-hours-info :hourData="hData" :hourIndex="index"></calendar-hours-info>
					</van-swipe-item>
				</van-swipe>
				<!-- 天气状态 -->
				<calendar-weather-info></calendar-weather-info>
			</div>
		</div>
		<!-- vip弹框 -->
		<my-vip-pop v-if="showPop"></my-vip-pop>
		<!-- 切换先知主人弹框 -->
		<my-user-list-pop v-if="showPop"></my-user-list-pop>
	</div>
</template>

<script>
	import { UTILS } from '../../modules/utils/index.js'
	import TopBar from '../../components/common/TopBar.vue'
	import CalendarInfo from '../../components/calendar/CalendarInfo.vue'
	import CalendarHoursInfo from '../../components/calendar/CalendarHoursInfo.vue'
	import CalendarWeatherInfo from '../../components/calendar/CalendarWeatherInfo.vue'
	import MyVipPop from '../../components/my/MyVipPop.vue'
	import MyUserListPop from '../../components/my/MyUserlistPop.vue'
	export default {
		name: "Calendar",
		components: { TopBar, CalendarInfo, CalendarHoursInfo, CalendarWeatherInfo, MyVipPop, MyUserListPop },
		computed: {
			timeInfo() {
				return this.$store.state.timeInfo;	
			},
			userStarInfo() {
				return this.$store.state.user.userStarInfo;
			},
			userHomeIsToday() {
				return this.$store.state.user.userHomeIsToday;
			},
			userTodayData() {
				return this.$store.state.user.userTodayData;
			},
			userTomorrowData() {
				return this.$store.state.user.userTomorrowData;
			},
			todayDateInfo() {
				return this.$store.state.calendar.todayDateInfo;
			},
			tomorrowDateInfo() {
				return this.$store.state.calendar.tomorrowDateInfo;
			}
		},
		data() {
			return {
				title: '',
				isToday: true,
				showPop: false,
				starsArr: ['jupi', 'mars', 'merc', 'satu', 'venu'],
				curStars: []
			}
		},
		mounted() {
			this.showPop = true;
		},
		onHide() {
			this.showPop = false;
		},
		methods: {
			initStarAnimation() {

			},
		}
	}
</script>

<style>
	.calendar-info-panel-box {
		display: flex;
	}
	.calendar-info-panel {
		width: 50%;
	}
</style>
