<template>
	<div class="ai-result-panel">
		<div class="ai-result-panel-top pos-r">
			<!-- <div class="ai-result-light"></div> -->
			<!-- <div class="ai-result-niu"></div> -->
		</div>
		<div class="ai-result-panel-content">
			<!-- <div class="ai-result-title">
				<span>主人主人，您终于来了啊！我是您的牛先知助理</span><span class="text2">天天</span><span>，接下来将由我助力您冲刺牛年～</text>
			</div> -->
			<div class="ai-result-content-scroll">
				<div class="ai-result-content">
					<div class="ai-result-base" v-if="userBaseTalentSortData && userBaseTalentSortData.length > 0">
						{{'主人是一个'+userBaseTalentSortData[3].name+'极强的人，同时有着不错的'+userBaseTalentSortData[2].name+'。而在'+userBaseTalentSortData[0].name+'和'+userBaseTalentSortData[1].name+'方面存在先天短板。随着年岁的增长和阅历的增多，短板已不断得到补强，您的天赋矩阵如下：'}}
					</div>
					<div class="ai-result-base" v-else>
						主人是一个A极强的人，同时有着不错的B。而在C和D方面存在先天短板。随着年岁的增长和阅历的增多，短板已不断得到补强，您的天赋矩阵如下：
					</div>
					<div class="ai-result-f-talent">
						<div class="my-data-box-title">
							<span class="title-line line3"></span><span class="title">先天天赋</span>
						</div>
						<div class="ai-result-my-data-chart">
							<radar-charts :canvasId="'aiRadarChart'" :chartData="userBaseTalentChartData" :showLegend="false"></radar-charts>
						</div>
					</div>
					<!-- todo 还没有数据 -->
					<div class="ai-result-forecast">
						<div class="my-data-box-title">
							<span class="title-line line1"></span><span class="title">2023可能事件</span>
						</div>
						<div class="ai-result-base base2">
							<span v-for="bText in userEvent" :key="bText">{{bText}}</span>
						</div>
					</div>
					<div class="ai-result-note">
						<span class="title">科普小知识：</span>
						<span class="content">2023年2月3日太阳到达黄经315度，从此我们进入兔年的能量场。之后每移动15度将切换一个时空能量，正好对应12个月的时间。而在这期间随着地球自转、公转的发生，太阳、月亮及和五大行星对你的影响将决定了你的兔气走向。</span>
					</div>
				</div>
			</div>
			<div class="ai-result-button-box" @click="clickStart">
				<button class="ai-result-button">{{userBaseInfo.isdefault== 1?'领取3天VIP开始试用':'继  续'}}</button>
			</div>
		</div>
	</div>
</template>

<script>
	import RadarCharts from '../../components/charts/RadarCharts.vue'
	export default {
		name: "AiResultPanel",
		components: { RadarCharts },
		computed: {
			userBaseInfo() {
				return this.$store.state.user.userBaseInfo;
			},
			userEvent() {
				return this.$store.state.user.userEvent;
			},
			userBaseTalentChartData() {
				return this.$store.state.user.userBaseTalentChartData;
			},
			userBaseTalentSortData() {
				return this.$store.state.user.userBaseTalentSortData;
			}
		},
		data() {
			return {
				categories: ['维度1', '维度2', '维度3', '维度4', '维度5', '维度6', '维度7', '维度8'],
				series: [{
					name: '成交量1',
					data: [90, 110, 165, 195, 187, 172, 187, 172]
				}]
			}
		},
		methods: {
			clickStart() {
				let me = this;
				this.$store.dispatch('calendar/initTodayTomorrowDateInfo');
				this.$store.state.curTabIndex = 0;
				this.$store.dispatch('user/getUserHomePageData', {
					cb: function() {
						me.$router.replace('/calendar');
					}
				})
			}
		}
	}
</script>

<style>
</style>
