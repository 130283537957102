/**
 * 日历组件
 */
import { CALENDAR } from './calendar.js'
var CALENDAR_UTILS = {
    /**
     * 获取公历月份的天数
     * @param {年} y
     * @param {月} m
     */
    getSolarMonth(y, m) {
        // 校对2月，有的有29天
        var result;
        if(m == 1) {
            var lastDay = new Date(y, m, 29);
            result = lastDay.getMonth() == m ? 29 : CALENDAR.solarMonth[m];
        }else {
            result = CALENDAR.solarMonth[m];
        }
        return result;
    },
	/**
	 * 获取元数据
	 * @param {日期数据} date
	 */
	getMetaDataByDate(date) {
		var now = new Date(), nY = now.getFullYear(), nM = now.getMonth(), nD = now.getDate();
		var dY = date.getFullYear(), dM = date.getMonth(), dD = date.getDate();
			// console.error('getMetaDataByDate------', nY, nM, nD, dY, dM, dD);
			// 判断是否前5天
		var result = {
			y: dY, m: dM, d: dD,
			day: date.getDay(),
			dayText: CALENDAR.nStr1[date.getDay()],
			isToday: dY == nY && dM == nM && dD == nD
		};
		return result;
	},
    /**
     * 根据年月日获取一周的数据(从周日到周六)
     * @param {年} y
     * @param {月} m
     * @param {日} d
     */
    getWeekArrayByYMD(y, m, d) {
        var curDate = new Date(y, m, d),
            curDay = curDate.getDay(); //周几
        var startD = d > curDay ? d-curDay : CALENDAR_UTILS.getSolarMonth(y, (m >=1 ? m-1: 11))-(curDay-d),
            startM = d > curDay ? m : m-1,
            startY = startM >= 0 ? y : y-1;
            startM = startM >= 0 ? startM : 11;
        // console.error('getWeekArrayByYMD-----', y, m, d, curDate, curDay, startY, startM, startD);
        var result = [];
        for(var i = 0; i < 7; i++) {
            var date = new Date(startY, startM, startD);
			var mData = CALENDAR_UTILS.getMetaDataByDate(date);
            result.push(mData);
            startD++;
        }
        console.log('getWeekArrayByYMD------', result);
        return result;
    },
    /**
     * 根据年月日获取前一周的数据(从周日到周六)
     * @param {年} y
     * @param {月} m
     * @param {日} d
     */
    getPrevWeekByYMD(y, m, d) {
        var newD = d > 8 ? d-7 : CALENDAR_UTILS.getSolarMonth(y, (m >= 1 ? m - 1 : 11))-(7-d),
            newM = d > 8 ? m : m-1,
            newY = newM >= 1 ? y : y-1;
            newM = newM >= 1 ? newM : 11;
        // console.error('getPrevWeekByYMD-------', y, m, d, newY, newM, newD);
        var result = CALENDAR_UTILS.getWeekArrayByYMD(newY, newM, newD);
        return { y: newY, m: newM, d: newD, arr: result };
    },
    /**
     * 根据年月日获取后一周的数据(从周日到周六)
     * @param {年} y
     * @param {月} m
     * @param {日} d
     */
    getNextWeekByYMD(y, m, d) {
        var maxD = CALENDAR_UTILS.getSolarMonth(y, m),
            newD = d+7 <= maxD ? d+7 : 7-(maxD-d),
            newM = d+7 <= maxD ? m : m+1,
            newY = newM <= 11 ? y : y+1;
            newM = newM <= 11 ? newM : 0;
        // console.error('getNextWeekByYMD-------', y, m, d, maxD, newY, newM, newD);
        var result = CALENDAR_UTILS.getWeekArrayByYMD(newY, newM, newD);
        return { y: newY, m: newM, d: newD, arr: result };
    },
    /**
     * 根据年月获取公历一个月的数据(从1号到月底)
     * @param {年} y
     * @param {月} m
     */
    getSolarMonthArrayByYM(y, m) {
        var lastMD = CALENDAR_UTILS.getSolarMonth(y, m),
            startD = 1,
            firstD = new Date(y, m, 1),
            firstDay = firstD.getDay(), 
            tempArr = [], result = [], line = 0;
        // 最大周数:6周
        for(var i = 0; i < 6; i++) {
            result[i] = result[i] ? result[i] : [];
            // 天数
            for(var j = 0; j < 7; j++) {
				// 前面几天
                if(i == 0 && j < firstDay) {
					var lastY = m-1 >= 0 ? y: y-1,
						lastM = m-1 >= 0 ? m-1: 11,
						maxD = CALENDAR_UTILS.getSolarMonth(lastY, lastM),
						curD = maxD-firstDay+1+j;
					var lastDate = new Date(lastY, lastM, curD);
					var lastDateObj = CALENDAR.solar2lunar(lastY, lastM+1, curD);
					var lData = Object.assign({
						curMonth: lastDateObj.cMonth == m+1 ? true : false
					}, lastDateObj)
					// console.error('jjjjj-------', j, y, m, lastY, lastM, maxD, firstDay);
                    result[i].push(lData);
                }else {
					var dateObj = CALENDAR.solar2lunar(y, m+1, startD);
					var mData = Object.assign({
						curMonth: dateObj.cMonth == m+1 ? true : false
					}, dateObj);
                    result[i].push(mData);
                    startD = startD+1;
                }
            }
        }
        // 处理最后一周的数据:如果是新的一个月了,就删掉哦；
        if(!result[5][0].curMonth) {
            result.splice(-1, 1);
        }
        console.log('getSolarMonthArrayByYM-----', lastMD, firstDay, result);
        return result;
    },
    /**
     * 根据年月获取前一月的数据(从1号到月底)
     * @param {年} y
     * @param {月} m
     */
    getPrevSolarMonthByYM(y, m) {
        var newM = m <= 0 ? 11 : m-1,
            newY = m <= 0 ? y-1 : y;
        // console.error('getPrevMonthByYM-------', y, m, newY, newM)
        var result = CALENDAR_UTILS.getSolarMonthArrayByYM(newY, newM);
		return { y: newY, m: newM, arr: result };
    },
    /**
     * 根据年月获取后一月的数据(从1号到月底)
     * @param {年} y
     * @param {月} m
     */
    getNextSolarMonthByYM(y, m) {
        var newM = m >= 11 ? 0 : m+1,
            newY = m >= 11 ? y+1 : y;
        // console.error('getNextMonthByYM-------', y, m, newY, newM)
        var result = CALENDAR_UTILS.getSolarMonthArrayByYM(newY, newM);
		return { y: newY, m: newM, arr: result };
    },
    /**
     * 根据年月获取农历一个月的数据(从初一到月底)
     * @param {年} y
     * @param {月} m
     * @param {闰月} leap
     */
    getLunarMonthArrayByYM(y, m, leap) {
		var lD = 1, lDays = CALENDAR.checkLunarBigMonth(y, m) ? 30 : 29, lastLD = {},
			firstD = CALENDAR.lunar2solar(y, m, lD, leap), firstDay = firstD.nWeek%7, 
			cY = firstD.cYear, cM = firstD.cMonth, lastCD = CALENDAR_UTILS.getSolarMonth(cY, cM-2),
			cM = firstD.cDay-firstDay-1 >= 0 ? cM : cM-1;
		var cD = firstD.cDay-firstDay-1 >= 0 ? firstD.cDay-firstDay-1 : lastCD-(firstDay-firstD.cDay), 
			weekLen = firstDay == 6 && lDays == 30 ? 6 : 5;
		// console.error('cD------', cY, cM, cD, lastCD, firstDay, firstD.cDay)
		var tempArr = [], result = [], line = 0;
		for(var i = 0; i < weekLen; i++) {
			result[i] = result[i] ? result[i] : [];
			// 天数
			for(var j = 0; j < 7; j++) {
				// 前面几天
				if(i == 0 && j < firstDay) {
					var lastDateObj = CALENDAR.solar2lunar(cY, cM, cD);
					var lData = Object.assign({
						curMonth: false
					}, lastDateObj)
					result[i].push(lData);
					cD = cD+1;
				}else {
					if(lD <= lDays) {
						var dateObj = CALENDAR.lunar2solar(y, m, lD, leap);
						var dData = Object.assign({
							curMonth: dateObj.lMonth == m ? true : false
						}, dateObj);
						result[i].push(dData);
						lastLD = dateObj;
						lD = lD+1; 
					}else {
						// 前面几天
						var solarObj = CALENDAR.solar2lunar(lastLD.cYear, lastLD.cMonth, lastLD.cDay+1);
						var cData = Object.assign({
							curMonth: false
						}, solarObj);
						result[i].push(cData);
						lastLD = solarObj;
					}
				}
			}
		}
		console.error('getLunarMonthArrayByYM------', y, m, leap, result);
		return result;
	},
    /**
     * 根据年月获取农历前一个月的数据
     * @param {年} y
     * @param {月} m
     * @param {闰月} leap
     */
    getPrevLunarMonthByYM(y, m, leap) {
        var newM = m == 1 ? 12 : m-1,
            newY = m == 1 ? y-1 : y;
        var newLeap = CALENDAR.leapMonth(newY) != 0 && newM == CALENDAR.leapMonth(newY) ? true : false;
		var firstD = CALENDAR.lunar2solar(newY, newM, 1, newLeap);
        var result = CALENDAR_UTILS.getLunarMonthArrayByYM(newY, newM, newLeap);
        return { date: firstD, arr: result };
    },
    /**
     * 根据年月获取农历后一个月的数据
     * @param {年} y
     * @param {月} m
     * @param {闰月} leap
     */
    getNextLunarMonthByYM(y, m, leap) {
        var newM = m == 12 ? 1 : m+1,
            newY = m == 12 ? y+1 : y;
        var oldLeap = CALENDAR.leapMonth(y) != 0 && m == CALENDAR.leapMonth(y) ? true : false;
        var newLeap = CALENDAR.leapMonth(newY) != 0 && newM == CALENDAR.leapMonth(newY) ? true : false;
		var firstD = !leap && oldLeap ? CALENDAR.lunar2solar(y, m, 1, oldLeap) : CALENDAR.lunar2solar(newY, newM, 1, newLeap);
        var result = !leap && oldLeap ? CALENDAR_UTILS.getLunarMonthArrayByYM(y, m, oldLeap) : CALENDAR_UTILS.getLunarMonthArrayByYM(newY, newM, newLeap);
        return { date: firstD, arr: result };
    }
}

export {
	CALENDAR_UTILS
}

