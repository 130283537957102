<template>
	<div class="page-two page-second my-talent-page">
		<!-- 二级顶部导航栏 -->
		<second-top-bar :title="'性格特点'" :backFunc="clickMyBack"></second-top-bar>
		<div class="my-character-scroll-view">
			<!-- 外在性格 -->
			<div class="my-data-box-title w-94-p" v-if="userCharacterData[0]">
				<span class="title-line line1"></span><span class="title">{{userCharacterData[0].title}}</span>
			</div>
			<div class="my-data-box w-94-p" v-if="userCharacterData[0]">
				<div class="my-data-content">
					<div class="text1">{{userCharacterData[0].content}}</div>
				</div>
			</div>
			<!-- 内在性格 -->
			<div class="my-data-box-title w-94-p" v-if="userCharacterData[1]">
				<span class="title-line line2"></span><span class="title">{{userCharacterData[1].title}}</span>
			</div>
			<div class="my-data-box w-94-p" v-if="userCharacterData[1]">
				<div class="my-data-content">
					<div class="text1">{{userCharacterData[1].content}}</div>
				</div>
			</div>
			<!-- 其他 -->
			<div class="my-data-box w-94-p my-data-box-last">
				<div class="my-data-content">
					<div class="text2" v-for="ddText in userCharacterData[2]" :key="ddText">{{ddText}}</div>
				</div>
			</div>
			<div class="my-data-box-blank"></div>
		</div>
	</div>
</template>

<script>
	import SecondTopBar from '../../../components/common/SecondTopBar.vue'
	export default {
		name: "MyCharacterPage",
		components: { SecondTopBar },
		computed: {
			systemInfo() {
				return this.$store.state.systemInfo;
			},
			userCharacterData() {
				return this.$store.state.user.userCharacterData;
			}
		},
		data() {
			return {
				height: '500px'
			}
		},
		mounted() {
			setTimeout(function() {
				this.initDom();
			}.bind(this), 20)
		},
		methods: {
			initDom() {
				this.height = (this.systemInfo.windowHeight-50)+'px';
			},
			clickMyBack() {
				this.$router.replace('/my');
			},
			clickTab(curTab) {
				this.curTab = curTab;
			}
		}
	}
</script>

<style>
	
</style>
