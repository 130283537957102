// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/constellation-bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/constellation-default.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/constellation-blue.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/constellation-red.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/images/constellation-arrow.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".constellation-panel{width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:240px 240px;background-position:bottom}.constellation-circle{width:240px;height:240px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100% 100%;background-repeat:no-repeat;margin:0 auto}.constellation-circle.sex-man{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.constellation-circle.sex-woman{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.constellation-info{width:100%;height:45px}.constellation-icon-box,.constellation-text{width:100%;text-align:center}.constellation-text{color:#9598ff}.constellation-text.text-man{color:#1198ff}.constellation-text.text-woman{color:#ff76b8}.constellation-icon{display:inline-block;width:22px;height:20px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");background-size:100% 100%;background-repeat:no-repeat}", ""]);
// Exports
module.exports = exports;
