import * as consts from '../base/consts'

var WUXING = {
    // 获取天干的五行
    getTianGanXingByIndex(index) {
        var result;
        switch (index) {
            case 0:
            case 1:
                result = 1; break;       //木
            case 2:
            case 3:
                result = 2; break;       //火
            case 4:
            case 5:
                result = 3; break;       //土
            case 6:
            case 7:
                result = 4; break;       //金
            case 8:
            case 9:
                result = 0; break;       //水
            default:
                break;
        }
        return result;
    },
    // 获取地支的五行
    getDiZhiXingByIndex(index) {
        var result;
        switch (index) {
            case 2:
            case 3:
                result = 1; break;         //木
            case 5:
            case 6:
                result = 2; break;         //火
            case 1:
            case 4:
            case 7:
            case 10:
                result = 3; break;  //土
            case 8:
            case 9:
                result = 4; break;         //金
            case 0:
            case 11:
                result = 0; break;        //水
            default:
                break;
        }
        return result;
    },
    // 获取五行文字
    getWuXingByIndex(index) {
        return consts.CONST_WU_XING.charAt(index);
    },
    // 获取五行english
    getWuXingEnByIndex(index) {
        return consts.CONST_WU_XING_EN[index];
    },
    // 获取五行的数据类:同天干地支数据类
    getWuXingDataByIndex(index) {
        var data = {};
        data.text = this.getWuXingByIndex(index);
        data.propXing = this.getWuXingByIndex(index);
        data.propXingEn = this.getWuXingEnByIndex(index);
        return data;
    }
}

export {
    WUXING
}