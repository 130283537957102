<template>
	<div class="birthday-panel">
		<van-picker ref="bpicker" :columns="columns" :item-height="40" :visible-item-count="3" @change="onChange" />
	</div>
</template>

<script>
	import { UTILS } from '../../modules/utils'
	import * as consts from '../../modules/data/base/consts.js'
	import { CALENDAR } from '../../modules/calendar/calendar.js'
	export default {
		name: "BirthdayPanel",
		components: {  },
		data() {
			return {
				curTab: 0,
				isLunar: false,
				curIndex: [51, 1, 1, 1],
				defaultIndex: [51, 1, 1, 1],
				defaultDBArr: [false, false, false, false],
				years: [], months: [], days: [], hours: [], value: [],
				year: undefined, month: undefined, day: undefined, hour: undefined, minute: undefined,
				solarDate: undefined,
				columns: [],
			}
		},
		mounted() {
			this.$bus.on('toggleSolarBirthCalendar', this.initSolarCalendar);
			this.$bus.on('toggleLunarBirthCalendar', this.initLunarCalendar);
			this.$bus.on('checkBithCalendar', this.checkCalendar);
			setTimeout(function() {
				this.initSolarCalendar();
			}.bind(this), 0)
		},
		destroyed() {
			this.$bus.off('toggleSolarBirthCalendar', this.initSolarCalendar);
			this.$bus.off('toggleLunarBirthCalendar', this.initLunarCalendar);
			this.$bus.off('checkBithCalendar', this.checkCalendar);
		},
		methods: {
			toggleCalendar(type) {
				if(type == 0 && this.curTab != 0) {
					this.initSolarCalendar();
				}else if(type == 1 && this.curTab != 1) {
					this.initLunarCalendar();
				}
				this.curTab = type;
			},
			initSolarCalendar() {
				this.isLunar = false;
				let years = [], months = [], days = [], hours = [];
				for (let i = consts.CONST_START_END_YEAR[0]; i <= consts.CONST_START_END_YEAR[1]; i++) { years.push(i) }
				for (let i = 1; i <= 12; i++) { months.push(UTILS.getTimeStrByInt(i)) }
				let daysNum = this.defaultDBArr[0] && this.defaultDBArr[1] ? CALENDAR.solarDays(parseInt(this.year), parseInt(this.month)) : 31;
				// console.error('solardays-------', daysNum)
				for (let i = 1; i <= daysNum; i++) { days.push(UTILS.getTimeStrByInt(i)) }
				for (let i = 0; i <= 23; i++) { hours.push(UTILS.getTimeStrByInt(i)) }
				if(!this.defaultDBArr[0]) { years.splice(this.defaultIndex[0], 0, '请选择年') }; this.years = years;
				if(!this.defaultDBArr[1]) { months.splice(this.defaultIndex[1], 0, '月') }; this.months = months;
				if(!this.defaultDBArr[2]) { days.splice(this.defaultIndex[2], 0, '日') }; this.days = days;
				if(!this.defaultDBArr[3]) { hours.splice(this.defaultIndex[3], 0, '时') }; 
				hours.push('未知'); this.hours = hours;
				setTimeout(function() {
					this.initColumns();
				}.bind(this), 20);
				this.updateConstellationStatus();
			},
			initLunarCalendar() {
				this.isLunar = true;
				let years = [], months = [], days = [], hours = [];
				for (let i = consts.CONST_START_END_YEAR[0]; i <= consts.CONST_START_END_YEAR[1]; i++) { years.push(UTILS.getChineseWordByNumber('year', i)) }
				for (let i = 1; i <= 12; i++) { months.push(UTILS.getChineseWordByNumber('month', i)) }
				let daysNum = this.defaultDBArr[0] && this.defaultDBArr[1] ? CALENDAR.monthDays(parseInt(this.year), parseInt(this.month)) : 30;
				// console.error('lunardays-------', daysNum)
				for (let i = 1; i <= daysNum; i++) { days.push(UTILS.getChineseWordByNumber('day', i)) }
				for (let i = 0; i <= 23; i++) { hours.push(UTILS.getTimeStrByInt(i)) }
				if(!this.defaultDBArr[0]) { years.splice(this.defaultIndex[0], 0, '请选择年') }; this.years = years;
				if(!this.defaultDBArr[1]) { months.splice(this.defaultIndex[1], 0, '月') }; this.months = months;
				if(!this.defaultDBArr[2]) { days.splice(this.defaultIndex[2], 0, '日') }; this.days = days;
				if(!this.defaultDBArr[3]) { hours.splice(this.defaultIndex[3], 0, '时') }; 
				hours.push('未知'); this.hours = hours;
				setTimeout(function() {
					this.initColumns();
				}.bind(this), 20);
				this.updateConstellationStatus();
			},
			initColumns() {
				// console.error('initColumns------', this.$refs.bpicker, this.columns, this.defaultIndex)
				this.columns = [
					{ values: this.years, defaultIndex: this.defaultIndex[0] },
					{ values: this.months, defaultIndex: this.defaultIndex[1] },
					{ values: this.days, defaultIndex: this.defaultIndex[2] },
					{ values: this.hours, defaultIndex: this.defaultIndex[3] },
				]
				setTimeout(function() {
					this.$refs.bpicker.setColumnIndex(0, this.curIndex[0]);
					this.$refs.bpicker.setColumnIndex(1, this.curIndex[1]);
					this.$refs.bpicker.setColumnIndex(2, this.curIndex[2]);
					this.$refs.bpicker.setColumnIndex(3, this.curIndex[3]);
				}.bind(this), 0);
			},
			onChange(picker, value, index) {
				// console.error('onChange------', value, index, this.defaultDBArr[index], this.defaultIndex[index])
				// 去掉默认设置的选择年月日时分
				this.spliceDefaultYMDString(value, index);
				// 设置日历值，设置index
				switch(index) {
					case 0:
						this.curIndex[0] = this.years.indexOf(value[0]); this.year = value[0]; break;
					case 1:
						this.curIndex[1] = this.months.indexOf(value[1]); this.month = value[1]; break;
					case 2:
						this.curIndex[2] = this.days.indexOf(value[2]); this.day = value[2]; break;
					case 3:
						this.curIndex[3] = this.hours.indexOf(value[3]);
						this.hour = value[3] == '未知' ? '0' : value[3]; 
						break;
					default:
						break;

				}
				this.updateYMD();
				if((index == 0 || index == 1) && this.defaultDBArr[0] && this.defaultDBArr[1]) {
					this.updateCalendarDays();
				}
			},
			spliceDefaultYMDString(value, index) {
				if(!this.defaultDBArr[index]) {
					switch(index) {
						case 0: 
							this.years.splice(this.defaultIndex[0], 1); 
							this.curIndex[0] = this.years.indexOf(value[0]);
							break;
						case 1: 
							this.months.splice(this.defaultIndex[1], 1);
							this.curIndex[1] = this.months.indexOf(value[1]);
							break;
						case 2:
							this.days.splice(this.defaultIndex[2], 1);
							this.curIndex[2] = this.days.indexOf(value[2]);
							break;
						case 3: 
							this.hours.splice(this.defaultIndex[3], 1);
							this.curIndex[3] = this.hours.indexOf(value[3]);
							break;
						default: break;
					}
					this.defaultDBArr[index] = true;
					this.initColumns();
				}
			},
			updateYMD() {
				this.year = this.years[this.curIndex[0]] ? (this.isLunar ? UTILS.getNumberByChineseWord('year', this.years[this.curIndex[0]]) : parseInt(this.years[this.curIndex[0]])) : 0,
				this.month = this.months[this.curIndex[1]] ? (this.isLunar ? UTILS.getNumberByChineseWord('month', this.months[this.curIndex[1]]) : parseInt(this.months[this.curIndex[1]])) : 0,
				this.day = this.days[this.curIndex[2]] ? (this.isLunar ? UTILS.getNumberByChineseWord('day', this.days[this.curIndex[2]]) : parseInt(this.days[this.curIndex[2]])) : 0,
				this.hour = this.hours[this.curIndex[3]] ? (this.curIndex[3] == 24 ? 0 : parseInt(this.hours[this.curIndex[3]])) : 0;
				this.updateConstellationStatus();
			},
			updateConstellationStatus() {
				var year = this.year, month = this.month, day = this.day, solarDate;
				// console.error('updateConstellationStatus------', year, month, day)
				if(!year || !month || !day) {
					return;
				}
				if(this.isLunar) {
					solarDate = CALENDAR.lunar2solar(year, month, day);
					year = solarDate.cYear;
					month = solarDate.cMonth;
					day = solarDate.cDay;
				}
				this.solarDate = solarDate;
				if(month > 0 && day > 0) {
					var constellation = UTILS.getConstellationByMD(month, day);
					this.$bus.emit('updateConstellation', constellation);
				}	
			},
			updateCalendarDays() {
				let days = [];
				let daysNum = this.isLunar ? CALENDAR.monthDays(parseInt(this.year), parseInt(this.month)) : CALENDAR.solarDays(parseInt(this.year), parseInt(this.month));
				for (let i = 1; i <= daysNum; i++) { days.push(
					this.isLunar ? UTILS.getChineseWordByNumber('day', i) : UTILS.getTimeStrByInt(i)
				) }
				if(!this.defaultDBArr[2]) { days.splice(this.defaultIndex[2], 0, '日') };
				this.days = days.length > 0 ? [].concat(days) : this.days;
				// console.error('updateCalendarDays-------', this.year, this.month, daysNum, days)
				this.initColumns();
			},
			checkCalendar(params) {
				var toastText = '';
					toastText = !this.defaultDBArr[3] ? '请选择您出生的时间哟~' : toastText;
					toastText = !this.day ? '请选择您出生的日哟~' : toastText;
					toastText = !this.month ? '请选择您出生的月份哟~' : toastText;
					toastText = !this.year ? '请选择您出生的年份哟~' : toastText;
				console.log('checkCalendar------', params, this.curIndex, this.defaultDBArr[3], toastText, this.hour)
				if(toastText != '') {
                	this.$toast(toastText);
				}else {
					var minute = 0,
						hour = parseInt(this.hour), 
						year = this.isLunar ? this.solarDate.cYear : this.year,
						month = this.isLunar ? this.solarDate.cMonth : parseInt(this.month),
						day = this.isLunar ? this.solarDate.cDay : parseInt(this.day),
						timeStr = UTILS.getYmdHmsTimeStrByArr([year, month, day, hour, minute, 0]);
					var date = new Date(timeStr), newTimeStr = timeStr.replace(/-/g,'/');
						date = date == 'Invalid Date' ? new Date(newTimeStr) : date;
					// console.log('========', this.year, this.month, this.day, this.hour, hour, minute, timeStr, newTimeStr, date.getTime()/1000)
					// return;
					this.$set(this.$store.state.user.inputData, 'birthday', parseInt(date.getTime()/1000));
					if(params && params.cb && typeof(params.cb) == 'function') {
						params.cb();
					}
				}
			}
		}
	}
</script>

<style>
	.birthday-panel {
		width: 90%;
		padding: 10px 0;
		margin: 0 auto;
	}
	.van-picker__columns {
		background: transparent !important;
	}
	.van-picker__mask {
		display: none;
	}
	.van-picker-column {
		z-index: 2;
	}
	.van-picker-column__item {
		font-size: 0.85rem;
		color: rgba(255, 255, 255, 0.5);
	}
	.van-picker-column__item--selected {
		font-size: 1rem;
		font-weight: bold;
		color: rgba(255, 255, 255, 1);
		border: none;
	}
	.van-ellipsis {
		text-overflow: unset;
	}
	.van-picker__frame {
		background:rgba(0, 0, 0, 0.3);
		z-index: 0;
	}
</style>
