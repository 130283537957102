export default {
    1001: '业务参数验证错误',
    1002: 'Sign值校验错误',
    1003: 'Token值校验错误',
    1004: '游客账户禁止访问',
    1005: '系统错误',
    1006: 'Token值为空',
    2004: '用户不存在',
    2005: '密码错误',
    9001: '发送验证码超过在每日最大限制',
    9002: '验证码错误',
    9100: '八字数据没有找到',
    9101: '分组不为空',
    3600: '不能小于最低金额',
    3601: '可提现金额不够'
}