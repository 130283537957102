<template>
	<div class="calendar-info">
		<!-- 基础信息 -->
		<div class="calendar-info-detail" :class="className">
			<div class="calendar-info-detail-value dsp-flex">
				<div class="calendar-idv-date">
					<div class="calendar-idv-day">{{dateData.cDay}}</div>
					<div class="calendar-idv-week">{{dateData.ncWeek}}</div>
				</div>
				<div class="calendar-idv-value dsp-flex">
					<div class="calendar-idv-value-box " v-for="(aData, index) in infoData.ability" :key="aData.name">
						<calendar-value-bar v-if="!infoData.locked" :className="className+'-bar '+aData.wClass" :text="aData.name" :value="aData.score"></calendar-value-bar>
						<calendar-value-bar v-else :className="'default-bar'+(index+1)" :text="'能力'+(index+1)" :value="0"></calendar-value-bar>
					</div>
				</div>
			</div>
			<div v-if="infoData && infoData.dayMsg" class="calendar-info-detail-today pos-r">
				<div class="calendar-info-text dsp-flex" :class="{'blur': infoData.locked}">
					<div class="calendar-idt-title">概况：</div>
					<div>{{infoData.dayMsg.keyword != '' ? infoData.dayMsg.keyword : defaultKeyword}}</div>
				</div>
				<div class="calendar-info-text dsp-flex" :class="{'blur': infoData.locked}">
					<div class="calendar-idt-title">适合：</div>
					<div>{{infoData.dayMsg.good != '' ? infoData.dayMsg.good : defaultGood}}</div>
				</div>
				<div class="calendar-info-text dsp-flex" :class="{'blur': infoData.locked}">
					<div class="calendar-idt-title">注意：</div>
					<div>{{infoData.dayMsg.careful != '' ? infoData.dayMsg.careful : defaultCareful}}</div>
				</div>
				<div v-if="showBtn && !infoData.locked" class="calendar-info-button" @click="clickCheckHourStatus">
					<span v-if="!showHoursInfoList" class="calendar-info-button-text"><span class="iconfont icon-arrow-d21"></span>计算当日分时状态</span>
					<span v-else class="calendar-info-button-text"><span class="iconfont icon-arrow-d21 rotate"></span>收起当日分时状态</span>
				</div>
				<!-- 需要解锁状态 -->
				<div v-if="infoData.locked" class="calendar-hours-locked">
					<div class="calendar-hours-lock-text">马上解锁，可查看你在当前这一天的概况，突出能力的能量值，适合做的事情，并提前得到预警，得知对应的建议。还可查看当日的分时状态。</div>
					<div class="calendar-hours-lock-button-box">
						<button v-if="infoData.locked" class="calendar-hours-lock-button" @click="clickGoPay">马上解锁</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CalendarValueBar from './CalendarValueBar.vue';
	export default {
		name: "CalendarInfo",
		components: { CalendarValueBar },
		computed: {
			showHoursInfoList() {
				return this.$store.state.schedule.showHoursInfoList;
			}
		},
		props: {
			className: {
				type: String,
				default: ''
			},
			infoData: {
				type: Object,
				default() { return {} }
			},
			dateData: {
				type: Object,
				default() { return {} }
			},
			showBtn: {
				type: Boolean,
				default() { return false }
			}
		},
		data() {
			return {
				defaultKeyword: '非常焦虑的一天，也是很有挑战的一天。',
				defaultGood: '制定风险策略、深究问题根源、制定流程制度、攻克重大问题、养成某种自律习惯。',
				defaultCareful: '出行有阻，切勿躁，以免带来不必要的麻烦。',
			}
		},
		mounted() {
			setTimeout(function() {
				this.initAnimation()
			}.bind(this), 0)
		},
		methods: {
			initAnimation() {
				// var animation = uni.createAnimation();
				// animation.translateY(60).step({ duration: 300, timingFunction: 'ease' })
				// 		 .translateY(0).opacity(1).step({ duration: 200, timingFunction: 'ease' })
				// this.animationData = animation.export();
			},
			clickCheckHourStatus() {
				var userCurrentData = this.$store.state.user.userCurrentData;
				if(userCurrentData && userCurrentData.hourMsg && userCurrentData.hourMsg.needpay == 0) {
					this.$store.state.schedule.showHoursInfoList = !this.showHoursInfoList;
				}
			},
			clickGoPay() {
				this.$bus.$emit('showMyVipPop');
			}
		}
	}
</script>

<style>
	
</style>
