<template>
	<div class="page-first pg-schedule pos-r">
		<!-- 顶部bar -->
		<top-bar :className="'schedule-top-bar'"></top-bar>
		<!-- 日历部分 -->
		<calendar-one-panel></calendar-one-panel>
		<!-- <c-calendar></c-calendar> -->
		<!-- 日历信息 -->
		<div class="schedule-calender-info-box">
			<calendar-info
				v-bind:className="'schedule-calendar-info'"
				v-bind:showBtn="true"
				v-bind:infoData="userCurrentData"
				v-bind:dateData="currentDateInfo">
			</calendar-info>
			<calendar-hours-info-list v-if="showHoursInfoList"></calendar-hours-info-list>
			<!-- 宜忌信息 -->
			<calendar-yi-ji-panel></calendar-yi-ji-panel>
		</div>
		<!-- vip弹框 -->
		<my-vip-pop v-if="showPop"></my-vip-pop>
		<!-- 切换先知主人弹框 -->
		<my-userlist-pop v-if="showPop"></my-userlist-pop>
	</div>
</template>

<script>
	import TopBar from '../../components/common/TopBar.vue'
	import CalendarInfo from '../../components/calendar/CalendarInfo.vue'
	import CalendarOnePanel from '../../components/schedule/calendar/CalendarOnePanel.vue'
	import CalendarYiJiPanel from '../../components/schedule/calendar/CalendarYiJiPanel.vue'
	import CalendarHoursInfoList from '../../components/schedule/calendar/CalendarHoursInfoList.vue'
	export default {
		name: "Schedule",
		components: { TopBar, CalendarInfo, CalendarOnePanel, CalendarYiJiPanel, CalendarHoursInfoList },
		computed: {
			curTab() {
				return this.$store.state.schedule.curTab;
			},
			scheduleType() {
				return this.$store.state.schedule.scheduleType;
			},
			userCurrentData() {
				return this.$store.state.user.userCurrentData;
			},
			currentDateInfo() {
				return this.$store.state.calendar.currentDateInfo;
			},
			showHoursInfoList() {
				return this.$store.state.schedule.showHoursInfoList;
			}
		},
		data() {
			return {
				showPop: false
			}
		},
		onShow() {
			this.showPop = true;
		},
		onHide() {
			this.showPop = false;
		},
		methods: {
			
		}
	}
</script>

<style>
</style>
