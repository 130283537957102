<template>
	<div class="topbar" :class="className">
		<div class="topbar-calendar pos-r">
			<div v-if="!hideAvatar" class="topbar-user" @click="clickMyAvatar">
				<my-avatar :class="'top-my-avatar'"></my-avatar>
			</div>
			<div v-if="curTabIndex == 0 || curTabIndex == 2" class="topbar-title">{{curTabIndex == 0 ? '人生天气日历' : '主人信息'}}</div>
			<div v-if="curTabIndex == 1" class="topbar-today" @click="clickToday">
				<i class="iconfont icon-today"></i>
			</div>
			<div v-if="curTabIndex == 1" class="topbar-ymd-tab dsp-flex">
				<div class="topbar-ymd-item" :class="{'cur':calendarTab == 1}" @click="clickCalendarTab(1)">公历
					<div class="line" v-if="calendarTab == 1"></div>
				</div>
				<div class="topbar-ymd-item" :class="{'cur':calendarTab == 2}" @click="clickCalendarTab(2)">农历
					<div class="line" v-if="calendarTab == 2"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MyAvatar from "../../components/my/MyAvatar.vue"
	export default {
		name: "TopBar",
		components: { MyAvatar },
		computed: {
			curTabIndex() {
				return this.$store.state.curTabIndex;
			},
		},
		props: {
			className: {
				type: String,
				default: ''
			},
			hideAvatar: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				calendarTab: 1
			}
		},
		methods: {
			clickMyAvatar() {
				this.$bus.emit('showUserListPop');
			},
			clickToday() {
				this.$bus.emit('updateScheduleCalendar', true);
			},
			clickCalendarTab(curTab) {
				this.calendarTab = curTab;
				this.$bus.emit('toggleScheduleCalendar', curTab);
			},
			clickSumTab(curTab) {
				this.$store.state.summary.curTab = curTab;
			}
		}
	}
</script>