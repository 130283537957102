<template>
	<div class="tabbar">
		<div v-for="(item, index) in tabList" :key="index" class="tabbar-item" :class="curTabIndex == index ? 'on' : ''" @click="switchTab(index)">
			<div class="icon">
				<span class="iconfont" :class="curTabIndex == index ? item.icon2 : item.icon" :style="[curTabIndex == index ? {'color': tintColor} : {'color': color}]"></span>
				<span v-if="item.day" class="number" :style="[curTabIndex == index ? {'color': textColor} : {'color': color}]">{{item.day}}</span>
			</div>
			<div class="text" :style="[curTabIndex == index ? {'color': tintColor} : {'color': color}]">{{item.text}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TabBar',
		computed: {
			curTabIndex() {
				return this.$store.state.curTabIndex;
			},
			userAuthority() {
				return this.$store.state.user.authority;
			}
		},
		data() {
			return {
				tabList: [
					{
						icon: 'icon-calendar',
						icon2: 'icon-calendar2',
						text: '今日',
						day: 25,
					},
					{
						icon: 'icon-schedule',
						icon2: 'icon-schedule2',
						text: '日历',
					},
					{
						icon: 'icon-my',
						icon2: 'icon-my2',
						text: '主人'
					}
				]
			}
		},
		props: {
			current: {
				type: [Number, String],
				default: 0
			},
			textColor: {
				type: String,
				default: '#ffffff'
			},
			color: {
				type: String,
				default: '#1F314A'
			},
			tintColor: {
				type: String,
				default: '#474AD9'
			}
		},
		methods: {
			switchTab(index) {
				this.$store.state.curTabIndex = index;
				switch(index) {
					case 0:
						this.$router.replace('/calendar');
						break;
					case 1:
						this.$router.replace('/schedule');
						break;
					case 2:
						this.$router.replace('/my');
						break;
					default:
						break;
				}
				// this.$emit('click', index);
			}
		}		
	}
</script>
