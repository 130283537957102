
/**
 * 定义一些常量
 */


//四柱起盘:起始年份
export const CONST_START_END_YEAR = [1940, 2023];
// 分时状态列表的时间区间
export const CONST_START_END_TIME_STYLE = [6, 19];
export const CONST_START_END_TIME_HOUR = [7, 9, 11, 13, 15, 17, 19, 21];

// 十二星座
// 12/22-1/19摩羯, 1/20-2/18水瓶, 2/19-3/20双鱼, 3/21-4/19白羊, 4/20-5/20金牛, 5/21-6/20双子, 
// 6/21-7/22巨蟹, 7/23-8/22狮子, 8/23-9/22处女, 9/23-10/22天秤, 10/23-11/21天蝎, 11/22-12/21射手,
export const CONST_CONSTELLATION_START_DAY = [20, 19, 21, 20, 21, 21, 23, 23, 23, 23, 22, 22];
export const CONST_CONSTELLATION_END_DAY = [19, 18, 20, 19, 20, 20, 22, 22, 22, 22, 21, 21];
export const CONST_CONSTELLATION_TEXT = [
	{index: 9, cText: '摩羯座', cClass: 'mojie'},
	{index: 10, cText: '水瓶座', cClass: 'shuiping'},
	{index: 11, cText: '双鱼座', cClass: 'shuangyu'},
	{index: 0, cText: '白羊座', cClass: 'baiyang'},
	{index: 1, cText: '金牛座', cClass: 'jinniu'},
	{index: 2, cText: '双子座', cClass: 'shuangzi'},
	{index: 3, cText: '巨蟹座', cClass: 'juxie'},
	{index: 4, cText: '狮子座', cClass: 'shizi'},
	{index: 5, cText: '处女座', cClass: 'chunv'},
	{index: 6, cText: '天秤座', cClass: 'tianchen'},
	{index: 7, cText: '天蝎座', cClass: 'tianxie'},
	{index: 8, cText: '射手座', cClass: 'sheshou'},
];

// 年月日的中文字符
export const CONST_YEAR_WORD = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
export const CONST_MONTH_WORD = ["正", "二", "三", "四", "五", "六", "七", "八", "九", "十", "十一", "腊"];
export const CONST_DAY_WORD = ["初一", "初二", "初三", "初四", "初五", "初六", "初七", "初八", "初九", "初十", "十一", "十二", "十三", "十四", "十五", "十六", "十七", "十八", "十九", "二十", "二十一", "二十二", "二十三", "二十四", "二十五", "二十六", "二十七", "二十八", "二十九", "三十", "三十一"];

// 九大特征
export const CONST_CHARACTER_ONE_WORD = ['思', '灵', '集', '抗', '行', '通', '标', '解', '鬼'];
export const CONST_CHARACTER_WORD = ['思考能力', '创意灵感', '人员召集力', '抗压能力', '执行能力', '沟通表达', '目标意识', '办法对策', '鬼点子'];
//S:思考力 思, L:创意灵感, X:执行能力 行, T:沟通 通, J:对策 解
export const CONST_CHARACTER_FIVE_WORD = ['S', 'L', 'X', 'T', 'J']; 
export const CONST_CHARACTER_FIVE_WORD_CH = ['思考力', '灵感', '执行', '沟通', '对策']; 

// 十二时辰
export const CONST_HOUR_WORD = ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥'];
// 十二节
export const CONST_MONTH_FEST = ['立春', '惊蛰', '清明', '立夏', '芒种', '小暑', '立秋', '白露', '寒露', '立冬', '大雪', '小寒'];

//五行
export const CONST_WU_XING = "水木火土金";
export const CONST_WU_XING_EN = ["water", "wood", "fire", "soil", "gold"];
//天干
// export const CONST_TIAN_GAN = "0123456789";
export const CONST_TIAN_GAN = "甲乙丙丁戊己庚辛壬癸";
//地支
export const CONST_DI_ZHI = "子丑寅卯辰巳午未申酉戌亥";

// 宜忌json的URL地址拼接: URL+2021+'.json'
export const CONST_YI_JI_JSON_URL = "https://m1.yxdxt.com/config/";
export const CONST_YI_JI_YEAR = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
