<template>
	<div class="page-second my-status-page" :style="'height: '+height+'px'">
		<!-- 二级顶部导航栏 -->
		<second-top-bar :title="'按月状态'" :backFunc="clickMyBack"></second-top-bar>
		<!-- 内容部分 -->
		<div class="pad-t-18">
			<div class="my-data-box w-94-p white-box">
				<div class="my-data-content">
					<div class="my-data-title"><span>5月5日-6月2日能量状态走势</span></div>
					<!-- tab -->
					<div class="my-status-menu dsp-flex">
						<div class="my-status-item"><span class="text1 cur">思考力</span></div>
						<div class="my-status-item"><span class="text2 cur">灵感</span></div>
						<div class="my-status-item"><span class="text3 cur">执行</span></div>
						<div class="my-status-item"><span class="text4 cur">沟通</span></div>
						<div class="my-status-item"><span class="text5 cur">对策</span></div>
					</div>
					<div class="my-data-chart my-status-chart">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SecondTopBar from '../../../components/common/SecondTopBar.vue'
	export default {
		name: "MyStatusMonthPage",
		components: { SecondTopBar },
		computed: {
			userAuthority() {
				return this.$store.state.user.authority;
			},
		},
		data() {
			return {
				height: 640,
				curTab: 1,
				statusCategories: ['1月', '2月', '3月'],
				statusSeries: [{
					data: [20, 75, 95],
					color: '#007AFF'
				},{
					data: [80, 60, 90],
					color: '#FE5E8F'
				},{
					data: [50, 60, 70],
					color: '#07C160'
				},{
					data: [90, 50, 80],
					color: '#F8AA2A'
				},{
					data: [40, 70, 60],
					color: '#32C5FF'
				}],
			}
		},
		mounted() {
			setTimeout(function() {
				this.height = this.$store.state.systemInfo.screenHeight;
			}.bind(this), 10);
		},
		methods: {
			clickMyBack() {
				this.$router.replace('/my');
			},
			clickTab(curTab) {
				this.curTab = curTab;
			}
		}
	}
</script>

<style>
	
</style>
