<template>
	<div class="my-vip-pop">
		<div class="my-vip-pop-title" v-if="titleText.length != 0">
			<div class="title" v-for="tText in titleText" :key="tText">{{tText}}</div>
		</div>
		<div class="my-vip-choose-list dsp-flex">
			<!-- <div class="my-vip-choose-item-box" v-for="(pData, index) in productList" :key=pData.pid @click="clickItem(index)">
				<div class="my-vip-choose-item" :class="{'cur': curTab == index}">
					<div class="offer" v-if="curTab == index">限时特价</div>
					<div class="title">{{pData.paymsg}}</div>
					<div class="price">¥<span class="number">{{pData.priceText}}</span><span v-if="index == 0">/月</span></div>
					<div class="note">{{pData.priceSubText}}</div>
					<div class="gou" v-if="curTab == index"><i class="iconfont icon-gou"></i></div>
				</div>
			</div> -->
			<div class="my-vip-choose-item-box" @click="clickItem(0)">
				<div class="my-vip-choose-item" :class="{'cur': curTab == 0}">
					<div class="offer" v-if="curTab == 0">限时特价</div>
					<div class="title">{{productList[0].paymsg}}</div>
					<div class="price">¥<span class="number">{{productList[0].priceText}}</span><span>/月</span></div>
					<div class="note">{{productList[0].priceSubText}}</div>
					<div class="gou" v-if="curTab == 0"><i class="iconfont icon-gou"></i></div>
				</div>
			</div>
			<div class="my-vip-choose-item-box" @click="clickItem(1)">
				<div class="my-vip-choose-item" :class="{'cur': curTab == 1}">
					<div class="offer" v-if="curTab == 1">限时特价</div>
					<div class="title">{{productList[1].paymsg}}</div>
					<div class="price">¥<span class="number">{{productList[1].priceText}}</span></div>
					<div class="note note-del">{{productList[1].priceSubText}}</div>
					<div class="gou" v-if="curTab == 1"><i class="iconfont icon-gou"></i></div>
				</div>
			</div>
		</div>
		<div class="my-vip-pop-button">
			<button class="my-vip-button" type="default" @click="clickGo">继  续</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MyVipPanel",
		computed: {
			productList() {
				return this.$store.state.pay.productList;
			}
		},
		props: {
			fromPage: {
				type: String,
				default: ''
			},
			titleText: {
				type: Array,
				default: []
			},
		},
		data() {
			return {
				curTab: 0
			}
		},
		mounted() {
			// 获取商品列表
			var userBaseInfo = this.$store.state.user.userBaseInfo;
			this.$store.dispatch('pay/getProductList', {
				type: '1'
			});
		},
		methods: {
			clickItem(tab) {
				this.curTab = tab;
				if(this.fromPage == 'vippage') {
					this.$bus.emit('toggleVipItem', tab+1);
				}
			},
			clickGo() {
				var me = this;
				var curData = this.productList[this.curTab];
				var userBaseInfo = this.$store.state.user.userBaseInfo;
				console.log('clickgo', curData, userBaseInfo)
				
				// 月会员点击月会员付费
				if(userBaseInfo.f_type == 2 && curData.pid == "pay_10001") {
					this.$toast('你已经是月会员了~');
					return;
				}
				// 支付 todo 平台要区分ptype
				this.$store.dispatch('pay/createPayOrder', {
					params: {
						pid: curData.pid,
						ptype: curData.payType[0],
						ext: userBaseInfo.id
					},
					sucCb: function(res) {
						console.log('支付成功:::::::', res)
						me.$toast('支付成功~');
						me.$bus.emit('hideMyVipPop');
						// 刷新数据
						setTimeout(function() {
							me.$store.dispatch('user/getUserHomePageData');
						}, 200);
					},
					failCb: function(res) {
						console.error('支付失败:::::::', res)
						me.$toast('支付失败，请稍后再试~');
						me.$bus.emit('hideMyVipPop');
					}
				});
			}
		}
	}
</script>

<style>
</style>
