<template>
	<div class="schedule-calendar-panel">
		<div class="calendar-title dsp-flex">
			<div class="calendar-title-left">
				<!-- todo iconfont -->
				<span class="title" v-if="!isLunar">{{year ? year : ''}}年{{month || month==0 ? month+1 : ''}}月</span>
				<span class="title" v-else>{{lunarDate && lunarDate.IYearCn ? lunarDate.IYearCn : ''}}年{{lunarDate && lunarDate.IMonthCn ? lunarDate.IMonthCn : ''}}</span>
				<i class="iconfont icon-arrow-d2"></i>
			</div>
			<div class="calendar-title-right">
				<span class="calendar-prev" @click="clickPrevMonth">
					<i class="iconfont icon-arrow-l"></i>上月
				</span>
				<span class="calendar-next" @click="clickNextMonth">
					下月<i class="iconfont icon-arrow-r"></i>
				</span>
			</div>
		</div>
		<div class="calendar-week-title dsp-flex">
			<div class="calendar-side"></div>
			<div class="calendar-week-item" v-for="weekTitle in CALENDAR.nStr1.slice(0, 7)" :key="weekTitle">{{'周'+weekTitle}}</div>
			<div class="calendar-side"></div>
		</div>
		<div class="calendar-month-week-box" v-if="!showHoursInfoList">
			<div v-if="monthArray.length > 0" :class="{'calendar-month-five': !sixWeek, 'calendar-month-six': sixWeek}">
				<div class="calendar-month-week dsp-flex" v-for="(week, index) in monthArray" :key="index">
					<div class="calendar-side"></div>
					<div class="calendar-month-item" :class="{'disabled': !data.curMonth, 'selected': data.selected}" v-for="data in week" :key="data.cDay" @click="clickDay(data, index)">
						<div v-if="data.isInFiveDay && data.cYear == nYear && data.cMonth == nMonth+1 && userMonthData[data.cYMD]" class="item-title" :class="'item-title-'+data.zhiD">{{userMonthData[data.cYMD].title}}</div>
						<div v-if="data.isToday" class="item-status today"><div class="today-bg"></div></div>
						<div v-if="data.selected" class="item-hover"></div>
						<div class="solar-text">{{data.cDay}}</div>
						<div class="lunar-text">{{data.IDayCn}}</div>
					</div>
					<div class="calendar-side"></div>
				</div>
			</div>
		</div>
		<div class="calendar-week-box" v-else>
			<div class="calendar-month-week dsp-flex">
				<div class="calendar-side"></div>
				<div class="calendar-month-item" :class="{'disabled': !data.curMonth, 'selected': data.selected}" v-for="data in weekArray" :key="data.cDay" @click="clickDay(data, index)">
					<!-- userMonthData[data.cYMD].needpay == 0 -->
					<div v-if="data.isInFiveDay && data.cYear == nYear && data.cMonth == nMonth+1 && userMonthData[data.cYMD]" class="item-title" :class="'item-title-'+data.zhiD">{{userMonthData[data.cYMD].title}}</div>
					<div v-if="data.isToday" class="item-status today"><div class="today-bg"></div></div>
					<div v-if="data.selected" class="item-hover"></div>
					<div class="solar-text">{{data.cDay}}</div>
					<div class="lunar-text">{{data.IDayCn}}</div>
				</div>
				<div class="calendar-side"></div>
			</div>
		</div>
		<div class="calendar-month-note-box" v-if="!showHoursInfoList">
			<div class="calendar-month-note-line dsp-flex">
				<div class="calendar-mn-item" :class="{'calendar-mn-item-mid':index==2}" v-for="(titleData, index) in titleArray" :key="titleData.titleL"><span class="calendar-mni-abb">{{titleData.titleS}}</span>{{titleData.titleL}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as consts from '../../../modules/data/base/consts.js'
	import { UTILS } from '../../../modules/utils'
	import { CALENDAR } from '../../../modules/calendar/calendar.js'
	import { CALENDAR_UTILS } from '../../../modules/calendar/calendarutils.js'
	export default {
		name: "CalendarOnePanel",
		computed: {
			userMonthData() {
				return this.$store.state.user.userMonthData;
			},
			showHoursInfoList() {
				return this.$store.state.schedule.showHoursInfoList;
			}
		},
		data() {
			return {
				CALENDAR: CALENDAR,
				nYear: undefined, nMonth: undefined, nDay: undefined,
				year: undefined, month: undefined, day: undefined,
				monthArray: [], weekArray: [], titleArray: [],
				sixWeek: false,
				isLunar: false,
				lunarDate: {},
			}
		},
		mounted() {			
			this.$bus.on('updateScheduleCalendar', this.initCalendar);
			this.$bus.on('toggleScheduleCalendar', this.toggleSolarLunarCalendar);
			setTimeout(() => {
				this.initCalendar();
			}, 100);
		},
		destroyed() {
			this.$bus.off('updateScheduleCalendar', this.initCalendar);
			this.$bus.off('toggleScheduleCalendar', this.toggleSolarLunarCalendar);
		},
		methods: {
			initCalendar(clickToday=false) {
				var now = new Date();
				this.year = now.getFullYear(); this.nYear = now.getFullYear();
				this.month = now.getMonth(); this.nMonth = now.getMonth();
				this.day = now.getDate(); this.nDay = now.getDate();
				this.lunarDate = CALENDAR.solar2lunar(this.year, this.month+1, this.day);
				if(this.isLunar) {
					this.showLunarCalendarMonth();
				}else {
					this.showCalendarMonth();
				}
				// console.error('initCalendar-----', this.lunarDate, clickToday)
				if(clickToday) {
					this.$store.dispatch('user/getUserCurrentData', {isToday: true});
					this.$store.state.calendar.currentDateInfo = Object.assign({}, this.lunarDate);
					this.$store.state.schedule.showHoursInfoList = false;
					this.selectDay(this.lunarDate);
				}else {
					this.getCalendarDate();
				}
			},
			getCalendarDate() {
				var me = this, monthDay = CALENDAR.solarDays(this.year, this.month+1),
					ymdStr = this.year+UTILS.getTimeStrByInt(this.month+1)+'01';
				console.log('getCalendarDate-----', this.year, this.month, this.day, monthDay)
				if(this.day+4 > monthDay) {
					var nYmdStr = this.year+UTILS.getTimeStrByInt(this.month+2)+'01';
					// console.log('getCalendarDate-----ymdStr------', nYmdStr);
					this.$store.dispatch('user/getUserMonthData', {
						ymd: ymdStr,
						cb: function() {
							// 多拉取一个月的数据先
							me.$store.dispatch('user/getUserMonthData', {
								ymd: nYmdStr,
								cb: function() {
									setTimeout(function() {
										me.selectDay(me.lunarDate);
									}, 20)
								}
							})
						}
					});
				}else  {
					// console.log('getCalendarDate-----ymdStr------', ymdStr, me.lunarDate);
					this.$store.dispatch('user/getUserMonthData', {
						ymd: ymdStr,
						cb: function() {
							setTimeout(function() {
								me.selectDay(me.lunarDate);
							}, 20)
						}
					});
				}
				this.getDayYiJiData(this.year, this.month+1, this.day);
			},
			toggleSolarLunarCalendar(tab) {
				if(tab == 1) {
					// 显示公历
					if(!this.isLunar) { return; }
					this.isLunar = false;
					this.showCalendarMonth();
				}else if(tab == 2) {
					// 显示农历
					if(this.isLunar) { return; }
					this.isLunar = true;
					this.showLunarCalendarMonth();
				}
			},
			showLunarCalendarMonth() {
				this.monthArray.splice(0);
				this.lunarDate = CALENDAR.solar2lunar(this.year, this.month+1, this.day);
				setTimeout(function() {
					this.monthArray = CALENDAR_UTILS.getLunarMonthArrayByYM(this.lunarDate.lYear, this.lunarDate.lMonth, this.lunarDate.isLeap);
					this.sixWeek = this.monthArray.length >= 6 ? true : false;
				}.bind(this), 20)
				console.log('showLunarCalendarMonth-----', this.year, this.month, this.day, this.lunarDate);
			},
			showCalendarMonth() {
				this.monthArray.splice(0);
				setTimeout(function() {
					this.monthArray = CALENDAR_UTILS.getSolarMonthArrayByYM(this.year, this.month);
					this.sixWeek = this.monthArray.length >= 6 ? true : false;
				}.bind(this), 20)
				console.log('showCalendarMonth-----', this.monthArray)
			},
			clickPrevMonth() {
				var result = this.isLunar ? CALENDAR_UTILS.getPrevLunarMonthByYM(this.lunarDate.lYear, this.lunarDate.lMonth, this.lunarDate.isLeap) : CALENDAR_UTILS.getPrevSolarMonthByYM(this.year, this.month);
				if(this.isLunar) {
					this.lunarDate = Object.assign({}, result.date);
					this.year = result.date.cYear; this.month = result.date.cMonth-1;
				}else {
					this.year = result.y; this.month = result.m;
				}
				this.monthArray.splice(0);
				setTimeout(function() {
					this.monthArray = result.arr;
					this.sixWeek = this.monthArray.length >= 6 ? true : false;
				}.bind(this), 20)
				this.getCalendarDate();
				console.log('clickPrevMonth-----', this.year, this.month)
			},
			clickNextMonth() {
				var result = this.isLunar ? CALENDAR_UTILS.getNextLunarMonthByYM(this.lunarDate.lYear, this.lunarDate.lMonth, this.lunarDate.isLeap) : CALENDAR_UTILS.getNextSolarMonthByYM(this.year, this.month);
				if(this.isLunar) {
					this.lunarDate = Object.assign({}, result.date);
					this.year = result.date.cYear; this.month = result.date.cMonth-1;
				}else {
					this.year = result.y; this.month = result.m;
				}
				this.monthArray.splice(0);
				setTimeout(function() {
					this.monthArray = result.arr;
					this.sixWeek = this.monthArray.length >= 6 ? true : false;
				}.bind(this), 20)
				this.getCalendarDate();
				console.log('clickNextMonth-----', this.year, this.month)
			},
			clickDay(dData, index) {
				if(!dData.curMonth) { return; }
				this.$store.dispatch('user/getUserCurrentData', dData);
				this.$store.state.calendar.currentDateInfo = Object.assign({}, dData);
				this.$store.state.schedule.showHoursInfoList = false;
				this.selectDay(dData, index);
			},
			selectDay(dData, index=-1) {
				var me = this, tempArr = [].concat(this.monthArray), curIndex = -1, fiveArr = [], fiveTitleArr = [];
				tempArr.forEach((weekArray, x)=>{
					weekArray.forEach((day, y)=>{
						tempArr[x][y].selected = day.cYMD == dData.cYMD ? true : false;
						curIndex = day.cYMD == dData.cYMD ? x : curIndex;
						if(day.isInFiveDay && day.cYear == me.nYear && day.cMonth == me.nMonth+1) {
							fiveArr.push(day);
						}
					});
				});
				this.monthArray.splice(0);
				this.monthArray = tempArr;
				this.getDayYiJiData(dData.cYear, dData.cMonth, dData.cDay);
				
				if(index >= 0) {
					if(this.weekArray.length > 0) { this.weekArray.splice(0); }
					this.weekArray = [].concat(this.monthArray[index]);
				}else {
					if(this.weekArray.length > 0) { this.weekArray.splice(0); }
					this.weekArray = [].concat(this.monthArray[curIndex]);
				}
				console.error('selectDay======', index, curIndex, this.weekArray)
				// console.log('selectDay----', dData, this.monthArray)
				
				// 处理当前5天的数据
				var userMonthData = this.$store.state.user.userMonthData, tempObj = {}, tempArr = [];
				for(var i = 0; i < fiveArr.length; i++) {
					var fData = fiveArr[i];
					if(userMonthData[fData.cYMD]) {
						var fTitle = userMonthData[fData.cYMD].title;
						if(!tempObj[fTitle]) {
							tempObj[fTitle] = true;
							fiveTitleArr.push(fTitle);
						}
					}
				}
				for(var j = 0; j < fiveTitleArr.length; j++) {
					var title = fiveTitleArr[j], index = consts.CONST_CHARACTER_ONE_WORD.indexOf(title);
					tempArr.push({
						titleS: title, titleL: consts.CONST_CHARACTER_WORD[index]
					});
				}
				this.titleArray.splice(0);
				this.titleArray = [].concat(tempArr);
				// console.error('fiveTitleArr-----', fiveTitleArr, tempArr, this.titleArray)
			},
			getDayYiJiData(year, month, day) {
				var curYmdStr = year+'-'+UTILS.getTimeStrByInt(month)+'-'+UTILS.getTimeStrByInt(day);
				console.log('getTodayYiJiData------', curYmdStr)
				this.$store.dispatch('getYiJiCurrentDataByYear', {
					year: year, ymd: curYmdStr
				});
			}
		}
	}
</script>

<style>
	.schedule-calendar-panel {
		padding-top: 64px;
		padding-bottom: 10px;
	}
	.schedule-calendar-status-item {
		width: 12.5%;
		text-align: center;
		white-space: nowrap;
		word-break: keep-all;
		border-right: solid 1px #E5E5E5;
		padding-bottom: 20px;
	}
	.schedule-calendar-status-item:last-child {
		border-right: none;
	}
	.schedule-time-text {
		font-size: 22px;
		color: #636F81;
	}
	.schedule-word-text {
		line-height: 40px;
		font-size: 26px;
		color: #243B6B;
		position: relative;
	}
	.schedule-word-text .schedule-dot {
		bottom: -15px;
		width: 12px;
		height: 12px;
		margin-left: -6px;
	}
</style>
