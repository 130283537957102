<template>
	<div class="page-second my-vip-page">
		<!-- 二级顶部导航栏 -->
		<second-top-bar :className="'my-vip-second-top-bar'" :title="'特权功能开通提示'" :backFunc="clickMyBack"></second-top-bar>
		<!-- 标题部分 -->
		<div class="my-vip-title">
			<div class="title">此功能为VIP会员特权功能，</div>
			<div class="title">开通会员后可立享此功能在内的特权</div>
		</div>
		<div class="my-vip-content-box">
			<div class="my-vip-content" :class="'content'+type"></div>
		</div>
		<!-- vip特权列表 -->
		<!-- <div class="my-vip-privilege pos-r">
			<div class="my-vipp-title dsp-flex">
				<div class="my-vipp-text text1">特权</div>
				<div class="my-vipp-text text2">非会员</div>
				<div class="my-vipp-text text3">3天试用会员</div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}">月会员</div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}">终身会员</div>
			</div>
			<div class="my-vipp-item line-odd dsp-flex">
				<div class="my-vipp-text text1">今日先知概况</div>
				<div class="my-vipp-text text2"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text3"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}"><span class="iconfont icon-ok"></span></div>
			</div>
			<div class="my-vipp-item line-even dsp-flex">
				<div class="my-vipp-text text1">明日先知概况</div>
				<div class="my-vipp-text text2"><span class="iconfont icon-no"></span></div>
				<div class="my-vipp-text text3"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}"><span class="iconfont icon-ok"></span></div>
			</div>
			<div class="my-vipp-item line-odd dsp-flex">
				<div class="my-vipp-text text1">先知未来天数</div>
				<div class="my-vipp-text text2">仅当天</div>
				<div class="my-vipp-text text3">未来3天内</div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}">未来30天内</div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}">未来90天内</div>
			</div>
			<div class="my-vipp-item line-even dsp-flex">
				<div class="my-vipp-text text1">先知未来小时数</div>
				<div class="my-vipp-text text2">未来2小时</div>
				<div class="my-vipp-text text3">全天</div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}">全天</div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}">全天</div>
			</div>
			<div class="my-vipp-item line-odd dsp-flex">
				<div class="my-vipp-text text1">明日分时状态先知</div>
				<div class="my-vipp-text text2"><span class="iconfont icon-no"></span></div>
				<div class="my-vipp-text text3"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}"><span class="iconfont icon-ok"></span></div>
			</div>
			<div class="my-vipp-item line-even dsp-flex">
				<div class="my-vipp-text text1">历史日期状态回顾</div>
				<div class="my-vipp-text text2"><span class="iconfont icon-no"></span></div>
				<div class="my-vipp-text text3">过去7天内</div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}">过去30天内</div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}">过去10年内</div>
			</div>
			<div class="my-vipp-item line-odd dsp-flex">
				<div class="my-vipp-text text1">性格特点</div>
				<div class="my-vipp-text text2"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text3"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}"><span class="iconfont icon-ok"></span></div>
			</div>
			<div class="my-vipp-item line-even dsp-flex">
				<div class="my-vipp-text text1">先天天赋</div>
				<div class="my-vipp-text text2"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text3"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}"><span class="iconfont icon-ok"></span></div>
			</div>
			<div class="my-vipp-item line-odd dsp-flex">
				<div class="my-vipp-text text1">后天天赋</div>
				<div class="my-vipp-text text2"><span class="iconfont icon-no"></span></div>
				<div class="my-vipp-text text3"><span class="iconfont icon-no"></span></div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}"><span class="iconfont icon-ok"></span></div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}"><span class="iconfont icon-ok"></span></div>
			</div>
			<div class="my-vipp-item line-even dsp-flex">
				<div class="my-vipp-text text1">按日能力状态</div>
				<div class="my-vipp-text text2"><span class="iconfont icon-no"></span></div>
				<div class="my-vipp-text text3">7天</div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}">未来30天</div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}">未来90天</div>
			</div>
			<div class="my-vipp-item line-odd dsp-flex">
				<div class="my-vipp-text text1">按月能力状态</div>
				<div class="my-vipp-text text2"><span class="iconfont icon-no"></span></div>
				<div class="my-vipp-text text3">当月</div>
				<div class="my-vipp-text text4" :class="{'cur': type==1}">未来3个月</div>
				<div class="my-vipp-text text5" :class="{'cur': type==2}">未来12个月</div>
			</div>
			<div class="my-vipp-cur-box" :class="'cur-box-'+type"></div>
		</div> -->
		<!-- 弹框 -->
		<div class="my-vip-panel-box">
			<my-vip-panel
				:fromPage="'vippage'">
			</my-vip-panel>
		</div>
	</div>
</template>

<script>
	import SecondTopBar from '../../../components/common/SecondTopBar.vue'
	import MyVipPanel from "../../../components/my/MyVipPanel.vue"
	export default {
		name: "MyVipPage",
		components: { SecondTopBar, MyVipPanel },
		data() {
			return {
				type: 1
			}
		},
		onShow() {
			this.$bus.on('toggleVipItem', this.initVipItem)
		},
		onHide() {
			this.$bus.off('toggleVipItem', this.initVipItem)
		},
		methods: {
			clickMyBack() {
				this.$router.replace('/my');
			},
			initVipItem(index) {
				this.type = index;
			}
		}
	}
</script>

<style>
</style>
