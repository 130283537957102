<template>
	<div class="calendar-weather-info dsp-flex">
		<div v-if="weatherInfo && weatherInfo.today" class="calendar-weather-item calendar-weather-item-bd dsp-flex">
			<div class="calendar-weather-il">
				<div class="w-text">今天</div>
				<div class="w-number">{{weatherInfo.today.night_air_temperature+'/'+weatherInfo.today.day_air_temperature+'℃'}}</div>
			</div>
			<div class="calendar-weather-ir">
				<div class="w-text">{{weatherInfo.today.day_weather}}</div>
				<div class="w-icon">
					<img class="w-icon-img" :src="weatherInfo.today.day_weather_pic" mode="" />
				</div>
			</div>
		</div>
		<div v-if="weatherInfo && weatherInfo.tomorrow" class="calendar-weather-item dsp-flex">
			<div class="calendar-weather-il">
				<div class="w-text">明天</div>
				<div class="w-number">{{weatherInfo.tomorrow.night_air_temperature+'/'+weatherInfo.tomorrow.day_air_temperature+'℃'}}</div>
			</div>
			<div class="calendar-weather-ir">
				<div class="w-text">{{weatherInfo.tomorrow.day_weather}}</div>
				<div class="w-icon">
					<img class="w-icon-img" :src="weatherInfo.tomorrow.day_weather_pic" mode="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CalendarWeatherInfo",
		computed: {
			weatherInfo() {
				return this.$store.state.weatherInfo;
			}
		}
	}
</script>

<style>
</style>
