import { API } from '../../../modules/api';
import { DATA } from '../../../modules/data'


export default {
    namespaced: true,
    state: {
		gotProductData: false,
		productList: [],
    },
    mutations: {},
    actions: {
		getProductList({state}, params) {
			if(state.gotProductData) { return; }
			API.getProductList({
				type: params.type.toString()
			}, function(data) {
				console.log('获取商品列表成功:::::::', data)
				state.gotProductData = true;
				state.productList = DATA.getProductDataByData(data);
			})
		},
		createPayOrder({state}, params) {
			console.error('createPayOrder------', params)
			API.createPayOrder(params.params, function(data) {
				console.log('下单成功:::::::', data);
				
				wx.requestPayment({
					timeStamp: data.return.timeStamp,
					nonceStr: data.return.nonceStr,
					package: data.return.package,
					signType: data.return.signType,
					paySign: data.return.paySign,
					success(res) {
						if(params && params.sucCb && typeof(params.sucCb) == 'function') {
							params.sucCb(res);
						}
					},
					fail(res) {
						if(params && params.failCb && typeof(params.failCb) == 'function') {
							params.failCb(res);
						}
					}
				})
			})
		},
		resetData({state}) {
			state.productList = [];
		}
    }
}