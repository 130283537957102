<template>
	<div class="page-one page-home">
		<div class="login-text text1">
			欢迎来到「人生天气日历」
		</div>
		<div class="login-text">
			<span v-if="showWxLogin">请您先登录哟~</span>
			<!-- <span v-else>登录中...</span> -->
		</div>
		<!-- 欢迎界面: -->
		<!-- <button class="login-button" v-if="showWxLogin" open-type="getUserInfo" @getuserinfo="bindGetUserInfo">授权登录</button> -->
		<!-- <load-image-list></load-image-list> -->
	</div>
</template>

<script>
	import loadImageList from '../../components/common/LoadImageList.vue'
	export default {
		name: "Home",
		components: { loadImageList },
		data() {
			return {
				showWxLogin: false,
			}
		},
		mounted() {
			setTimeout(function() {
				// for test
				// this.gotoStartPage(23, 113);
				// return;
				// for test
				this.gotoStartPage();
			}.bind(this), 20);
		},
		methods: {
			/**
			 * 登录成功之后跳转到开始页面
			 */
			gotoStartPage() {
				let me = this;
				var userData = this.$store.state.userData;
				console.error('gotoStartPage----')
				// 0是老用户,1是新用户
				if(userData.isnewuser == 1) {
					this.$bus.emit('loadImageList', {
						fromPage: 'home',
						cb: function() {
							this.$router.replace('/start');
						}
					})
				}else {
					// for test
					// this.$set(this.$store.state.user.inputData, 'sex', 0);
					// this.$router.replace('/generate');
					// return;
					// for test
					
					// 获取我的用户列表数据
					this.$store.dispatch('user/getUserHomePageData', {
						cb: function() {
							setTimeout(() => {
								me.$router.replace('/calendar');
							}, 1000);
						},
						failCb: function() {
							setTimeout(() => {
								me.$router.replace('/start');
							}, 1000);
						}
					})
				}
			},
		}
	}
</script>
