<template>
	<div class="calendar-value-bar" :class="className">
		<div class="calendar-value-text dsp-flex">
			<div class="calendar-value-note">{{text.slice(0, 4)}}</div>
			<div class="calendar-value-num">+{{value == 0 ? '?' : value}}</div>
		</div>
		<div class="calendar-value-progress-box">
			<div class="calendar-value-progress">
				<div class="calendar-value-progress-cur" :style="'width: '+this.value+'%'"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CalendarValueBar",
		props: {
			className: {
				type: String,
				default: ''
			},
			text: {
				type: String,
				default: ''
			},
			value: {
				type: Number,
				default: 50
			}
		},
		data() {
			return {
				animationData: {},
			}
		},
		mounted() {
			this.initAnimation();
		},
		updated() {
			this.initAnimation();
		},
		methods: {
			initAnimation() {
				// if(this.value == 0) { return; }
				// var animation = uni.createAnimation();
				// setTimeout(function() {
				// 	animation.width(this.value+'%').step({duration: this.value*10, timingFunction: 'ease'});
				// 	this.animationData = animation.export()
				// }.bind(this), 500)
			}
		}
	}
</script>

<style>
</style>
