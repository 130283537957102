import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'

import userStore from '../../modules/store/modules/user'
import payStore from '../../modules/store/modules/pay'
import scheduleStore from '../../modules/store/modules/schedule'
import calendarStore from '../../modules/store/modules/calendar'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
		user: userStore,
		pay: payStore,
		schedule: scheduleStore,
		calendar: calendarStore,
	},
    state: {
		platform: 0,
		shareTimes: 0,
		gotUserInfo: false,
		userData: {},
		userInfo: {},
		timeInfo: {},
		systemInfo: {},
		weatherInfo: {},
		yijiTotalData: {},
		yijiCurrentData: {},
		gotBasicConfig: false,
		basicConfigData: {},
		curTabIndex: 0,
		gotMyUserList: false,
		myUserList: [],
		hideAllCharts: false
	},
    getters: {},
    mutations, 
    actions
});