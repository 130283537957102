<template>
	<div class="my-status-day-panel">
		<div class="my-status-panel-title">{{(userBaseInfo.f_type==0?defaultTitle:(userStatusDayDate.start+'-'+userStatusDayDate.end))+'能量状态走势'}}</div>
		<!-- 几个切换tab按钮 -->
		<div class="my-status-menu dsp-flex">
			<div class="my-status-item" @click="clickTab(0)"><span class="text1" :class="{'cur': curTab == 0}">{{tabArr[0]}}</span></div>
			<div class="my-status-item" @click="clickTab(1)"><span class="text2" :class="{'cur': curTab == 1}">{{tabArr[1]}}</span></div>
			<div class="my-status-item" @click="clickTab(2)"><span class="text3" :class="{'cur': curTab == 2}">{{tabArr[2]}}</span></div>
			<div class="my-status-item" @click="clickTab(3)"><span class="text4" :class="{'cur': curTab == 3}">{{tabArr[3]}}</span></div>
			<div class="my-status-item" @click="clickTab(4)"><span class="text5" :class="{'cur': curTab == 4}">{{tabArr[4]}}</span></div>
		</div>
		<!-- 表格部分 -->
		<div v-if="userBaseInfo.f_type != 0" class="my-line-data-chart">
			<div class="line-charts" v-bind:id="canvasId"></div>
		</div>
		<!-- 模糊背景部分 -->
		<div v-if="userBaseInfo.f_type == 0" class="my-area-data-bg-box">
			<img class="my-area-data-bg" :src="dataBg" mode="" />
			<div class="my-area-data-bg-text">{{'马上解锁，可以查看在未来90天，你在'+tabArr[curTab-1]+'方面，能量的起伏变化。'}}</div>
		</div>
		<!-- 按钮部分 -->
		<div v-if="userBaseInfo.f_type == 0" class="my-status-btn-box">
			<button class="my-status-btn btn-long red" @click="clickPayDay(30)">查看未来30天</button>
		</div>
		<div v-if="userBaseInfo.f_type == 1" class="my-status-btn-box">
			<button class="my-status-btn btn-short blue" @click="clickPayDay(30)">查看未来30天</button>
			<button class="my-status-btn btn-short red" @click="clickPayDay(90)">查看未来90天</button>
		</div>
		<div v-if="userBaseInfo.f_type == 2" class="my-status-btn-box">
			<button class="my-status-btn btn-long red" @click="clickPayDay(90)">查看未来90天</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MyStatusDayPanel",
		computed: {
			hideAllCharts() {
				return this.$store.state.hideAllCharts;
			},
			userBaseInfo() {
				return this.$store.state.user.userBaseInfo;
			},
			userAuthority() {
				return this.$store.state.user.authority;
			},
			userStatusDayDate() {
				return this.$store.state.user.userStatusDayDate;
			}
		},
		data() {
			return {
				tabArr: ['思考力', '灵感', '执行', '沟通', '对策'],
				colorArr: ['#007AFF', '#FE5E8F', '#07C160', '#F8AA2A', '#32C5FF'],
				defaultTitle: '按日',
				dataBg: '../../assets/images/day-bg.png',
				curTab: 0,
				canvasId: 'statusDayChart',
				categories: [],
				allDataList: [],
				lineChart: null
			}
		},
		mounted() {
			setTimeout(function() {
				this.getServerData();
			}.bind(this), 20);
			this.$bus.on('updatemystatusday', this.getServerData);
		},
		destroyed() {
			this.$bus.off('updatemystatusday', this.getServerData);	
		},
		methods: {
			getServerData(){
				let me = this;
				this.$store.dispatch('user/getUserDayStatusData', {
					cb: function(rData) {
						me.categories = rData.categories;
						me.allDataList = rData.dataList;
						me.initEcharts();
					}
				});
			},
			initEcharts() {
				var canvasDom = document.getElementById(this.canvasId);
				if(canvasDom == null) { return; }
				// 基于准备好的dom，初始化echarts实例
				if(this.lineChart == null) {
					this.lineChart = echarts.init(canvasDom);
				}
				let me = this;
				this.lineChart.setOption({
					xAxis: {
						type: 'category',
						data: me.categories,
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								dashOffset: 2
							}
						}
					},
					yAxis: {
						type: 'value',
						max: 100,
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								dashOffset: 2
							}
						},
						// axisLabel: {
						// 	show: false
						// },
					},
					grid: {
						top: 10,
						left: 30,
						width: 300,
						height: 200,
					},
					dataZoom: [{
						type: 'inside',
						start: 0,
						end: 7,
						zoomLock: true,
					}],
					series: [
						{
							type: 'line',
							symbol: "emptyCircle",
							symbolSize: 6,
							smooth: true,
							lineStyle: {
								color: me.colorArr[me.curTab],
							},
							itemStyle: {
								normal: {
									color: me.colorArr[me.curTab]
								}
							},
							data: me.allDataList[me.curTab],
							markPoint: {
								data: [
									{ type: 'max', name: 'Max' },
									{ type: 'min', name: 'Min' }
								]
							},
						}
					],
				});
			},
			clickTab(curTab) {
				if(curTab == this.curTab) {
					return;
				}
				this.curTab = curTab;
				setTimeout(() => {
					this.initEcharts();	//更新表格
				}, 20);
			},
			clickPayDay(type) {
				this.$bus.$emit('showMyVipPop');
			}
		}
	}
</script>

<style>
	.my-line-data-chart {
		position: relative;
		overflow: hidden;
		height: 250px;
	}
	.my-line-data-chart .line-charts {
		width: 100% !important;
		height: 240px !important;
		margin: 0 auto;
	}
</style>