import * as consts from '../../../modules/data/base/consts'
import { UTILS } from '../../../modules/utils'
import { CONFIG } from '../../../modules/config'

/**
 * 处理首页相关逻辑数据
 */
var USER_DATA_MANAGER = {
	/**
	 * 获取用户信息
	 * @param {Object} data
	 */
	getUserBaseInfoByData(data) {
		var tempData = Object.assign({}, data);
		var birthInfo = UTILS.getBirthdayInfoByTimeStamp(data.birthday);
		var endInfo = UTILS.getBirthdayInfoByTimeStamp(data.typetime);
		var endTimeArr = endInfo.date.split(' ');
		var resultData = Object.assign({
			birthdayStr: birthInfo.date,
			vipEndTimeStr: endTimeArr[0],
			constellation: birthInfo.constellation,
			firstL: UTILS.getFirstLetter(tempData.f_name),
		}, tempData);
		console.log('getUserBaseInfoByData-------', birthInfo, endTimeArr, resultData)
		return resultData;
	},
	/**
	 * 获取用户先天天赋
	 * @param {Object} data
	 */
	getUserBaseTalentInfoByData(data) {
		var indicator = [], dataList = [], categories = [], series = [], sortData = [];
			series[0] = { name: '先天天赋', color: '#007AFF', data: []};
		dataList[0] = {
			name: '先天天赋',
			label: { "show": false },
			symbol: 'none',
			lineStyle: {
				color: '#F8A35B',
			},
			itemStyle: {
				color: '#F8A35B'
			},
			areaStyle: {
				color: '#F8A35B',
				opacity: 0.4
			}
		};
		dataList[0].value = [];
		data.info.forEach(element => {
			indicator.push({
				name: element.name,
				max: 100
			});
			dataList[0].value.push(element.score)
		});
		sortData = [].concat(data.info);
		sortData = sortData.sort((a, b) => {return a.score-b.score});
		sortData.splice(2, 4);
		// console.log('getUserBaseTalentInfoByData---', indicator, dataList, sortData);
		return {
			chartData: {
				chartIndicator: indicator,
				chartDataList: dataList,
			},
			sortData: sortData
		}
	},
	/**
	 * 处理当前测算用户的首页数据
	 */
	getHomePageDataByData(data) {
		var resultData = {}, newAbility = [];
		// 处理一下ability,添加wordIndex用来控制样式:todo
		if(data.ability.length == 0) {
			newAbility = [
				{name: "思考力", score: 80, title: "思", wClass: "green-bar"},
				{name: "抗压能力", score: 60, title: "抗", wClass: "blue-bar"},
				{name: "目标意识", score: 70, title: "标", wClass: "red-bar"},
			]
		}else {
			data.ability.forEach(element=>{
				var index = consts.CONST_CHARACTER_WORD.indexOf(element.name);
				var color = index>2 && index<=5 ? 'blue-bar' : 'green-bar';
					color = index>5 && index<=8 ? 'red-bar' : color;
				newAbility.push(Object.assign({
					wClass: color
				}, element));
			});
		}
		
		// 处理dayMsg
		var defaultDayMsg = {};
		if(data.dayMsg.length == 0) {
			defaultDayMsg = { careful: "", good: "", keyword: "" }
		}
		
		// 处理hourMsg
		var startHour = CONFIG.startHour, newHourMsg = {}; newHourMsg.info = [];
		for(var i = 0; i < 8; i++) {
			newHourMsg.info.push({
				index: i,
				beg: data.hourMsg.info[i] ? data.hourMsg.info[i].beg : startHour,
				keyword: data.hourMsg.info[i] && data.hourMsg.info[i].keyword ? [data.hourMsg.info[i].keyword.slice(0, 4), data.hourMsg.info[i].keyword.slice(4, 8)] : ['情绪高昂', '注意出行'],
				end: data.hourMsg.info[i] ? data.hourMsg.info[i].end : startHour+2,
				good: data.hourMsg.info[i] ? data.hourMsg.info[i].good : '',
				careful: data.hourMsg.info[i] ? data.hourMsg.info[i].careful : '',
				suggest: data.hourMsg.info[i] ? data.hourMsg.info[i].suggest : '',
				locked: data.hourMsg.info[i] ? false : true
			});
			startHour = startHour+2;
		}
		newHourMsg.needpay = data.hourMsg.needpay;
		
		var tempData = Object.assign({}, data);
		resultData = Object.assign(tempData, {
			ability: newAbility,
			dayMsg: data.dayMsg.length == 0 ? defaultDayMsg : data.dayMsg,
			hourMsg: newHourMsg,
			locked: data.dayMsg.length == 0 && data.ability.length == 0 && data.needpay == 1 ? true : false
		});
		// console.error('getHomePageDataByData--------', resultData)
		return resultData;
	},
	/**
	 * 处理当前测算用户的性格数据
	 * @param {Object} data
	 */
	getCharacterDataByData(data) {
		var resultList = [];
		data.forEach(dText => {
			if(dText.indexOf('外在性格') != -1) {
				resultList[0] = {
					title: '外在性格',
					content: dText.slice(5)
				}
			}else if(dText.indexOf('内在性格') != -1) {
				resultList[1] = {
					title: '内在性格',
					content: dText.slice(5)
				}
			}else {
				resultList[2] = !resultList[2] ? [] : resultList[2];
				resultList[2].push(dText);
			}
		});
		console.error('getCharacterDataByData-------', resultList)
		return resultList;
	},
	/**
	 * 处理当前测算用户的天赋雷达图数据
	 */
	getTalentDataByData(data) {
		var chartList = [].concat(data.chart), firstData = chartList[0].info;
		var indicator = [], dataList = [], legend = [], colorArr = ['#CBD1DD', '#F8A35B', '#007AFF'];
		for(var i = 0; i < firstData.length; i++) {
			indicator.push({
				name: firstData[i].name,
				max: 100
			});
		}
		for(var j = 0; j < chartList.length; j++) {
			dataList[j] = {
				name: chartList[j].title == '先天' ? chartList[j].title+'能力' : '后天能力 '+chartList[j].title+'年',
				value: [],
				label: { "show":false },
				symbol: j==0 ? "none" : "circle",
				symbolSize: j==0 ? 0 : 8,
				lineStyle: {
					color: colorArr[j]
				},
				itemStyle: {
					color: colorArr[j]
				},
				areaStyle: {
					color: colorArr[j],
					opacity: 0.4
				}
			}
			legend[j] = dataList[j].name;
			var cData = chartList[j].info;
			for(var k = 0; k < cData.length; k++) {
				dataList[j].value.push(cData[k].score);
			}
		}
		// console.error('getTalentDataByData------', indicator, dataList, legend)
		return {
			chartIndicator: indicator,
			chartDataList: dataList,
			chartLegend: legend 
		}
	},
	/**
	 * 处理当前测算用户的日状态数据
	 */
	getStatusDayDataByData(data) {
		var tempArr = [], date = {}, categories = [], dataList = [];
		for(var keys in data) {
			var index = consts.CONST_CHARACTER_FIVE_WORD.indexOf(keys);
			tempArr[index] = data[keys];
		};
		var tempData = tempArr[0];
		for(var kk in tempData) {
			var kkText = UTILS.getTimeNumByString(kk.slice(4, 6))+'.'+UTILS.getTimeNumByString(kk.slice(6, 8));
			categories.push(kkText);
		}
		var tempL = categories.length;
		date = {start: categories[0].replace('.', '月')+'日', end: categories[tempL-1].replace('.', '月')+'日'};
		for(var j = 0; j < tempArr.length; j++) {
			var valueArr = [];
			for(var kkk in tempArr[j]){
				valueArr.push(tempArr[j][kkk]);
			}
			dataList[j] = valueArr;
		}
		// console.log('getStatusDayDataByData----', date, categories, dataList)
		return {
			date: date,
			chartData: {
				categories: categories,
				dataList: dataList
			}
		}
	},
	/**
	 * 获取当前月状态的数据
	 * @param {Object} data
	 */
	getStatusMonthDataByData(data) { 
		console.log('getStatusMonthDataByData-----', data);
		var tempArr = [], date = {}, dataList = [], len = consts.CONST_CHARACTER_FIVE_WORD.length;
		for(var keys in data) {
			var index = consts.CONST_CHARACTER_FIVE_WORD.indexOf(keys);
			tempArr[index] = data[keys];
		};
		let keyArr = [];
		var tempData = tempArr[0];
		for(var kk in tempData) {
			var kkText = UTILS.getTimeNumByString(kk.slice(4, 6))+'月';
			keyArr.push(kkText);
		}
		let kLen = keyArr.length;
		date = {start: keyArr[0], end: keyArr[kLen-1]};

		dataList[0] = ['product'].concat(consts.CONST_CHARACTER_FIVE_WORD_CH);
		keyArr.forEach((el, i) => {
			dataList[i+1] = [el];
		});
		let valueArr = tempArr.map(el => { return Object.values(el) });
		valueArr.forEach((el, i) => el.splice(0, 0, consts.CONST_CHARACTER_FIVE_WORD_CH[i]));
		for(let i = 1; i < dataList.length; i++) {
			for(let j = 0; j < len; j++) {
				// console.log(i, j, dataList[i], valueArr[j][i])
				dataList[i].push(valueArr[j][i]);
			}
		}
		console.log('getStatusMonthDataByData---', tempArr, keyArr, dataList, date)
		return {
			date: date,
			chartData: {
				dataList: dataList
			}
		}
	},
	/**
	 * 获取我的列表数据
	 * @param {Object} data
	 */
	getMyUserListDataByData(data) {
		var len = data.length, resultList = [];
		for(var i = 0; i < len; i++) {
			var newData = Object.assign({
				firstL: UTILS.getFirstLetter(data[i].f_name),
			}, data[i]);
			resultList.push(newData);
		}
		console.log('getMyUserListDataByData----', resultList)
		return resultList;
	},
	/**
	 * 获取我的用户列表数据
	 * @param {Object} data
	 */
	getMyUserDataByData(data) {
		var resultData = Object.assign({
			f_type: 0,
			firstL: UTILS.getFirstLetter(data.f_name),
		}, data);
		return resultData;
	}
}

export {
    USER_DATA_MANAGER
}