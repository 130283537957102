import * as types from '../mutation-types'

export default {
    namespaced: true,
    state: {
		curTab: 1,
		calendarTab: 1,
		scheduleTab: 1,
		scheduleType: 'read', //read查看 edit新增和编辑
		planModel: 1,
		showHoursInfoList: false
    },
    mutations: {},
    actions: {
    }
}