<template>
	<div class="my-status-month-panel">
		<div class="my-status-panel-title">{{(userBaseInfo.f_type==0?defaultTitle:(userStatusMonthDate.start+'-'+userStatusMonthDate.end))+'能量状态走势'}}</div>
		<!-- tab不用切换 -->
		<!-- <div class="my-status-menu dsp-flex">
			<div class="my-status-item"><span class="text1 cur">{{tabArr[0]}}</span></div>
			<div class="my-status-item"><span class="text2 cur">{{tabArr[1]}}</span></div>
			<div class="my-status-item"><span class="text3 cur">{{tabArr[2]}}</span></div>
			<div class="my-status-item"><span class="text4 cur">{{tabArr[3]}}</span></div>
			<div class="my-status-item"><span class="text5 cur">{{tabArr[4]}}</span></div>
		</div> -->
		<!-- 表格部分 -->
		<div v-if="userBaseInfo.f_type != 0" class="my-bar-data-chart">
			<div class="bar-charts" v-bind:id="canvasId"></div>
		</div>
		<!-- 模糊背景部分 -->
		<div v-if="userBaseInfo.f_type == 0" class="my-area-data-bg-box">
			<img class="my-area-data-bg" :src="dataBg" mode="" />
			<div class="my-area-data-bg-text">{{'马上解锁，可以查看未来你在思考力、灵感、执行力、沟通力、对策方面能量的起伏变化。'}}</div>
		</div>
		<!-- 按钮部分 -->
		<div v-if="userBaseInfo.f_type == 0" class="my-status-btn-box">
			<button class="my-status-btn btn-long red" @click="clickPayMonth(3)">查看未来3个月</button>
		</div>
		<div v-if="userBaseInfo.f_type == 1" class="my-status-btn-box">
			<button class="my-status-btn btn-short blue" @click="clickPayMonth(3)">查看未来3个月</button>
			<button class="my-status-btn btn-short red" @click="clickPayMonth(12)">查看未来12个月</button>
		</div>
		<div v-if="userBaseInfo.f_type == 2" class="my-status-btn-box">
			<button class="my-status-btn btn-long red" @click="clickPayMonth(12)">查看未来12个月</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MyStatusMonthPanel",
		computed: {
			hideAllCharts() {
				return this.$store.state.hideAllCharts;
			},
			userBaseInfo() {
				return this.$store.state.user.userBaseInfo;
			},
			userAuthority() {
				return this.$store.state.user.authority;
			},
			userStatusMonthDate() {
				return this.$store.state.user.userStatusMonthDate;
			},
			userStatusMonthCategories() {
				return this.$store.state.user.userStatusMonthCategories;
			},
			userStatusMonthSeries() {
				return this.$store.state.user.userStatusMonthSeries;
			}
		},
		data() {
			return {
				tabArr: ['思考力', '灵感', '执行', '沟通', '对策'],
				colorArr: ['#007AFF', '#FE5E8F', '#07C160', '#F8AA2A', '#32C5FF'],
				defaultTitle: '按月',
				dataBg: '../../assets/images/month-bg.png',
				canvasId: 'statusMonthChart',
				allDataList: [],
				barChart: null,
			}
		},
		mounted() {
			setTimeout(function() {
				this.getServerData();
			}.bind(this), 20);
			this.$bus.on('updatemystatusmonth', this.getServerData);
		},
		destroyed() {
			this.$bus.off('updatemystatusmonth', this.getServerData);
		},
		methods: {
			getServerData(){
				let me = this;
				this.$store.dispatch('user/getUserMonthStatusData', {
					cb: function(rData) {
						me.allDataList = [].concat(rData.chartData.dataList);
						me.initEcharts();
					}
				});
			},
			initEcharts() {
				var canvasDom = document.getElementById(this.canvasId);
				if(canvasDom == null) { return; }
				// 基于准备好的dom，初始化echarts实例
				if(this.barChart == null) {
					this.barChart = echarts.init(canvasDom);
				}
				console.error('initEcharts----', this.allDataList);
				let me = this;
				this.barChart.setOption({
					color: ['#007AFF', '#FE5E8F', '#07C160', '#F8AA2A', '#32C5FF'],
					legend: {
						textStyle: {
							color: '#fff',
							fontSize: 14,
							backgroundColor: [],
							lineHeight: 22,
							borderRadius: [10, 10, 10, 10],
							padding: [0, 12, 0, 12]
						},
						align: "left",
						itemWidth: 0,
						itemGap: 2,
					},
					dataset: {
						source: me.allDataList
					},
					xAxis: { 
						type: 'category',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								dashOffset: 2
							}
						}
					},
					yAxis: {
						type: 'value',
						max: 100,
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								dashOffset: 2
							}
						},
					},
					grid: {
						top: 50,
						left: 30,
						width: 300,
						height: 180,
					},
					dataZoom: [{
						type: 'inside',
						start: 0,
						end: 30,
						zoomLock: true,
					}],
					// Declare several bar series, each will be mapped
					// to a column of dataset.source by default.
					series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
				});
			},
			clickPayMonth(type) {
				this.$bus.$emit('showMyVipPop');
			}
		}
	}
</script>

<style>
	.my-bar-data-chart {
		position: relative;
		overflow: hidden;
		height: 270px;
		padding-top: 10px;
	}
	.my-bar-data-chart .bar-charts {
		width: 100% !important;
		height: 250px !important;
		margin: 0 auto;
	}
</style>

