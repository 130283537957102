<template>
	<div class="chart-radar-charts">
        <div class="radar-charts" v-bind:id="canvasId"></div>
	</div>
</template>

<script>
	export default {
		name: "RadarCharts",
		props: {
			canvasId: {
				type: String,
				default: ''
			},
			chartData: {
				type: Object,
				default() {
					return {}
				}
			},
			showLegend: {
				type: Boolean,
				default: true
			}
		},
		mounted() {
			setTimeout(() => {
				this.initEcharts();
			}, 100);
		},
		methods: {
			initEcharts() {
				var me = this;
				var canvasDom = document.getElementById(this.canvasId);
				if(canvasDom == null) { return; }
				// 基于准备好的dom，初始化echarts实例
				var myChart = echarts.init(canvasDom);
				myChart.setOption({
					radar: {
						splitNumber: 5,
						radius: '60%',
						startAngle: 90,
						indicator: me.chartData.chartIndicator,
						axisName: {
							color: '#E5E7EC',
							fontSize: 12,
							padding: [-7, -5],
						},
						axisLine: {
							lineStyle: {
								color: ['#E5E7EC']
							}
						},
						splitLine: {
							lineStyle: {
								color: ['#E5E7EC']
							}
						},
						splitArea: {
							show: false,
							areaStyle: {
								color: ["rgba(255,255,255,0.2)"]
							}
						},
					},
					series: [{
						type: 'radar',
						data: me.chartData.chartDataList
					}],
					legend: [
						{
							show: me.showLegend,
							bottom: 0,
							inactiveColor: "#ccc",
							textStyle: {
								color: "#E5E7EC"
							},
							itemWidth: 10,
							itemHeight: 10,
							data: me.showLegend && me.chartData.chartLegend ? me.chartData.chartLegend : [],
						}
					]
				});
			}
		}
	}
</script>

<style>
	.chart-radar-charts {
		position: relative;
		overflow: hidden;
	}
	.chart-radar-charts .radar-charts {
		width: 100% !important;
		height: 270px !important;
		margin: 0 auto;
	}
</style>
