<template>
	<div class="page-second my-status-page" :style="'height: '+height+'px'">
		<!-- 二级顶部导航栏 -->
		<second-top-bar :title="'按日状态'" :backFunc="clickMyBack"></second-top-bar>
		<!-- 内容部分 -->
		<div class="pad-t-18">
			<div class="my-data-box w-94-p white-box">
				<div class="my-data-content">
					<div class="my-data-title" v-if="userStatusDayDate"><span>{{userStatusDayDate.start}}-{{userStatusDayDate.end}}能量状态走势</span></div>
					<!-- tab -->
					<div class="my-status-menu dsp-flex">
						<div class="my-status-item" @click="clickTab(1)"><span class="text1" :class="{'cur': curTab == 1}">思考力</span></div>
						<div class="my-status-item" @click="clickTab(2)"><span class="text2" :class="{'cur': curTab == 2}">灵感</span></div>
						<div class="my-status-item" @click="clickTab(3)"><span class="text3" :class="{'cur': curTab == 3}">执行</span></div>
						<div class="my-status-item" @click="clickTab(4)"><span class="text4" :class="{'cur': curTab == 4}">沟通</span></div>
						<div class="my-status-item" @click="clickTab(5)"><span class="text5" :class="{'cur': curTab == 5}">对策</span></div>
					</div>
					<div class="my-data-chart my-status-chart">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SecondTopBar from '../../../components/common/SecondTopBar.vue'
	export default {
		name: "MyStatusDayPage",
		components: { SecondTopBar },
		computed: {
			userStatusDayDate() {
				return this.$store.state.user.userStatusDayDate;
			},
			userStatusDayCategories() {
				return this.$store.state.user.userStatusDayCategories;
			},
			userStatusDaySeries() {
				return this.$store.state.user.userStatusDaySeries;
			}
		},
		data() {
			return {
				curTab: 1,
				statusSeries: [{
					data: [100, 100, 95, 150, 112, 132, 120],
					color: '#F8AA2A'
				}],
				height: 640
			}
		},
		mounted() {
			setTimeout(function() {
				this.height = this.$store.state.systemInfo.screenHeight;
			}.bind(this), 10);
		},
		methods: {
			clickMyBack() {
				this.$router.replace('/my');
			},
			clickTab(curTab) {
				this.curTab = curTab;
			}
		}
	}
</script>

<style>

</style>
