<template>
	<div class="calendar-hours-info-list">
		<div class="calendar-hours-info-item dsp-flex" :class="{'locked': hData.locked}" v-for="hData in userCurrentData.hourMsg.info" :key="hData.beg">
			<div class="calendar-hours-ii-time" :class="{'time-passed': !hData.locked}">
				{{hData.beg+'-'+hData.end+'点'}}
				<!-- <span class="time-dot-bg"></span> -->
				<span class="time-dot"></span>
			</div>
			<div class="calendar-hours-ii-detail">
				<div class="title">{{hData.keyword}}</div>
				<div class="note">{{'利：'+hData.good}}</div>
				<div class="note">{{'弊：'+hData.careful}}</div>
				<div class="note">{{'荐：'+hData.suggest}}</div>
			</div>
		</div>
		<!-- <div class="calendar-hours-info-item dsp-flex">
			time-passed
			<div class="calendar-hours-ii-time time-current">7-9点<span class="time-dot-bg"></span><span class="time-dot"></span></div>
			<div class="calendar-hours-ii-detail">
				<div class="title">思路清奇少许矛盾</div>
				<div class="note">思路另类做创意设计2</div>
				<div class="note">思路另类做创意设计211</div>
				<div class="note">思路另类做创意设计22</div>
				<div class="note">思路另类做创意设计222</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: "CalendarHoursInfoList",
		computed: {
			userCurrentData() {
				return this.$store.state.user.userCurrentData;
			}
		},
		methods: {
			
		}
	}
</script>

<style>
</style>
