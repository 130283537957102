import wx from 'weixin-js-sdk'
import { UA } from '../utils'
import { WEIXIN_BASE } from './base'
import { WEIXIN_PAY } from './pay'

//需在用户可能点击分享按钮前就先调用
var WEIXIN = {
    /**
     * 微信分享配置
     */
    initConfigShare(sucFunc, failFunc, updateShareTime) {
        var shareTimes = AppFate.$store.state.shareTimes;
            shareTimes = updateShareTime ? shareTimes+1 : 0;
            AppFate.$store.state.shareTimes = shareTimes;

        WEIXIN_BASE.getAuthorationAndConfigJsSDK(function(){
            
            // 处理分享参数
            var WxShareData = {
                title: '人生天气日历',
                desc: '人生天气日历:像观察天气一样观察人的能量状态',
                linkurl: 'https://' + location.hostname,
                img: 'https://' + location.hostname + '/logo.png',
            }

            console.log("微信分享配置成功----", WxShareData, shareTimes, updateShareTime);

            // 页面加载完成后用户就有可能调用微信的分享，所以当页面ready 完后就加载
            wx.ready(function () {
                var commonShareData = {
                    // 分享标题
                    title: WxShareData.title,
                    // 分享描述
                    desc: WxShareData.desc,
                    // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    link: WxShareData.linkurl,
                    // 分享图标
                    imgUrl: WxShareData.img,
                    success: function () {
                        console.log('分享回调函数');
                        if(sucFunc && typeof(sucFunc) == 'function'){
                            sucFunc();
                        }
                    },
                }
                wx.onMenuShareTimeline(commonShareData);//1.0
                wx.updateTimelineShareData(commonShareData);//1.0
                wx.onMenuShareAppMessage(commonShareData)//1.4
                wx.updateAppMessageShareData(commonShareData)//1.4
            });

            // 微信预加载失败回调
            wx.error(function (res) {
                console.error(res);
                if(!UA.isPcWeChat()) {
                    // window.location.href = window.location.origin+'/record';
                    window.location.href = window.location.origin; //授权失败回调跳转回首页
                }
                if(shareTimes < 5) {
                    setTimeout(() => {
                        WEIXIN.initConfigShare(null, null, true);
                    }, 1000);
                }else {
                    fundebug.notify('weixin', 'weixinSdk', {metaData: res});
                }
                if(failFunc && typeof(failFunc) == 'function'){
                    failFunc();
                }
            });
        }, updateShareTime);
    },

    /**
     * 授权获取微信位置
     */
    initGetLocation(sucFunc, failFunc) {
        WEIXIN_BASE.getAuthorationAndConfigJsSDK(function(){
            wx.ready(function () {
                wx.getLocation({
                    type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    success: function (res) {
                        console.log('获取位置信息成功----', res);
                        if(sucFunc && typeof(sucFunc) == 'function') {
                            sucFunc(res);
                        }
                        // var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                        // var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                        // var speed = res.speed; // 速度，以米/每秒计
                        // var accuracy = res.accuracy; // 位置精度
                    },
                    fail: function (res) {
                        console.log('获取位置信息失败::::::::', res);
                        if(failFunc && typeof(failFunc) == 'function') {
                            failFunc();
                        }
                    },
                    cancel: function (res) {
                        console.log('获取位置信息取消::::::::', res);
                        if(failFunc && typeof(failFunc) == 'function') {
                            failFunc();
                        }
                    }
                  });
            });
        });
    }
}

export {
    WEIXIN, WEIXIN_PAY
}