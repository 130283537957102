import * as types from '../mutation-types'
import { CALENDAR } from '../../../modules/calendar/calendar'

export default {
    namespaced: true,
    state: {
		todayDateInfo: {},
		tomorrowDateInfo: {},
		currentDateInfo: {}
    },
    mutations: {},
    actions: {
		initTodayTomorrowDateInfo({state}) {
			var today = new Date(), 
				tdYear = today.getFullYear(), 
				tdMonth = today.getMonth(), 
				tdDay = today.getDate(), h = today.getHours(), mi = today.getMinutes(), s = today.getSeconds(),
				tdData = CALENDAR.solar2lunar(tdYear, tdMonth+1, tdDay, h, mi, s);
			var tomorrow = new Date(tdYear, tdMonth, tdDay+1), 
				tmYear = tomorrow.getFullYear(), 
				tmMonth = tomorrow.getMonth(), 
				tmDay = tomorrow.getDate(),
				tmData = CALENDAR.solar2lunar(tmYear, tmMonth+1, tmDay, h, mi, s);
			state.todayDateInfo = tdData;
			state.currentDateInfo = tdData;
			state.tomorrowDateInfo = tmData;
		}
    }
}