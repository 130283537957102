<template>
	<div class="page-one page-my">
		<top-bar v-bind:hideAvatar="true" :className="userBaseInfo.f_type!=0?'my-vip-top-bar':'my-top-bar'"></top-bar>
		<div class="my-page-content-scroll">
			<!-- 信息框 -->
			<div class="my-info-panel" :class="{'my-info-panel-vip': userBaseInfo.f_type!=0}">
				<!-- 基础信息 -->
				<div class="my-base-info">
					<div class="my-info-avatar">
						<my-avatar :className="'my-my-avatar'" @click="clickMyAvatar"></my-avatar>
						<div v-if="userBaseInfo.f_type!=0" class="my-avatar-vip"></div>
						<i v-if="userBaseInfo.f_type!=0" class="iconbg icon-vip"></i>
					</div>
					<div class="my-info-text">
						<span class="my-gender"><i class="iconbg" :class="{'icon-male cur': userBaseInfo.gender==0, 'icon-female cur': userBaseInfo.gender==1}"></i></span>
						<span class="my-nickname"><span>{{userBaseInfo.f_name.slice(0, 8)}}</span></span>
						<span class="my-edit" @click="clickEdit"><i class="iconfont icon-edit"></i></span>
					</div>
					<div class="my-birthday" :class="{'my-birthday-vip':userBaseInfo.f_type!=0}">
						<span class="my-birth">{{userBaseInfo.birthdayStr}}</span>
						<span class="my-constellation"><i class="iconfont " :class="'icon-'+userBaseInfo.constellation.cClass"></i>{{userBaseInfo.constellation.cText}}</span>
					</div>
				</div>
				<div class="my-base-list-box dsp-flex" :class="{'my-base-list-scroll-box': myUserList.length > 9}">
					<div class="my-list-add-l" v-if="myUserList.length == 0" @click="clickAdd">
						<i class="iconbg" :class="{'icon-add':userBaseInfo.f_type==0, 'icon-add-vip':userBaseInfo.f_type!=0}"></i>
						<span class="my-list-add-text">新增先知主人</span>
					</div>
					<div class="my-list-add" v-else-if="myUserList.length >= 1" @click="clickAdd">
						<i class="iconbg" :class="{'icon-add':userBaseInfo.f_type==0, 'icon-add-vip':userBaseInfo.f_type!=0}"></i>
					</div>
					<!-- 列表:数目大于9个就滚动，不然就居中显示不滚动 -->
					<div class="my-base-list dsp-flex" v-if="myUserList.length >= 1 && myUserList.length <= 9">
						<div class="my-base-list-item" @click="clickUserItem(userData)" v-for="userData in myUserList" :key="userData.id">
							<img v-if="userData.isdefault == 1" class="avatar" :src="(userData.gender==0 ? 'https://m1.yxdxt.com/d-m.png' : 'https://m1.yxdxt.com/d-f.png')" mode="" />
							<div v-else class="text" :class="{'text-vip': userData.f_type != 0}">{{userData.firstL}}</div>
							<i v-if="userData.f_type != 0" class="iconbg icon-vip"></i>
							<div class="current" v-if="userBaseInfo.id==userData.id">当前<span class="arrow"></span></div>
						</div>
					</div>
					<div class="my-base-list-scroll" v-else-if="myUserList.length > 9">
						<div class="my-base-list dsp-flex">
							<div class="my-base-list-item" @click="clickUserItem(userData)" v-for="userData in myUserList" :key="userData.id">
								<img v-if="userData.isdefault == 1" class="avatar" :src="(userData.gender==0 ? 'https://m1.yxdxt.com/d-m.png' : 'https://m1.yxdxt.com/d-f.png')" mode="" />
								<div v-else class="text" :class="{'text-vip': userData.f_type != 0}">{{userData.firstL}}</div>
								<i v-if="userData.f_type != 0" class="iconbg icon-vip"></i>
								<div class="current" v-if="userBaseInfo.id==userData.id">当前<span class="arrow"></span></div>
							</div>
						</div>
					</div>
					
				</div>
				<!-- <div class="my-vip-info-button" :class="{'my-vip-info-button-box':userBaseInfo.f_type!=0}" @click="clickVip">
					<div class="my-vip-ib-text" v-if="userBaseInfo.f_type!=0 && userBaseInfo.vipEndTimeStr!=''">
						<div>会员有效期至：</div>
						<div>{{userBaseInfo.vipEndTimeStr}}</div>
					</div>
				</div> -->
			</div>
			<!-- 列表框 -->
			<div class="my-main-content">
				<!-- 菜单按钮 -->
				<div class="my-tab-list dsp-flex">
					<div class="my-tab-item item1" @click="clickTab(1)">
						<div class="my-tab-item-line line-c"></div>
						<div class="my-tab-item-content">
							<div class="title">Character</div>
							<div class="content">
								<i class="iconbg icon-character"></i><span class="content-text">性格特点</span><span></span>
							</div>
						</div>
					</div>
					<div class="my-tab-item" @click="clickTab(2)">
						<div class="my-tab-item-line line-t"></div>
						<div class="my-tab-item-content">
							<div class="title">Talent</div>
							<div class="content">
								<i class="iconbg icon-talent"></i><span class="content-text">天赋矩阵</span><span></span>
							</div>
						</div>
					</div>
				</div>
				<div class="my-tab-long-list">
					<div class="my-tab-long-item">
						<my-status-day-panel></my-status-day-panel>
					</div>
					<div class="my-tab-long-item">
						<my-status-month-panel></my-status-month-panel>
					</div>
				</div>
			</div>
		</div>
		<router-view></router-view>
		<!-- 编辑弹框 -->
		<van-popup v-bind:value="showEditPop" class="van-popup-my-edit-popup" type="center">
			<div class="my-edit-popup">
				<div class="my-edit-pp-title">编辑昵称</div>
				<div class="my-edit-pp-input">
					<input class="my-edit-input" type="text" v-model="userNickName" maxlength="12">
				</div>
				<div class="my-edit-pp-btn dsp-flex">
					<div class="my-edit-button-box">
						<button type="default" class="my-edit-button cancel" @click="clickCancel">取消</button>
					</div>
					<div class="my-edit-button-box">
						<button type="default" class="my-edit-button sure" @click="clickSure">确定</button>
					</div>
				</div>
			</div>
		</van-popup>
		<!-- vip弹框 -->
		<my-vip-pop v-if="showPop"></my-vip-pop>
		<!-- 切换先知主人弹框 -->
		<my-userlist-pop v-if="showPop"></my-userlist-pop>
	</div>
</template>

<script>
	import TopBar from '../../components/common/TopBar.vue'
	import MyAvatar from "../../components/my/MyAvatar.vue"
	import MyStatusDayPanel from "../../components/my/MyStatusDayPanel.vue"
	import MyStatusMonthPanel from "../../components/my/MyStatusMonthPanel.vue"
	export default {
		name: "My",
		components: { TopBar, MyAvatar, MyStatusDayPanel, MyStatusMonthPanel },
		computed: {
			userInfo() {
				return this.$store.state.userInfo;	
			},
			userBaseInfo() {
				return this.$store.state.user.userBaseInfo;
			},
			userNickName: {
				get() {
					return this.$store.state.user.userNickName;
				},
				set(value) {
					this.$store.state.user.userNickName = value;
				}
			},
			myUserList() {
				return this.$store.state.myUserList;
			}
		},
		data() {
			return {
				title: '',
				showPop: false,
				showEditPop: false,
			}
		},
		onShow() {
			this.showPop = true;
		},
		onHide() {
			this.showPop = false;
		},
		methods: {
			clickMyAvatar() {
				this.$bus.emit('showUserListPop', 'myavatar');
			},
			clickEdit() {
				this.showEditPop = true;
			},
			clickAdd() {
				this.$store.dispatch('user/resetUserInputData');
				this.$router.replace('/start');
			},
			clickUserItem(userData) {
				// 是当前数据就返回了
				if(userData.id == this.userBaseInfo.id) {
					return;
				}
				let me = this;
				this.$store.dispatch('user/getUserHomePageData', {
					id: userData.id,
					cb: function() {
						me.$bus.emit('updatemystatusmonth');
						me.$bus.emit('updatemystatusday');
					}
				});
				
			},
			clickVip() {
				this.$router.replace('/my/vip');
			},
			clickTab(index) {
				var pagePath = '';
				switch(index) {
					case 1:
						pagePath = '/my/character';
						this.$store.dispatch('user/getUserCharacterData');
						break;
					case 2:
						pagePath = '/my/talent';
						this.$store.dispatch('user/getUserTalentData');
						break;
					default:
						break;
				}
				if(pagePath != '') {
					this.$router.replace(pagePath);
				}
			},
			clickCancel() {
				this.showEditPop = false;
			},
			clickSure() {
				let nickname = this.userNickName;
				if(nickname == '') {
					this.$toast('请输入昵称哟~');
					return;
				}
				this.$store.dispatch('user/updateUserNickname', {
					nickname: nickname,
					cb: function() {
						this.$toast('修改昵称成功~');
						this.showEditPop = false;
					}.bind(this)
				});
			}
		}
	}
</script>
