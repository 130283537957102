import Vue from 'vue'
import VueRouter from 'vue-router'
// 配置router路由

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
}
Vue.use(VueRouter);
// 配置router路由
import Home from '../../pages/start/Home.vue'
import Start from '../../pages/start/Start.vue'
import Generate from '../../pages/start/Generate.vue'
import Calendar from '../../pages/calendar/Calendar.vue'
import Schedule from '../../pages/schedule/Schedule.vue'
import My from '../../pages/my/My.vue'
import MyCharacterPage from '../../pages/my/sub/MyCharacterPage.vue'
import MyStatusDayPage from '../../pages/my/sub/MyStatusDayPage.vue'
import MyStatusMonthPage from '../../pages/my/sub/MyStatusMonthPage.vue'
import MyTalentPage from '../../pages/my/sub/MyTalentPage.vue'
import MyVipPage from '../../pages/my/sub/MyVipPage.vue'
import { WEIXIN } from '../weixin'

/**
 * 定义路由
 */
export default new VueRouter({
    base: '/',
    mode: 'history',
    routes: [
        {
            path: '/home',
            component: Home,
            meta: {
                index: 0,
                showFooter: false
            },
            beforeEnter: (to, from, next) => {
                next();
                setTimeout(() => {
                    WEIXIN.initConfigShare();
                }, 100);
            }
        },
        {
            path: '/start',
            component: Start,
            meta: {
                index: 0,
                showFooter: false
            },
            beforeEnter: (to, from, next) => {
                next();
                setTimeout(() => {
                    WEIXIN.initConfigShare();
                }, 100);
            }
        },
        {
            path: '/generate',
            component: Generate,
            meta: {
                index: 0,
                showFooter: false
            },
            beforeEnter: (to, from, next) => {
                next();
                setTimeout(() => {
                    WEIXIN.initConfigShare();
                }, 100);
            }
        },
        {
            path: '/calendar',
            component: Calendar,
            meta: {
                index: 0,
                showFooter: true
            },
            beforeEnter: (to, from, next) => {
                next();
                setTimeout(() => {
                    WEIXIN.initConfigShare();
                }, 100);
            }
        },
        {
            path: '/schedule',
            component: Schedule,
            meta: {
                index: 0,
                showFooter: true
            },
            beforeEnter: (to, from, next) => {
                next();
                setTimeout(() => {
                    WEIXIN.initConfigShare();
                }, 100);
            }
        },
        {
            path: '/my',
            component: My,
            meta: {
                index: 0,
                showFooter: true,
            },
            beforeEnter: (to, from, next) => {
                next();
                setTimeout(() => {
                    WEIXIN.initConfigShare();
                }, 100);
            },
            children: [
                {
                    path: 'character',
                    component: MyCharacterPage,
                },
                {
                    path: 'statusday',
                    component: MyStatusDayPage
                },
                {
                    path: 'statusmonth',
                    component: MyStatusMonthPage
                },
                {
                    path: 'talent',
                    component: MyTalentPage
                },
                {
                    path: 'vip',
                    component: MyVipPage
                },
            ]
        },
    ]
});