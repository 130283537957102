<template>
	<div class="page-one page-start">
		<!-- 返回部分 -->
		<div class="second-topbar-back page-start-back" v-if="editType == 2" @click="clickBack">
			<i class="iconfont icon-arrow-l"></i>
		</div>
		<!-- 选中的星座:要添加动画 -->
		<div class="start-birthday-panel-box">
			<constellation-panel></constellation-panel>
		</div>
		<div class="start-birthday-panel-bottom">
			<!-- 中间部分 -->
			<div class="start-birthday-main-panel">
				<!-- 选择时间 -->
				<div class="start-title dsp-flex">
					<span class="start-title-text"><span class="circle"></span><span>您的出生时间？</span></span>
					<div class="start-birthday-toggle">
						<div class="start-birthday-toggle-current" :class="'current-'+tempCalendarValue"></div>
						<div class="start-birthday-toggle-text solar" @click="toggleCalendar(0)">公历</div>
						<div class="start-birthday-toggle-text lunar" @click="toggleCalendar(1)">农历</div>
					</div>
				</div>
				<birthday-panel></birthday-panel>
				<!-- 输入昵称 -->
				<div class="start-title start-title-2 dsp-flex" v-if="editType == 2">
					<div class="start-title-text"><span class="circle"></span><span>昵称：</span></div>
					<div class="start-title-sex-box pos-r">
						<input class="start-name-input" type="text" v-model="inputData.nickname" maxlength="12" />
					</div>
				</div>
				<!-- 选择性别 -->
				<div class="start-title start-title-2 dsp-flex">
					<div class="start-title-text"><span class="circle"></span><span>性别：</span></div>
					<div class="start-title-sex-box pos-r">
						<div class="start-title-sex">
							<div class="sex-icon sex-icon-male" :class="{'cur': inputData.sex==0}" @click="chooseSex(0)">
								<i class="iconfont icon-male2" :class="{'cur': inputData.sex==0}"></i>男
							</div>
							<div class="sex-icon sex-icon-female" :class="{'cur': inputData.sex==1}" @click="chooseSex(1)">
								<i class="iconfont icon-female2" :class="{'cur': inputData.sex==1}"></i>女
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 继续按钮 -->
			<div class="start-button-box">
				<button type="default" class="start-button" @click="clickStart">{{editType == 2 ? '继续' : '开启我的先知'}}</button>
			</div>
		</div>
	</div>
</template>

<script>
	import BirthdayPanel from '../../components/common/BirthdayPanel.vue'
	import ConstellationPanel from '../../components/common/ConstellationPanel.vue'
	export default {
		name: "Start",
		components: { BirthdayPanel, ConstellationPanel },
		computed: {
			userData() {
				return this.$store.state.userData;
			},
			userInfo() {
				return this.$store.state.userInfo;
			},
			editType() {
				return this.$store.state.user.editType;
			},
			inputData() {
				return this.$store.state.user.inputData;
			}
		},
		data() {
			return {
				tempCalendarValue: 0,
				locked: false
			}
		},
		mounted() {
			setTimeout(function() {
				this.$bus.emit('loadImageList', {
					fromPage: 'start'
				});
				this.initData();
			}.bind(this), 0)
		},
		methods: {
			initData() {
				if(this.$store.state.user.editType != 2) {
					this.$set(this.$store.state.user.inputData, 'nickname', this.userInfo.nickname);
				}
			},
			chooseSex(index) {
				this.$set(this.$store.state.user.inputData, 'sex', index);
			},
			clickBack() {
				this.$router.replace('/my');
			},
			clickStart() {
				// for test start
				// this.$store.dispatch('user/addFakeUser', {
				// 	cb: function() {
				// 		this.$router.replace('/generate');
				// 	}.bind(this)
				// });
				// return;
				// for test end

				var nickname = this.inputData.nickname, sex = this.inputData.sex;
				console.log('clickStart----', nickname, sex);
				if(nickname == '') {
					this.$toast('请输入昵称哟~');
					return;
				}
				if(sex < 0) {
					this.$toast('请选择您的性别哟~');
					return;
				}
				// this.$bus.emit('loadImageList', {
				// 	fromPage: 'ani_'+(sex == 0 ? 'man' : 'woman'),
				// 	cb: function() {
				// 		uni.navigateTo({
				// 			url: '/pages/start/generate',
				// 			animationType: 'slide-in-left'
				// 		});
				// 	},
				// });
				// uni.navigateTo({
				// 	url: '/pages/start/generate',
				// 	animationType: 'slide-in-left'
				// });
				// return;
				// 做数据检测的条件判断
				
				this.$bus.emit('checkBithCalendar', {
					cb: function() {
						if(this.locked) { return; }
						this.locked = true;
						this.$store.dispatch('user/addUser', {
							cb: function() {
								this.$router.replace('/generate');
								this.locked = false;
								// 重置获取列表数据状态，以便于刷新
								this.$store.state.gotMyUserList = false;
							}.bind(this)
						});
						setTimeout(function() {
							this.locked = false;
						}.bind(this), 5000)
					}.bind(this)
				})
			},
			toggleCalendar(value) {
				console.error('toggleCalendar-----', value)
				if(value == this.tempCalendarValue) { return; }
				if(value == 0) {
					this.$bus.emit('toggleSolarBirthCalendar');
				}else if(value == 1) {
					this.$bus.emit('toggleLunarBirthCalendar');
				}
				this.tempCalendarValue = value;
			}
		}
	}
</script>

<style>
</style>
