import * as consts from '../../modules/data/base/consts'
import { USER_DATA_MANAGER } from '../../modules/data/manager/userdatamng'

/**
 * 分管各个模块的数据管理类
 */
/**
 * 处理前端展示数据
 * 定义后端数据转换成前端数据结构的方法
 */
var DATA = {
	/**
	 * 获取商品列表数据
	 * @param {Object} data
	 */
    getProductDataByData(data) {
		var resultList = []
		data.forEach((pData, index) => {
			var newData = Object.assign({
				priceText: parseInt(pData.price/100),
				priceSubText: index == 0 ? '节省'+parseInt((pData.price/pData.rate_price)*100)+'%' : '原价'+parseInt(pData.rate_price/100)+'元'
			}, pData);
			resultList.push(newData);
		});
		console.log('getProductDataByData-----', resultList)
		return resultList;
	}
}


export {
    DATA, USER_DATA_MANAGER
}