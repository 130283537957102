<template>
	<div id="wrapper" class="wrapper">
        <!-- 1. 路由匹配到的组件将渲染在这里 -->
        <div class="wrapper-content">
            <router-view></router-view>
        </div>
        <!-- 1.2 底部菜单栏:非web端 -->
		<tab-bar v-if="$route.meta.showFooter"></tab-bar>
	</div>
</template>
<script>
import { WEIXIN } from './modules/weixin';
import TabBar from './components/common/TabBar.vue';
import { UA } from './modules/utils';
export default {
    name: "App",
    components: { TabBar },
	mounted() {
		console.log('App Launch')
		setTimeout(() => {
			WEIXIN.initConfigShare();
			this.initDom();
			this.initData();
			this.$store.dispatch('initSysTime');
		}, 200);
	},
	methods: {
		initDom() {
			var path = this.$router.history.current.path;
            console.log('initDom------', path)
            if(path == '/') {
                this.$router.replace('/home');
            } else if(path == '/schedule') {
				this.$store.state.curTabIndex = 1;
            } else if(path == '/my') {
				this.$store.state.curTabIndex = 2;
			}
		},
		initData() {
			console.log('App initData----')
			this.$store.dispatch('getMyUserListData');
			this.$store.dispatch('initWeatherInformation');
			this.$store.dispatch('calendar/initTodayTomorrowDateInfo');
			setTimeout(() => {
				this.$store.dispatch('user/getUserHomePageData', {
					failCb: ()=> {
						this.$router.replace('/start');
					}
				});
			}, 1000);
		},
	}
}
</script>