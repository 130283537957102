// 设置一些key的常量
const REPORT_EARN_POP_STATUS = 'h5_wealth_wechat_report_earn_pop';
const AGENCY_BEGINNER_STATUS = 'h5_wealth_wechat_agency_beginner';
/**
 * 操作localstorage的一些公共方法
 */
var LS = {
    /**
     * 查看报告页赚钱说明弹框
     */
    checkReportEarnPopStatus() {
        var curStatus = localStorage.getItem(REPORT_EARN_POP_STATUS);
        if(!curStatus) {
            localStorage.setItem(REPORT_EARN_POP_STATUS, false);
            return false;
        }else {
            return JSON.parse(curStatus);
        }
    },
    /**
     * 设置报告页赚钱说明弹框
     * @param {布尔值} value 
     */
    setReportEarnPopStatus(value) {
        localStorage.setItem(REPORT_EARN_POP_STATUS, value);
    },
    /**
     * 查看奖励中心页的新手引导
     */
    checkAgencyBeginnerStatus() {
        var curStatus = localStorage.getItem(AGENCY_BEGINNER_STATUS);
        if(!curStatus) {
            localStorage.setItem(AGENCY_BEGINNER_STATUS, false);
            return false;
        }else {
            return JSON.parse(curStatus);
        }
    },
    /**
     * 设置奖励中心页的新手引导
     * @param {布尔值} value 
     */
    setAgencyBeginnerStatus(value) {
        localStorage.setItem(AGENCY_BEGINNER_STATUS, value);
    }
}

export {
    LS
}