import * as types from '../mutation-types'
import * as consts from '../../../modules/data/base/consts'
import { API } from '../../../modules/api';
import { UTILS } from '../../../modules/utils';
import { USER_DATA_MANAGER } from '../../../modules/data'
import { result } from 'lodash';

export default {
    namespaced: true,
    state: {
		editType: 1, //1:新建,2:编辑or新建更多
		inputData: {
			sex: 0,
			nickname: '',
			birthday: 0,
		},
		// 默认的是非会员状态
		authority: {
			tomorrowInfo: false,  	//明日先知概况
			futureDays: 1,  	  	//先知未来天数
			futureHours: 2, 	  	//先知未来小时数
			tomorrowHours: false, 	//先知未来小时数
			historyDays: 0,			//历史日期状态回顾
			characterAuth: true,	//性格特点
			FTalentAuth: true,  	//先天天赋
			STalentAuth: true,  	//后天天赋
			dayStatusDay: 0,        //按日能力状态
			monthStatusDay: 0,      //按月能力状态
		},
		userNickName: '',
		userBaseInfo: {f_name: "匿名", constellation: {}},
		userLocation: {},
		userStarInfo: {},
		userEvent: [], //占位
		userBaseTalentChartData: {},
		userBaseTalentSortData: [],
		userHomeIsToday: true,
		userTodayData: {},
		userTomorrowData: {},
		userCurrentData: {},
		userMonthData: {},
		userCharacterData: [],
		userTalentData: {},
		userTalentChartData: {},
		userStatusDayDate: {},
		userStatusDayChartData: {},
		userStatusDayCategories: [],
		userStatusDaySeries: [],
		userStatusMonthDate: {},
		userStatusMonthChartData: {},
		userStatusMonthCategories: [],
		userStatusMonthSeries: [],
    },
    mutations: {},
    actions: {
        addUser({dispatch, state}, params) {
			console.log('addUser------', state.inputData)
			API.addUser({
				birthday: state.inputData.birthday,
				f_name: state.inputData.nickname,
				gender: state.inputData.sex
			}, function(data) {
				console.log('添加测算成功:::::::', data);
				state.userBaseInfo = USER_DATA_MANAGER.getUserBaseInfoByData(data.info);
				state.userNickName = state.userBaseInfo.f_name;
				state.userEvent = data.event;
				var resultData = USER_DATA_MANAGER.getUserBaseTalentInfoByData(data.talent);
				state.userBaseTalentChartData = Object.assign({}, resultData.chartData);
				state.userBaseTalentSortData = resultData.sortData;
				// 增加数据到列表中
				dispatch('addMyUserListData', data.info, {root: true});
				if(params && params.cb && typeof(params.cb) == 'function') {
					params.cb();
				}
			})
		},
		addFakeUser({dispatch, state}, params) {
			//一个新增返回的数据
			let data = {
				"talent": {
					"title": "\u5148\u5929",
					"info": [
						{
							"name": "\u903b\u8f91\u601d\u7ef4\u80fd\u529b",
							"score": 44
						},
						{
							"name": "\u521b\u65b0\u80fd\u529b",
							"score": 65
						},
						{
							"name": "\u56e2\u961f\u610f\u8bc6",
							"score": 51
						},
						{
							"name": "\u6297\u538b\u80fd\u529b",
							"score": 51
						},
						{
							"name": "\u6267\u884c\u80fd\u529b",
							"score": 60
						},
						{
							"name": "\u6c9f\u901a\u80fd\u529b",
							"score": 62
						},
						{
							"name": "\u76ee\u6807\u91cf\u5316\u610f\u8bc6",
							"score": 54
						},
						{
							"name": "\u98ce\u9669\u610f\u8bc6",
							"score": 61
						}
					]
				},
				"event": [],
				"info": {
					"f_name": "testtt",
					"birthday": "779148000",
					"gender": "0",
					"platform": "10",
					"isdefault": 0,
					"f_type": 0,
					"typetime": 0,
					"uid": "1000019",
					"id": 322
				}
			};
			state.userBaseInfo = USER_DATA_MANAGER.getUserBaseInfoByData(data.info);
			state.userNickName = state.userBaseInfo.f_name;
			state.userEvent = data.event;
			var resultData = USER_DATA_MANAGER.getUserBaseTalentInfoByData(data.talent);
			state.userBaseTalentChartData = Object.assign({}, resultData.chartData);
			state.userBaseTalentSortData = resultData.sortData;
			// console.error('addFakeUser-------', state.userBaseTalentChartData, state.userBaseTalentSortData)
			// 增加数据到列表中
			// dispatch('addMyUserListData', data.info, {root: true});
			if(params && params.cb && typeof(params.cb) == 'function') {
				params.cb();
			}
		},
		initUserAuthority({state}) {						
			var type = state.userBaseInfo.f_type;
			switch(type){
				case 0:  //无会员
					state.authority.tomorrowInfo = false;
					state.authority.futureDays = 1;
					state.authority.futureHours = 2;
					state.authority.tomorrowHours = false;
					state.authority.historyDays = 0;
					state.authority.STalentAuth = false;
					break;
				case 1:  //试用会员
					state.authority.tomorrowInfo = true;
					state.authority.futureDays = 3;
					state.authority.futureHours = 24;
					state.authority.tomorrowHours = true;
					state.authority.historyDays = 7;
					state.authority.STalentAuth = false;
					state.authority.dayStatusDay = 7;
					state.authority.monthStatusDay = 1;
					break;
				case 2:	 //月卡
					state.authority.tomorrowInfo = true;
					state.authority.futureDays = 30;
					state.authority.futureHours = 24;
					state.authority.tomorrowHours = true;
					state.authority.historyDays = 30;
					state.authority.STalentAuth = true;
					state.authority.dayStatusDay = 30;
					state.authority.monthStatusDay = 3;
					break;
				case 3:  //终生
					state.authority.tomorrowInfo = true;
					state.authority.futureDays = 90;
					state.authority.futureHours = 24;
					state.authority.tomorrowHours = true;
					state.authority.historyDays = 3650;
					state.authority.STalentAuth = true;
					state.authority.dayStatusDay = 90;
					state.authority.monthStatusDay = 9;
					break;
				default:
					break;
			}
			console.log('initUserAuthority----', type, state.authority)
		},
		getUserHomePageData({dispatch, state}, params) {
			var newParams = {id: params && params.id ? params.id : (state.userBaseInfo && state.userBaseInfo.id ? state.userBaseInfo.id : 0)};
				newParams = state.userLocation.latitude ? Object.assign({lat: state.userLocation.latitude}, newParams) : newParams;
				newParams = state.userLocation.longitude ? Object.assign({long: state.userLocation.longitude}, newParams) : newParams;
			API.getUserHomePage(newParams, function(data) {
				console.log('获取首页数据成功:::::::', data)
				state.userStarInfo = data.star;
				state.userBaseInfo = USER_DATA_MANAGER.getUserBaseInfoByData(data.base);
				state.userNickName = state.userBaseInfo.f_name;
				state.userTodayData = USER_DATA_MANAGER.getHomePageDataByData(data.today);
				state.userTomorrowData = USER_DATA_MANAGER.getHomePageDataByData(data.tomorrow);
				state.userCurrentData = Object.assign({}, state.userTodayData);
				console.error('getUserHomePageData--------', state.userTodayData, state.userCurrentData);
				dispatch('initUserAuthority');
				if(params && params.cb && typeof(params.cb) == 'function') {
					params.cb();
				}
			}, function(data) {
				if(params && params.failCb && typeof(params.failCb) == 'function') {
					params.failCb();
				}
			})
		},
		getUserMonthData({state}, params) {
			// console.error('getUserMonthData-----', params.ymd, state.userMonthData[params.ymd])
			// console.error('getUserMonthData---', state.userBaseInfo.id);
			if(state.userMonthData[params.ymd]) {
				if(params && params.cb && typeof(params.cb) == 'function') {
					params.cb();
				}
				return;
			}
			API.getUserMonth({
				id: parseInt(state.userBaseInfo.id),
				ymd: params.ymd
			}, function(data) {
				console.log('获取月数据成功:::::::', data)
				for(var keys in data.info) {
					data.info[keys].titleClass = consts.CONST_CHARACTER_ONE_WORD.indexOf(data.info[keys].title);
				}
				state.userMonthData = Object.assign(data.info, state.userMonthData);
				state.userCurrentData = Object.assign({}, state.userTodayData);
				if(params && params.cb && typeof(params.cb) == 'function') {
					params.cb();
				}
			})
		},
		getUserCurrentData({dispatch, state}, dData) {
			if(dData.isToday) {
				state.userCurrentData = Object.assign({}, state.userTodayData);
			}else if(state.userMonthData[dData.cYMD]) {
				state.userCurrentData = USER_DATA_MANAGER.getHomePageDataByData(state.userMonthData[dData.cYMD]);
			}
			console.log('getUserCurrentData-----', dData, state.userCurrentData)
		},
		getUserCharacterData({state}, params) {
			API.getUserCharacter({
				id: parseInt(state.userBaseInfo.id),
			}, function(data) {
				console.log('获取性格数据成功:::::::', data)
				state.userCharacterData = USER_DATA_MANAGER.getCharacterDataByData(data.describe);
			})
		},
		getUserTalentData({state}, params) {
			API.getUserTalent({
				id: parseInt(state.userBaseInfo.id),
			}, function(data) {
				console.log('获取天赋数据成功:::::::', data, parseInt(data.info.chart[1].title))
				state.userTalentData = Object.assign({
					nextYear: (parseInt(data.info.chart[1].title)+1)
				}, data);
				var resultData = USER_DATA_MANAGER.getTalentDataByData(data.info);
				state.userTalentChartData = Object.assign({}, resultData);
				console.error('userTalentData-----', state.userTalentChartData)
			})
		},
		getUserDayStatusData({state}, params) {
			API.getUserDayStatus({
				id: parseInt(state.userBaseInfo.id),
			}, function(data) {
				console.log('获取日状态曲线数据成功:::::::', data)
				var resultData = USER_DATA_MANAGER.getStatusDayDataByData(data);
				state.userStatusDayDate = Object.assign({}, resultData.date);
				state.userStatusDayChartData = Object.assign({}, resultData.chartData);
				if(params && params.cb && typeof(params.cb) == 'function') {
					params.cb(resultData.chartData);
				}
			})
		},
		getUserMonthStatusData({state}, params) {
			API.getUserMonthStatus({
				id: parseInt(state.userBaseInfo.id),
			}, function(data) {
				console.log('获取月状态曲线数据成功:::::::', data)
				var resultData = USER_DATA_MANAGER.getStatusMonthDataByData(data);
				state.userStatusMonthDate = Object.assign({}, resultData.date);
				state.userStatusMonthChartData = Object.assign({}, resultData.chartData);
				if(params && params.cb && typeof(params.cb) == 'function') {
					params.cb(resultData);
				}
			
			})
		},
		updateUserNickname({dispatch, state}, params) {
			console.error('updateUserNickname-----', state.userBaseInfo, params)
			API.updateUserInfo({
				id: state.userBaseInfo.id,
				name: params.nickname
			}, function(data) {
				console.log('更新昵称成功::::::', data)
				state.userBaseInfo.f_name = params.nickname;
				state.userNickName = params.nickname;
				state.userBaseInfo.firstL = UTILS.getFirstLetter(params.nickname);
				// 更新列表数据
				dispatch('updateMyUserListData', {
					id: state.userBaseInfo.id,
					name: params.nickname
				}, {root: true});
				if(params && params.cb && typeof(params.cb) == 'function') {
					params.cb();
				}
			})
		},
		resetUserInputData({state}) {
			state.editType = 2;
			state.inputData = {
				sex: -1,
				nickname: '',
				birthday: 0,
			};
		},
        resetData({state}) {
            state.userBaseInfo = {};
			state.editType = 1;
            state.inputData = {
				sex: -1,
				nickname: '',
				birthday: 0,
            };
        }
    }
}
