import * as consts from '../data/base/consts'

/**
 * 一些公共的方法
 */
import { UA } from './ua'
import { LS } from './ls'
import { CANVAS } from './canvas'

var UTILS = {
    // 获取时间格式
    getTimeStrByInt(num) {
        return num < 10 ? ('0' + num) : num;
    },
	// 获取时间格式：反转
	getTimeNumByString(string) {
	    return string.charAt(0) == '0' ? parseInt(string.charAt(1)) : parseInt(string);
	},
	// 获取年月日的中文表达:::算法优化
	getChineseWordByNumber(type, number) {
	    var resultStr = '';
	    var tempArr = (number.toString()).split('');
	    var time = parseInt(number);
	    if (type == 'year') {
	        for (var i = 0; i < tempArr.length; i++) {
	            resultStr += consts.CONST_YEAR_WORD[tempArr[i]];
	        }
	        resultStr += '年';
	    } else if (type == 'month') {
	        resultStr += consts.CONST_MONTH_WORD[time - 1];
	    } else if (type == 'day') {
	        resultStr += consts.CONST_DAY_WORD[time - 1];
	    }
	    // console.log("getChineseWordByNumber", type, time, resultStr)
	    return resultStr;
	},
	// 获取年月日的数字表达:::算法优化
	getNumberByChineseWord(type, word) {
		var resultNumber = 0;
		switch(type) {
			case 'year':
				var yearArr = word.split(''), yearNArr = [];
				yearArr.forEach(yearT=>{
					var y = consts.CONST_YEAR_WORD.indexOf(yearT);
					yearNArr.push(y);
				});
				resultNumber = parseInt(yearNArr.join(''));
				break;
			case 'month':
				resultNumber = consts.CONST_MONTH_WORD.indexOf(word)+1;
				break;
			case 'day':
				resultNumber = consts.CONST_DAY_WORD.indexOf(word)+1;
				break;
			default:
				break;
		}
		console.error('getNumberByChineseWord', type, word, resultNumber)
		return resultNumber;
	},
	// 根据月和日获取星座
	getConstellationByMD(month, day) {
		var monthIndex = month-1,
			startD = consts.CONST_CONSTELLATION_START_DAY[monthIndex],
			endD = consts.CONST_CONSTELLATION_END_DAY[monthIndex],
			textArr = consts.CONST_CONSTELLATION_TEXT;
		var resultData = day <= endD ? textArr[monthIndex] : textArr[monthIndex+1];
			resultData = month >= 12 && day >= startD? textArr[0] : resultData; 
		return resultData;
	},
    // 根据数组获取 yy-mm-dd hh:mm:ss 格式的时间
    getYmdHmsTimeStrByArr(arr) {
        var timeStr = '';
        for(var i = 0; i < arr.length; i++){
            timeStr += this.getTimeStrByInt(arr[i]);
            if(i < 2){
                timeStr += '-';
            }else if (i == 2){
                timeStr += ' ';
            }else if (i == 3 || i == 4){
                timeStr += ':';
            }
        }
        return timeStr;
    },
	/**
	 * 获取名称的第一个文字(忽略emoji表情)
	 * @param {Object} string
	 */
	getFirstLetter(string) {
		if(!string) { return '' };
		var regRule = /[\u{1F601}-\u{1F64F}\u{2702}-\u{27B0}\u{1F680}-\u{1F6C0}\u{1F170}-\u{1F251}\u{1F600}-\u{1F636}\u{1F681}-\u{1F6C5}\u{1F30D}-\u{1F567}]/gu;
		if(string.match(regRule)) {
			string = string.replace(regRule, '');
		}
		return string.charAt(0);
	},
	/**
	 * 获取随机数
	 * @param {Object} max
	 */
	getRandomInt(max) {
	  return Math.floor(Math.random() * Math.floor(max));
	},
	/**
	 * 通过时间戳获取时间信息
	 * @param {Object} time
	 */
	getBirthdayInfoByTimeStamp(time) {
		var date = new Date(time*1000),
			year = date.getFullYear(), month = date.getMonth(), day = date.getDate(), hour = date.getHours(), minute = UTILS.getTimeStrByInt(date.getMinutes()),
			constellation = UTILS.getConstellationByMD(month+1, day);
		var dateStr = year+'年'+(month+1)+'月'+day+'日 '+hour+':'+minute;
		return {
			date: dateStr,
			constellation: constellation
		};
	},
	// 在对象数组里取包含特定id的object的index
    getIdIndexInObjectArray(objectArr, id) {
        for(var object of objectArr){
            var index = objectArr.indexOf(object);
            if(object.id == id){
                return index;
            }
        }
        return -1;
    },
	
	// --------------------cut line---------------------
	
    // 根据数组获取 大运开始日期string 
    getDaYunStartTimeStrByData(data) {
        var str = data.y + '年';
            str = str + this.getTimeStrByInt(data.m) + '月';
            str = str + this.getTimeStrByInt(data.d) + '日';
            str = str + this.getTimeStrByInt(data.h) + '时';
            str = str + this.getTimeStrByInt(data.i) + '分交大运';
        return str;
    },
    getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return decodeURI(r[2]);
        };
        return null;
    },
    getQueryStringByString(string, name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = string.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        };
        return null;
    },
    isPoneAvailable(number) {
        var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (!myreg.test(number)) {
            return false;
        } else {
            return true;
        }
    },
    // 数组去重
    distinct(arr) {
        var result = [];
        var obj = {};
    
        for (var i of arr) {
            if (!obj[i]) {
                result.push(i);
                obj[i] = 1;
            }
        }
        return result;
    },
    // 在数组中查找是否有某对象
    checkArrayInArray(origiArr, arr) {
        for(var i = 0; i < origiArr.length; i++){
            if(origiArr[i][0] == arr[0]){
                return true;
            }
        }
        return false;
    },
    // 在数组中获取某个数组的index值
    getArrayIndexInArray(origiArr, arr) {
        for(var i = 0; i < origiArr.length; i++){
            if(origiArr[i][0] == arr[0]){
                return i;
            }
        }
        return -1;
    },
    
    // 在字符串对象里获取某个存在目标字符的字符串的索引值
    getTextIndexInStringArray(arr, text) {
        for(var i = 0; i < arr.length; i++){
            if(arr[i].indexOf(text) != -1){
                return i;
            }
        }
    },

    /**
     * 获得指定数组的特定组合
     * @param {目标数组} targetArr 
     * @param {组合个数} num 
     */
    getCombineArrayByNum(targetArr, num) {
        if(!targetArr || !targetArr.length) {
            return [];
        }
    
        var len = targetArr.length;
        var resultArrs = [];
    
        // 所有组合
        var flagArrs = this.getFlagArrs(len, num);
        while(flagArrs.length) {
            var flagArr = flagArrs.shift();
            var combArr = [];
            for(var i = 0; i < len; i++) {
                flagArr[i] && combArr.push(targetArr[i]);
            }
            resultArrs.push(combArr);
        }
        return resultArrs;
    },
 
    /**
     * 获得从m中取n的所有组合
     */
    getFlagArrs(m, n) {

        if(!n || n < 1) {
            return [];
        }
    
        var resultArrs = [],
            flagArr = [],
            isEnd = false,
            i, j, leftCnt;
    
        for (i = 0; i < m; i++) {
            flagArr[i] = i < n ? 1 : 0;
        }
        resultArrs.push(flagArr.concat());
    
        while (!isEnd) {
            leftCnt = 0;
            for (i = 0; i < m - 1; i++) {
                if (flagArr[i] == 1 && flagArr[i+1] == 0) {
                    for(j = 0; j < i; j++) {
                        flagArr[j] = j < leftCnt ? 1 : 0;
                    }
                    flagArr[i] = 0;
                    flagArr[i+1] = 1;
                    var aTmp = flagArr.concat();
                    resultArrs.push(aTmp);
                    if(aTmp.slice(-n).join("").indexOf('0') == -1) {
                        isEnd = true;
                    }
                    break;
                }
                flagArr[i] == 1 && leftCnt++;
            }
        }
        return resultArrs;
    },

    /**
     * 给年补齐4位数
     * @param {年} $yy 
     */
    formatYearNum: function ($yy) {
        var $num = 4 - $yy.toString().length;
        for(var $i = 0; $i < $num; $i++){
            $yy = '0' + $yy;
        }
        return $yy;
    },
    
    /**
     * 通过文本获得换行符
     * @param {文本} text 
     */
    getBreakLineByText(text) {
        var regex = /\n/gi;
        var newText = text.replace(regex, '<br />');
        return newText;
    },

    // /**
    //  * 通过文本获得换行符
    //  * @param {文本} text 
    //  */
    // getBreakNLineByText(text) {
    //     var regex = /\<br \/\>/gi;
    //     var newText = text.replace(regex, '\n');
    //     return newText;
    // },

    /**
     * 通过文本获得换行符的个数
     * @param {文本} text 
     */
    getBreakLineNumber(text) {
        var arr = text.split('\n');
        return arr.length;
    },
	
	/**
	 * 通过文本获得文本数组
	 * 
	 * @param {文本} text
	 */
	getLineArrByText(text) {
		var arr = text.split('\n');
		return arr;
	},
    
	/**
	 * 从数组中随机取几个值
	 * @param {Object} arr
	 * @param {Object} count
	 */
	getRandomArrayElements(arr, count) {
	    var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
	    while (i-- > min) {
	        index = Math.floor((i + 1) * Math.random());
	        temp = shuffled[index];
	        shuffled[index] = shuffled[i];
	        shuffled[i] = temp;
	    }
	    return shuffled.slice(min);
	},
	
	/**
	 * 给数组元素去重处理
	 * @param {Object} arr
	 */
	removeRepeatArrElement(arr) {
		var obj = {}, newArr = [], len = arr.length;
		for(var i = 0; i < len; i++) {
			if(!obj[arr[i]]) {
				newArr.push(arr[i]);
				obj[arr[i]] = true;
			}
		}
		return newArr;
	}

}

export {
    UTILS, UA, LS, CANVAS
}