import wx from 'weixin-js-sdk'
import { API } from '../api'

var WEIXIN_DATA = {}
var WEIXIN_BASE = {
    getAuthorationAndConfigJsSDK(cb, resetData) {
        var curUrl = location.href.split('#')[0];
        // 是否重置数据
        WEIXIN_DATA = resetData ? {} : WEIXIN_DATA;
        if(WEIXIN_DATA.appId) {
            WEIXIN_BASE.setConfig(WEIXIN_DATA, cb)
        }else {
            API.getWeixinSdkConfig({
                'url': curUrl
            }, function (data) {
                // WEIXIN_DATA[curUrl] = data;
                WEIXIN_DATA = data;
                WEIXIN_BASE.setConfig(data, cb)
            });
        }
    },
    setConfig(data, cb) {
        wx.config({
            // 开启调试模式时,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            debug: false,
            // 后台返回之前获取的appId
            appId: data.appId,
            // 必填，生成签名的时间戳
            timestamp: data.timestamp,
            // 必填，生成签名的随机串
            nonceStr: data.nonceStr,
            // 必填，签名，见附录1
            signature: data.signature,
            // 必填，需要使用的JS接口列表，所有JS接口列表见附录3
            jsApiList: [
                'onMenuShareTimeline', //1.0分享到朋友圈
                'onMenuShareAppMessage', //1.0 分享到朋友
                'updateAppMessageShareData', //1.4 分享到朋友
                'updateTimelineShareData', //1.4分享到朋友圈
                'getLocation', //获取地理位置接口
            ]
        });

        // 检查JsApi是否可用
        wx.checkJsApi({
            jsApiList: [
                'onMenuShareTimeline', //1.0分享到朋友圈
                'onMenuShareAppMessage', //1.0 分享到朋友
                'updateAppMessageShareData', //1.4 分享到朋友
                'updateTimelineShareData', //1.4分享到朋友圈
                'getLocation', //获取地理位置接口
            ],
            success: function (res) {
                console.log('checkJsApi', res)
                // 以键值对的形式返回，可用的api值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            }
        });

        if(cb && typeof(cb) == 'function') {
            cb();
        }
    }
}

export {
    WEIXIN_BASE
}