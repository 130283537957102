import Vue from 'vue'
import { UTILS, UA, COOKIE } from '../utils'
import { API } from '../api'
import { CONFIG } from '../config'
import { WEIXIN } from '../weixin';
import App from '../../App.vue'
import store from '../store'
import router from '../router'

export default {

    checkLogin() {

        // todo 人生天气日历中没有checkLogin的接口。
        var me = this, userInfo;
        // 超过30min后，删除数据，重新授权登录
        var oldTime = parseInt(parseInt(window.localStorage.getItem('H5_Life_TimeStamp'))/1000/60);
        var newTime = parseInt((new Date()).getTime()/1000/60);
        var temp = newTime - oldTime;
        if(temp > 30) {
            window.localStorage.removeItem("H5_Life_UserInfo");
        }
        userInfo = JSON.parse(window.localStorage.getItem("H5_Life_UserInfo"));
        var userToken = userInfo && userInfo.token ? userInfo.token : null;

        console.log('localStorage-----', temp, userInfo, userToken);
        // 其他平台用sessionstorage缓存用户登录信息
        if (userToken != null && userToken != 'null') {
            API.checkLogin({
                'token': userToken
            }, function (data) {
                store.state.userData = userInfo ? userInfo : {};
                if(userToken) {
                    store.state.userData = Object.assign({
                        'token': userToken
                    }, data);
                }
                me.renderView();
            }, function (data) {
                console.error("checkLogin-----failed", data)
                me.thirdLogin();
            })
        }else{
            me.thirdLogin();
        }
    },
    /**
     * 跳转到授权登录
     */
    thirdLogin(loginType, sucFunc) {

        // for test
        // store.state.user.userLocation = {latitude: 23, longitude:113};
        // this.renderView();        
        // return;
        // for test

        var me = this;
        var code = UTILS.getQueryString("code");     // 注意：1个code请求登录thirdLogin的接口只能请求1次
        var type = CONFIG.platform == 'wechat' ? 3 : 6;  // 微信3，其他是6
            type = loginType ? loginType : type;
        console.error('thirdLogin------', loginType, code, type)
        var params = code ? {
            'code': code,
            'type': type
        } : null;
        if (params) {
            API.thirdLogin(params, function (data) {
                store.state.userData = data;
                window.localStorage.setItem("H5_Life_TimeStamp", (new Date()).getTime());
                window.localStorage.setItem("H5_Life_UserInfo", JSON.stringify(data));
                console.log('thirdLogin-----授权登录成功', data);
                if(sucFunc && typeof(sucFunc) == 'function') {
                    sucFunc();
                }
                WEIXIN.initGetLocation((res) => {
                    console.log('initGetLocation---', res);
                    store.state.user.userLocation = res;
                })
                me.renderView();
            }, function (data) {
                console.error("thirdLogin-----授权登录失败", data.status_code);
                if(data.status_code == 1004) {
                    AppFate.$toast('因为违反国家相关法律规定账号被封');
                }else {
                    me.loginFailed();
                }
            });
        } else {
            me.loginFailed();
        }
    },
    /**
     * 第三方登录失败
     */
    loginFailed() {
        window.localStorage.removeItem("H5_Life_UserInfo");
        window.localStorage.removeItem("H5_Life_TimeStamp");
        window.location.href = CONFIG.getWechatAuthUrl();
    },
    /**
     * 渲染页面
     */
    renderView() {

        // 初始化vue的app
        window.AppFate = new Vue({
            el: '#app',
            template: '<App/>',
            router,
            store,
            components: { App }
        });

        // 横竖屏检查及提示
        // this.checkOrientation();
        // window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", this.checkOrientation, false);
    },
    checkOrientation() {
        var hoverDiv = document.getElementById('orientOverLay');
        if (window.orientation == 90 || window.orientation == -90) {
            //横屏
            hoverDiv.style.display = 'block';
        } else {
            //竖屏
            hoverDiv.style.display = 'none';
        }
    }
}