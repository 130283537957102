<template>
	<div class="page-first page-home page-generate" :style="'height: '+height+'px'">
		<div class="generate-content" :style="cStyle">
			<div class="generate-ani generate-ani-sun" :class="'a'+earthNum" :style="'height: '+height+'px'"></div>
			<!-- <div class="generate-ani generate-ani-moon-left" :class="'a'+moonNum"></div> -->
			<!-- <div class="generate-ani generate-ani-moon-right" :class="'a'+moonNum"></div> -->
			<div class="generate-ani generate-ani-earth" :class="'a'+earthNum"></div>
			<div class="generate-ani generate-ani-man" :class="'a'+manNum" v-if="inputData.sex == 0"></div>
			<div class="generate-ani generate-ani-woman" :class="'a'+manNum" v-if="inputData.sex == 1"></div>
		</div>
		<van-popup v-bind:value="showPop" class="van-popup-ai-result-panel" position="bottom" >
			<ai-result-panel></ai-result-panel>
		</van-popup>
	</div>
</template>

<script>
	import AiResultPanel from "../../components/common/AiResultPanel.vue"
	export default {
		name: "Generate",
		components: { AiResultPanel },
		computed: {
			inputData() {
				return this.$store.state.user.inputData;
			}
		},
		data() {
			return {
				height: 720,
				showPop: false,
				manTime: 80, manNum: 1,
				earthTime: 120, earthNum: 1,
				moonTime: 220, moonNum: 1,
				cStyle: ''
			}
		},
		mounted() {
			setTimeout(function() {
				this.height = innerHeight;
				this.initDom();
				this.initAnimation();
			}.bind(this), 20);
			setTimeout(() => {
				this.showPop = true;
			}, 1000);
			// 通过定时器来显示弹框:确保动画展示完成了
			// setTimeout(function() {
			// 	this.$refs.aipopup.open();
			// }.bind(this), 5000);
		},
		methods: {
			initDom() {
				this.$el.style.height = window.screen.height+'px';
				var scaleX = parseFloat(innerWidth/750), 
					scaleY = parseFloat(window.screen.height/1334);
				var cLeft = innerWidth-750, 
					cTop = window.screen.height >= 800 ? scaleY*window.screen.height-window.screen.height-20 : (window.screen.height-1334)/2;
				// console.error('generate------', cLeft, cTop, innerWidth, window.screen.height)
				var scaleValue = scaleX > scaleY ? scaleY : scaleX;
				this.cStyle = this.cStyle+ 'transform: scale('+scaleValue+','+scaleValue+'); left: '+cLeft/2+'px; top: '+cTop+'px';
			},
			initAnimation() {
				var me = this;
				function ani1() {
					me.manNum = me.manNum == 16 ? 1 : me.manNum+1;
					setTimeout(ani1, me.manTime)
				}
				ani1();
				
				function ani2() {
					me.earthNum = me.earthNum == 16 ? 1 : me.earthNum+1;
					setTimeout(ani2, me.earthTime)
				}
				ani2();
				
				function ani3() {
					me.moonNum = me.moonNum == 16 ? 1 : me.moonNum+1;
					setTimeout(ani3, me.moonTime)
				}
				ani3();
			}
		}
	}
</script>

<style>
	
</style>
