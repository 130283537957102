<template>
	<div class="schedule-calendar-yiji-panel dsp-flex">
		<div class="schedule-cyj-box border">
			<div class="schedule-cyj-title">
				<div class="title1">宜</div>
				<div class="title2">Should</div>
				<div class="title-line"><div class="line line-green"></div></div>
			</div>
			<div class="schedule-cyj-content">
				<span class="text" v-for="yiText in yijiCurrentData.yi">{{yiText}}</span>
			</div>
		</div>
		<div class="schedule-cyj-box">
			<div class="schedule-cyj-title">
				<div class="title1">忌</div>
				<div class="title2">Avoid</div>
				<div class="title-line"><div class="line line-red"></div></div>
			</div>
			<div class="schedule-cyj-content">
				<span class="text" v-for="jiText in yijiCurrentData.ji">{{jiText}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CalendarYiJiPanel",
		computed: {
			yijiCurrentData() {
				return this.$store.state.yijiCurrentData;
			}
		},
		methods: {
			
		}
	}
</script>

<style>
	.schedule-calendar-yiji-panel {
		padding: 20px 0;
	}
	.schedule-cyj-box {
		width: 50%;
	}
	.schedule-cyj-box.border {
		border-right: solid 1px #D8DCE5;
	}
	.schedule-cyj-title {
		text-align: center;
	}
	.schedule-cyj-title .title1 {
		font-size: 20px;
		font-weight: bold;
		color: #2F2F51;
	}
	.schedule-cyj-title .title2 {
		font-size: 15px;
		color: #B5B5C2;
	}
	.schedule-cyj-title .title-line {
		margin-top: -10px;
	}
	.schedule-cyj-title .line {
		display: inline-block;
		width: 30px;
		height: 3px;
	}
	.schedule-cyj-title .line.line-green {
		background: #07C160;
	}
	.schedule-cyj-title .line.line-red {
		background: #F12960;
	}
	.schedule-cyj-content {
		color: #75758C;
		font-size: 13px;
		text-align: center;
		padding: 5px 5px 0;
	}
	.schedule-cyj-content span.text {
		display: inline-block;
		padding: 0 2px;
	}
</style>
