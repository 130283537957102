<template>
	<div class="constellation-panel">
		<div class="constellation-info">
			<div v-if="cData.cText">
				<div class="constellation-text" v-bind:class="{'text-man': inputData.sex==0, 'text-woman': inputData.sex==1}">{{cData.cText}}</div>
				<div class="constellation-icon-box"><i class="constellation-icon"></i></div>
			</div>
		</div>
		<div class="constellation-circle" v-bind:class="{'sex-man': inputData.sex==0, 'sex-woman': inputData.sex==1}" :style="'transform: rotate('+cDeg+'deg);'"></div>
	</div>
</template>

<script>
	export default {
		name: "ConstellationPanel",
		computed: {
			inputData() {
				return this.$store.state.user.inputData;
			}
		},
		data() {
			return {
				cData: {},
				cDeg: 0,
				animationData: {}
			}
		},
		mounted() {
			this.$bus.on('updateConstellation', this.updateConstellation);
		},
		destroyed() {
			this.$bus.off('updateConstellation', this.updateConstellation);
		},
		methods: {
			updateConstellation(cData) {
				if(cData.cText == this.cData.cText) { return; }
				// 添加星座的动画
				var cIndex = cData.index, 
					tempI = this.cData.index ? Math.abs(cData.index-this.cData.index+1) : cData.index,
					cDeg = -cIndex*30, cTime = tempI*100;
				console.error('updateConstellation-----', cData, tempI, cDeg, cTime)
				// var animation = uni.createAnimation();
				// animation.rotate(cDeg).step({ duration: cTime, timingFunction: 'ease-in-out' });
				// this.animationData = animation.export();
				this.cData = Object.assign({}, cData);
				this.cDeg = cDeg;
			}
		}
	}
</script>

<style>
	.constellation-panel {
		width: 100%;
		background-image: url('../../assets/images/constellation-bg.png');
		background-repeat: no-repeat;
		background-size: 240px 240px;
		background-position: center bottom;
	}
	.constellation-circle {
		width: 240px;
		height: 240px;
		background-image: url('../../assets/images/constellation-default.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		margin: 0 auto;
	}
	.constellation-circle.sex-man {
		background-image: url('../../assets/images/constellation-blue.png');
	}
	.constellation-circle.sex-woman {
		background-image: url('../../assets/images/constellation-red.png');
	}
	.constellation-info {
		width: 100%;
		height: 45px;
	}
	.constellation-text, .constellation-icon-box {
		width: 100%;
		text-align: center;
	}
	.constellation-text {
		color: #9598FF;
	}
	.constellation-text.text-man {
		color: #1198FF;
	}
	.constellation-text.text-woman {
		color: #FF76B8;
	}
	.constellation-icon {
		display: inline-block;
		width: 22px;
		height: 20px;
		background: url('../../assets/images/constellation-arrow.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
</style>