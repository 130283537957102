<template>
	<div class="my-user-list-popup-box" v-if="showValue" @click="hidePop">
		<div class="my-user-list-popup" :class="className">
			<div class="my-user-list-arrow"></div>
			<div class="my-user-list-title">切换先知主人</div>
			<div class="my-user-list">
				<div class="my-user-list-item" :class="{'cur': userData.id == userBaseInfo.id}" @click.stop="clickUserItem(userData)" v-for="userData in myUserList" :key="userData.id">
					<div class="my-user-list-item-content">
						<div class="item-text-box">
								<img v-if="userData.isdefault == 1" class="item-avatar" :src="(userBaseInfo.gender==0 ? 'https://m1.yxdxt.com/d-m.png' : 'https://m1.yxdxt.com/d-f.png')" mode="" />
								<span v-else class="item-text" :class="{'item-text-vip': userData.f_type != 0}">{{userData.firstL}}</span>
								<i v-if="userData.f_type != 0"  class="iconbg icon-vip"></i>
							</div>
							<span class="item-nickname">{{userData.f_name.slice(0, 4)}}</span>
							<i v-if="userData.id == userBaseInfo.id"  class="iconfont icon-ok"></i>
						</div>
					</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MyUserlistPop",
		computed: {
			userInfo() {
				return this.$store.state.userInfo;	
			},
			userBaseInfo() {
				return this.$store.state.user.userBaseInfo;
			},
			myUserList() {
				return this.$store.state.myUserList;
			}
		},
		data() {
			return {
				showValue: false,
				className: ''
			}
		},
		mounted() {
			this.$bus.on('showUserListPop', this.showPop)
			this.$bus.on('hideUserListPop', this.hidePop)
			this.$store.dispatch('getMyUserListData');
		},
		destroyed() {
			this.$bus.off('showUserListPop', this.showPop)	
			this.$bus.off('hideUserListPop', this.hidePop)
		},
		methods: {
			showPop(type) {
				if(this.myUserList.length <= 1) {
					return;
				}
				this.className = type;
				this.showValue = true;
			},
			hidePop() {
				this.showValue = false;
			},
			clickUserItem(userData) {
				// 是当前数据就返回了
				if(userData.id == this.userBaseInfo.id) {
					return;
				}
				this.$store.dispatch('user/getUserHomePageData', {
					id: userData.id,
					cb: function() {
						this.hidePop();
						this.$bus.emit('updatemystatusmonth');
						this.$bus.emit('updatemystatusday');
					}.bind(this)
				});
			},
		}
	}
</script>

<style>
</style>
